import React, { useEffect, useRef, useState } from "react";
import Styles from "./SliderLayout.module.css";

enum SlideDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

interface SliderProps {
  children: React.ReactNode;
}

const Slider: React.FC<SliderProps> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollDistance = 300;

  const [showLeftArrow, setLeftArrowVisibility] = useState(false);
  const [showRightArrow, setRightArrowVisibility] = useState(true);
  const [showSlider, setSliderVisibility] = useState(false);

  const handleScroll = () => {
    const container = scrollContainerRef.current;

    if (container) {
      setLeftArrowVisibility(container.scrollLeft !== 0);
      setRightArrowVisibility(
        container.scrollLeft + container.clientWidth < container.scrollWidth
      );
    }
  };

  useEffect(() => {
    if (
      !scrollContainerRef.current ||
      !scrollContainerRef.current.parentElement?.clientWidth
    )
      return;

    setSliderVisibility(
      scrollContainerRef.current.scrollWidth >
        scrollContainerRef.current?.parentElement?.clientWidth
    );
  }, [scrollContainerRef.current?.scrollWidth]);

  const scroll = (command: SlideDirection) => {
    const direction =
      command === SlideDirection.LEFT ? -scrollDistance : scrollDistance;

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
    return undefined;
  }, []);

  return (
    <div className={Styles.sliderLayout}>
      <div className={Styles.templates} ref={scrollContainerRef}>
        {React.Children.map(children, (child, index) => (
          <div key={index} className={Styles.template}>
            {child}
          </div>
        ))}
      </div>
      {showLeftArrow && (
        <div
          onClick={() => scroll(SlideDirection.LEFT)}
          className={`${Styles.navigation} ${Styles.left}`}
        >
          <span className={Styles.arrow}>&lt;</span>
        </div>
      )}
      {showRightArrow && showSlider && (
        <div
          onClick={() => scroll(SlideDirection.RIGHT)}
          className={`${Styles.navigation} ${Styles.right} ${Styles.navigationShadow}`}
        >
          <span className={Styles.arrow}>&gt;</span>
        </div>
      )}
    </div>
  );
};

export default Slider;
