import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { ErrorType } from "../../state/error/error.reducer";
import OrgBlockedCreateDialog from "./errorDialogs/OrgBlockedCreateDialog";
import ErrorDialog from "./errorDialogs/ErrorDialog";
import CreditLimitReachedDialog from "./errorDialogs/CreditLimitReachedDialog";

const mapStateToProps = (state: RootState) => ({
  errorType: state.context.error.errorType,
});

type ErrorDialogRouterProps = ReturnType<typeof mapStateToProps>;

export const ErrorDialogRouter: React.FC<ErrorDialogRouterProps> = ({
  errorType,
}) => {
  switch (errorType) {
    case ErrorType.CreateBlockedByOrgPolicy:
      return <OrgBlockedCreateDialog />;
    case ErrorType.CreateBlockedByCreditLimit:
      return <CreditLimitReachedDialog />;
    default:
      return <ErrorDialog />;
  }
};

export default connect(mapStateToProps)(ErrorDialogRouter);
