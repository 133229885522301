import debug from "debug";
import { isElectron } from "../../utils/is.electron";
import { version } from "../../../package.json";

const trace = debug("hoylu:pendo");

const pendoScriptSource = "./pendo.js"; // pendo.js is in public folder

export function loadPendo(): boolean {
  if (getPendo()) return false; // do load it only once

  trace("loadPendo");

  // Note: This is the pendo.io init code converted to Typescript - includes a few ts-ignore for simplicity
  (function (window, document, script, name) {
    var functions: any,
      w: number,
      funcionsCount: number,
      scriptElem: any,
      z: any;
    //@ts-ignore because of window[name]
    const pendoObject = (window[name] = window[name] || {});
    pendoObject._q = pendoObject._q || [];
    functions = [
      "initialize",
      "identify",
      "updateOptions",
      "pageLoad",
      "track",
    ];
    for (w = 0, funcionsCount = functions.length; w < funcionsCount; ++w)
      (function (m) {
        pendoObject[m] =
          pendoObject[m] ||
          function () {
            pendoObject._q[m === functions[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(functions[w]);
    scriptElem = document.createElement(script);
    scriptElem.async = true;
    scriptElem.src = pendoScriptSource;
    z = document.getElementsByTagName(script)[0];
    z.parentNode.insertBefore(scriptElem, z);
  })(window, document, "script", "pendo");

  return true;
}

export function identifyUserInPendo(
  data: {
    userId: string;
    orgId: string;
    isPartialAccount: boolean;
    licenseType?: string;
    isEmbeddedInTeams?: boolean;
    isEmbeddedInWebex?: boolean;
  },
  piiData?: {
    email: string;
    name: string;
  }
) {
  const firstTime = loadPendo();

  const pendo = getPendo();
  if (!pendo) return;
  if (!data.userId) return; // do not track anything if the userId is not present

  trace("identifyUserInPendo", { data, piiData });

  const pendoIdentity: pendo.Identity = {
    visitor: {
      id: data.userId,
      email: piiData?.email ?? "",
      full_name: piiData?.name ?? "",
      isPartialAccount: data.isPartialAccount,
      licenseType: data.licenseType ?? "",
      isElectronApp: isElectron(),
      config_env: getEnv(),
      isEmbeddedInTeams: !!data.isEmbeddedInTeams,
      isEmbeddedInWebex: !!data.isEmbeddedInWebex,
      appVersion: version,
    },
    account: {
      id: getAccountId(data.userId, data.orgId),
      licenseType: data.licenseType ?? "",
      orgId: data.orgId,
    },
  };
  
  if (firstTime) {
    pendo.initialize(pendoIdentity);
  } else {
    pendo.identify(pendoIdentity);
  }
  // make pendo metadata available to workspace iframes as well
  sessionStorage.setItem("pendoIdentity", JSON.stringify(pendoIdentity));
}

export function getPendo(): pendo.Pendo | undefined {
  //@ts-expect-error because window.pendo is not in the typedef for pendo-io yet
  return window.pendo as pendo.Pendo | undefined;
}

export function isNotNullAndNonGlobalOrg(orgId?: string | null) {
  if (!orgId) return false;
  if (orgId === "00000000-0000-0000-0000-000000000000") return false;
  return true;
}

export function getAccountId(userId: string, orgId: string) {
  if (isNotNullAndNonGlobalOrg(orgId)) return orgId.toLowerCase();
  return `userid_${userId}`.toLowerCase();
}

declare global {
  interface Window {
    /** environment variables set globally by config.js */
    __env?: { CONFIG_URL?: string };
  }
}

function getEnv() {
  if (typeof window?.__env?.CONFIG_URL !== "string") return "err_undefined";
  try {
    return new URL(window.__env.CONFIG_URL).host;
  } catch {
    return "err_invalid_config_url";
  }
}