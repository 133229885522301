import { Store } from "redux";
import { loadWebexSdk } from "../services/dependencies/webex";
import { webexDetected } from "../state/config/config.actions";
import {
  checkURLPathForCommand,
  checkUrlPathForWorkspaceId,
  isWebexModeUrl,
} from "./url.helpers";
import { log } from "@hoylu/client-common";
import { storeEmbeddedAppMode } from "./embedded.app.mode";

export async function detectWebex(store: Store) {
  if (isWebexModeUrl(new URLSearchParams(window.location.search))) {
    // enable select workspace mode only we are not loading a workspace and also not creating a new workspace (and then loading that one)
    const isInSelectorMode =
      !checkUrlPathForWorkspaceId(window.location.pathname) ||
      checkURLPathForCommand(window.location.pathname) === "new";
    try {
      store.dispatch(
        webexDetected({ isInSelectorMode: false, isShared: false })
      ); // while webex sdk isn't loaded, we cannot call any webex functions, however UI already needs to be adapted
      storeEmbeddedAppMode("webex");
      await loadWebexSdk(store);
      log.featureUsed("webex-mode", "dashboard");
      store.dispatch(webexDetected({ isInSelectorMode, isShared: false }));
    } catch (e) {
      log.error("Failed to load webex", e as any);
    }
  }
}
