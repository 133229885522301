import { of } from 'rxjs';
import { filter, tap, ignoreElements, mergeMap, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getConfig, ConfigResponse } from '../../state/config/config.actions';

import { AppEpic } from '../types';

export const configEpic: AppEpic = (action$, state$, { getJSON }) => {
  return action$.pipe(
    filter(isActionOf(getConfig.request)),
    mergeMap(action => {
      return getJSON<ConfigResponse>(action.payload.configUrl).pipe(
        mergeMap(response => of(getConfig.success(response)))
      );
    }),
    catchError(error => of(getConfig.failure(error)))
  );
};

export const setupLogShippingEpic: AppEpic = (action$, state$, { setLogUrl }) => {
  return action$.pipe(
    filter(isActionOf(getConfig.success)),
    tap(action => setLogUrl(action.payload.serviceConfig.logs + '/hoylu-suite.log')),
    ignoreElements()
  );
};
