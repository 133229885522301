import * as configActions from "./config/config.actions";
import * as workspaceActions from "./workspaces/workspaces.actions";
import * as templateActions from "./templates/templates.actions";
import * as userActions from "./user/user.actions";
import * as modeActions from "./mode/mode.actions";
import { createAction } from "typesafe-actions";

export const epicFailure = createAction("EPIC_FAILURE")<Error>();

export const resetState = createAction("RESET_STATE")();

export default {
  resetState,
  modeActions,
  configActions,
  workspaceActions,
  userActions,
  templateActions,
  epicFailure,
};
