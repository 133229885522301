import React from "react";
import { Localized } from "../../../strings";
import { ProjectStatuses } from "../../../state/workspaces/workspaces.reducer";
import { hiconToHoyluUiIconsCentered } from "../../../utils/icon.map";
import { Project } from "../../../state/workspaces/types";
import Styles from "./ProjectLabel.module.css";

const ProjectLabel = ({
  project,
  onClick,
}: {
  project: Project;
  onClick: (e: React.MouseEvent, project: Project) => void;
}): JSX.Element => {
  const isProjectInactive = project.status === ProjectStatuses.Inactive;
  const projectName = isProjectInactive
    ? `${Localized.string("FILTER_BY_NAME.INACTIVE")}: ${project.name}`
    : project.name;
  const tooltip = isProjectInactive
    ? Localized.string("WORKSPACE_CARD.INACTIVE_PROJECT_LABEL")
    : project.name;

  return (
    <div
      onClick={(e) => onClick(e, project)}
      title={tooltip}
      className={Styles.projectLabel}
      style={{ borderColor: `${project.color}` }}
    >
      <div
        style={{ color: `${project.color}` }}
        className={`${hiconToHoyluUiIconsCentered(project.icon)} ${
          Styles.projectIcon
        }`}
      />
      <div className={Styles.projectName}>{projectName}</div>
    </div>
  );
};

export default ProjectLabel;
