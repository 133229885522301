import {
  Label,
  WorkspaceDetails,
  WorkspaceLabels,
  WorkspacePermissionsAndUsers,
} from "../../../state/workspaces/types";
import { WorkspaceTypeName } from "../../../state/workspaces/WorkspaceType";
import { GlobalAccess } from "../global.access";
import { metadataV1toWorkspaceDetails } from "./to.workspace.details";

export interface MyWorkspaceMetadataV1 {
  containerId?: string;
  createTime: string;
  hasPassword: boolean;
  id: number;
  isHidden: boolean | null;
  labels?: Label[];
  lastModified: string;
  lastaccess: string;
  module: {
    name: string;
  };
  orgs: string[];
  pageSize: {
    width: number;
    height: number;
  };
  permissions: {
    administrators: string[];
    readers: string[];
    writers: string[];
    unspecifiedUsers: string[];
    globalAccess: GlobalAccess;
  };
  schemaVersion: number;
  templateId?: string;
  weight?: number | null;
  workspaceName: string;
  workspaceType: WorkspaceTypeName;
  workspaceid: string;
}

export interface MyWorkspacesV1Response {
  workspaces: MyWorkspaceMetadataV1[];
  cursor: string;
}

export type LegacyMyWorkspacesV1Response = MyWorkspaceMetadataV1[];

export const metadataV1toWorkspaceDetailsArray = (
  rawDetails: MyWorkspaceMetadataV1[]
): WorkspaceDetails[] => {
  return rawDetails.map(metadataV1toWorkspaceDetails);
};

/**
 * Used by documentid-server v1 requests
 */
export type DocumentMetadataV1Request = {
  documentId?: string;
  documentType: WorkspaceTypeName;
  documentName: string;
  schemaVersion?: number;
  module: { name: string; configuration?: Object };
  pageSize?: { width: number; height: number };
  permissions?: WorkspacePermissionsAndUsers;
  password?: string;
};

/**
 * Used by documentid-server v1 responses
 */
export type DocumentMetadataV1Response = {
  administrators: string[];
  createTime: number;
  documentId: string;
  documentName: string;
  documentType: WorkspaceTypeName | "DELETED";
  globalAccess: GlobalAccess;
  hasPassword: boolean;
  labels?: WorkspaceLabels;
  lastModified?: number;
  module: { name: string; configuration: Object };
  orgs: string[];
  owners: string[];
  pageSize: { width: number; height: number };
  readers: string[];
  schemaVersion: number;
  unspecifiedUsers: string[];
  /** size of the workspace (int) */
  weight: number | null;
  writers: string[];
  containerId?: string;
  templateId?: string;  // Template workspaces
};

export type DocumentMetadataV2Response = DocumentMetadataV1Response;

export type CreateWorkspaceRequestParameters = {
  documentName: string;
  module: {
    name: string;
    configuration?: Object;
  };
  permissions?: WorkspacePermissionsAndUsers;
  password?: string;
};

export type DuplicateV1Request = {
  documentName: string;
  permissions?: WorkspacePermissionsAndUsers;
  password?: string;
};