import Styles from "./ShareType.module.css";

export type ShareOptionValue = {
  title: string;
  color?: string;
  value: number;
};

export type User = {
  email: string;
  permission: ShareOptionValue;
};

export const ShareType: Record<string, ShareOptionValue> = {
  ADMIN: {
    title: "SHARE_TYPE.ADMIN",
    color: Styles.admin,
    value: 3,
  },
  EDIT: {
    title: "SHARE_TYPE.EDIT",
    color: Styles.edit,
    value: 2,
  },
  READ: {
    title: "SHARE_TYPE.READ",
    color: Styles.read,
    value: 1,
  },
  NO_ACCESS: {
    title: "SHARE_TYPE.NO_ACCESS",
    color: Styles.noAccess,
    value: 0,
  },
  REMOVE: {
    title: "SHARE_TYPE.REMOVE",
    value: -1,
  },
};

export enum InfoSection {
  WORKSPACE = "workspace",
  TEMPLATE = "template",
}
