import { StateObservable } from 'redux-observable';
import { EMPTY, MonoTypeOperatorFunction } from 'rxjs';
import { filter, delayWhen } from 'rxjs/operators';
import { RootState } from 'typesafe-actions';

function stateDoesNotMatch(
  state$: StateObservable<RootState>,
  condition: (state: RootState) => boolean
) {
  return () => {
    if (condition(state$.value)) return EMPTY;
    return state$.pipe(filter(condition));
  };
}

export function waitForAppState<T>(
  state$: StateObservable<RootState>,
  condition: (state: RootState) => boolean
): MonoTypeOperatorFunction<T> {
  return delayWhen(stateDoesNotMatch(state$, condition));
}

export function waitForToken<T>(state$: StateObservable<RootState>) {
  return waitForAppState<T>(state$, state => !!state.context.user.token);
}

export function waitForLicense<T>(state$: StateObservable<RootState>) {
  return waitForAppState<T>(state$, state => !!state.context.user.licenseInfo);
}

export function waitForConfigLoaded<T>(state$: StateObservable<RootState>) {
  return waitForAppState<T>(state$, state => !!state.context.config.isLoaded);
}
