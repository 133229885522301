import React, { useEffect, useState } from "react";
import LoadingStyles from "../WorkspaceDialog.module.css";
import { LdsRoller } from "../LdsRoller";

type ProgressProps = {
  title: string;
  step: number | undefined;
  progress: number;
  strings: any;
};

export const Progress: React.FC<ProgressProps> = ({
  title,
  step,
  progress,
  strings,
}) => {
  const showLoadingPercent = !!Math.floor(progress) || !!step;
  const [loadStage, setLoadStage] = useState(strings.LOADED);

  useEffect(() => {
    switch (step) {
      case 0:
        setLoadStage(strings.LOADED);
        break;
      case 1:
        setLoadStage(strings.PROCESS);
        break;
      default:
        setLoadStage(strings.LOADED);
        break;
    }
  }, [step]);

  return (
    <>
      <div className={LoadingStyles.header}>
        <span className={LoadingStyles.title}>{title}</span>
      </div>
      <LdsRoller />
      <div className={LoadingStyles.loading}>
        {strings.STEP} {(step || 0) + 1}/2
      </div>
      {showLoadingPercent && (
        <div className={LoadingStyles.loading}>
          {Math.floor(progress)}% {loadStage}
        </div>
      )}
    </>
  );
};
