import React, { CSSProperties } from "react";
import Styles from "./TileTag.module.css";

type TagProps = {
  text: string;
  checked: boolean;
  onToggle: (text: string, checked: boolean) => void;
  color?: string;
  icon?: string;
};

export const TileTag: React.FC<TagProps> = ({
  text,
  onToggle,
  checked,
  color,
  icon,
}) => {
  let divIcon: JSX.Element = <></>;
  let style: CSSProperties = {};
  let additionalClasses = "";
  if (icon && color) {
    divIcon = (
      <div style={{ color: color }} className={`${icon} ${Styles.icon}`} />
    );
    style = { borderColor: color };
    additionalClasses = Styles.borderedTag;
  }
  return (
    <div
      className={`${Styles.tileTag} ${
        checked ? Styles.accent : Styles.gray
      } ${additionalClasses}`}
      style={style}
      onClick={() => onToggle(text, !checked)}
    >
      <input
        type="checkbox"
        checked={checked}
        className="mt-1 cursor-pointer self-center"
        readOnly
      />
      {divIcon}
      <div className="text-primary font-bold text-sm ml-1 truncate">{text}</div>
    </div>
  );
};
