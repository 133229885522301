import {HostClientType} from "@microsoft/teams-js";
import {TeamsFrameContexts} from "./detectTeams";

const embeddedAppModes = ["teams", "webex"] as const;
export type EmbeddedAppModes = typeof embeddedAppModes[number]; // the magic stuff to have string literal type based on an const array

/**
 * Store embedded app mode in session storage to make it available for workspace iframes.
 * Note: Within dashboard the app mode is stored in the config state, but is tied to successful loading of the integration libraries too.
 */
export function storeEmbeddedAppMode(mode: EmbeddedAppModes) {
  // intentionally keeping console.log here for tracing
  console.log(`[Hoylu] EmbeddedAppMode ${mode} enabled.`);
  sessionStorage.setItem("embeddedAppMode", mode);
}

/**
 * Store teams metadata in session storage to make it available for workspace iframes.
 * @param host
 * @param frame
 */
export function storeTeamsMetadata(host:HostClientType, frame: TeamsFrameContexts) {
  // intentionally keeping console.log here for tracing
  console.log(`[Hoylu] Teams Metadata`, host, frame);
  sessionStorage.setItem("teamsHost", host);
  sessionStorage.setItem("teamsFrame", frame);
}

/**
 * Read embedded app mode from session storage (which survies page reloads)
 * @returns the embedded app mode or undefined if not set
 */
export function readEmbeddedAppMode(): EmbeddedAppModes | undefined {
  const value = sessionStorage.getItem("embeddedAppMode");
  if (isEmbeddedAppMode(value)) {
    return value;
  }
  return;
}

function isEmbeddedAppMode(obj: any): obj is EmbeddedAppModes {
  return embeddedAppModes.includes(obj);
}
