import React, { useState, useEffect } from "react";
import Styles from "./RippleSpinner.module.css";

const Ripple2DelayedByASecond = [Styles.ripple2, Styles.delaySecond].join(" ");
const Ripple1WithAccentBorder = [Styles.ripple1, Styles.accentBorder].join(" ");
const Ripple1DelayedWithIndicatorBorder = [
  Styles.ripple1,
  Styles.delayHalfSecond,
  Styles.indicatorBorder,
].join(" ");

export const FlatRippleSpinner: React.FC = () => {
  // Workaround to avoid showing elements with delay in the spinner before initialization of the animation
  const [isFirstElVisible, setFirstElVisibility] = useState(false);
  const [isSecondElVisible, setSecondElVisibility] = useState(false);

  useEffect(() => {
    const el1Delay = 600; // cover difference between delay-1/2
    const el2Delay = 1100; // cover difference between delay-1

    const timer1 = setTimeout(() => {
      setFirstElVisibility(true);
    }, el1Delay);

    const timer2 = setTimeout(() => {
      setSecondElVisibility(true);
    }, el2Delay);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className={Styles.flatSpinner}>
      <div className={Styles.ripple2} />
      <div
        className={[Styles.ripple2, Styles.delayHalfSecond].join(" ")}
        style={{ opacity: isFirstElVisible ? 1 : 0 }}
      />
      <div
        className={Ripple2DelayedByASecond}
        style={{ opacity: isSecondElVisible ? 1 : 0 }}
      />
    </div>
  );
};

export const SmallRippleSpinner: React.FC = () => (
  <div className={Styles.smallRippleSpinner}>
    <div className={Ripple1WithAccentBorder} />
    <div className={Ripple2DelayedByASecond} />
    <div className={Ripple1DelayedWithIndicatorBorder} />
  </div>
);

export const MediumRippleSpinner: React.FC = () => (
  <div className={Styles.mediumRippleSpinner}>
    <div className={Ripple1WithAccentBorder} />
    <div className={Ripple2DelayedByASecond} />
    <div className={Ripple1DelayedWithIndicatorBorder} />
  </div>
);
