import { WorkspaceDetails } from "../state/workspaces/types";
import { getWorkspaceURL } from "./url.helpers";
import { ConfigState } from "../state/config/config.reducer";

export function openWorkspaceInNewWindow(
  details: WorkspaceDetails,
  config: ConfigState
) {
  window.open(
    getWorkspaceURL(
      details.workspaceType,
      details.workspaceId,
      config,
      details.module?.name ?? ""
    ),
    details.workspaceId
  );
}
