import { GlobalAccess } from "../../services/workspaces/global.access";
import type { WorkspacePermissionsAndUsers } from "./types";

export function defaultRoles(): WorkspacePermissionsAndUsers {
  return {
    administrators: [],
    readers: [],
    writers: [],
    unspecifiedUsers: [],
    globalAccess: GlobalAccess.WRITE,
  };
}
