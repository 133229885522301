import { createReducer } from "typesafe-actions";
import { clearError } from "../mode/mode.actions";
import { resetState } from "../root.action";
import { retryableFailures } from "../workspaces/retryableFailures";
import { AjaxError } from "rxjs/ajax";

export enum ErrorResponseMessages {
  UserLimitReached = "UserLimitReached",
  InvalidPassword = "InvalidPassword"
}

// The error type controls which error modal gets used
export enum ErrorType {
  Generic = "Generic",
  CreateBlockedByOrgPolicy = "CreateBlockedByOrgPolicy",
  CreateBlockedByCreditLimit = "CreateBlockedByCreditLimit",
}

export interface ErrorState {
  message: string;
  errorType?: ErrorType;
}

export const defaultState = (): ErrorState => ({
  // TODO: Is this really the default state message that we want when we clear error?
  //       Or does it make more sense for it to be '' or null?
  message: "Something went wrong, our engineers are working on it!",
});

export default createReducer<ErrorState>(defaultState())
  .handleAction(resetState, () => defaultState())
  .handleAction(clearError, () => defaultState())
  .handleAction(retryableFailures, (state, action) => ({
    message: action.payload.message,
    errorType: toErrorType(action.payload.error),
  }));

function toErrorType(error: Error): ErrorType {
  return redistributeSpecificErrorTypes(error) ?? ErrorType.Generic;
}

function redistributeSpecificErrorTypes(error: Error) {
  if (error instanceof AjaxError) {
    switch (error.status) {
      case 403:
        // "403.*": see DocumentIdServer.kt/throwIfWorkspaceCreateNotAllowed()
        if (String(error.response).includes("403.1")) {
          return ErrorType.CreateBlockedByOrgPolicy
        }
        break;
      case 402:
        if (String(error.response.code).includes(ErrorResponseMessages.UserLimitReached)) {
          return ErrorType.CreateBlockedByCreditLimit;
        }
        break;
      default:
        return null;
    }
  }

  return null;
}
