import { filter, mergeMap, map, tap, ignoreElements, throttleTime } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { AppEpic } from "../types";
import {
  editWorkspace,
  showDashboard,
  unfocusWorkspaces,
  setFocusAction,
  getWorkspaceDetails,
  createWorkspaceFromTemplate,
} from "../../state/workspaces/workspaces.actions";
import { getWorkspaceFullscreen, getWorkspaceById } from "../../state/workspaces/workspaces.selector";
import { AppMode } from "../../state/mode/mode.reducer";
import { openWorkspaceInNewWindow } from "../../utils/openWorkspaceInNewWindow";
import { log } from "@hoylu/client-common";
import { setWebexShareState } from "../../state/config/config.actions";

export const setWorkspaceTitleEpic: AppEpic = (
  action$,
  state$,
  { updateDocumentTitle, getDocumentTitle }
) =>
  action$.pipe(
    filter(isActionOf(editWorkspace)),
    tap((a) => {
      if (getDocumentTitle() !== "Hoylu") return;
      let targetWorkspace = state$.value.context.workspaces.existing.find(
        (workspace) => workspace.workspaceId === a.payload.workspaceId
      );
      if (targetWorkspace) {
        let title = "Hoylu";

        if (targetWorkspace.workspaceName) {
          title = targetWorkspace.workspaceName;
        }
        updateDocumentTitle(title);
      }
    }),
    ignoreElements()
  );

export const showDashboardEpic: AppEpic = (
  action$,
  state$,
  { updateDocumentTitle, sendMessageToWorkspace }
) =>
  action$.pipe(
    filter(isActionOf(showDashboard)),
    map(() => {
      setTimeout(() => updateDocumentTitle("Hoylu"), 100);
      let workspace = getWorkspaceFullscreen(state$.value);
      if (workspace) {
        sendMessageToWorkspace(workspace.workspaceId, {
          action: "TOGGLE_FOCUS",
          isFocused: false,
        });
      }
      return unfocusWorkspaces();
    })
  );

export const clearShareUrlEpic: AppEpic = (
  action$,
  state$,
  { clearWebexShareUrl }
) =>
  action$.pipe(
    filter(isActionOf([showDashboard, setWebexShareState])),
    filter(() => !!state$.value.context.config.webexMode && !state$.value.context.config.webexMode?.isShared),
    filter(() => state$.value.mode === AppMode.DASHBOARD),
    tap(async () => {
      try {
        await clearWebexShareUrl()
      } catch(e) { // happens when clearing & Stop Session is there on dashboard
        log.error(`clearShareUrlEpic: error: ${e}`);
      }
    }),
    ignoreElements()
  );

export const autoFetchWorkspaceDetailsEpic: AppEpic = (action$, state$) =>
  state$.pipe(
    filter((s) => !!s.context.workspaces.waitingToEditID),
    filter(
      (s) =>
        s.mode === AppMode.DASHBOARD || // -> "open shared workspace" button
        s.mode === AppMode.LOADING_DASHBOARD // -> loading workspace through URL
    ),
    filter((s) => !!s.context.user.token),
    filter((s) => {
      const workspace = getWorkspaceById(
        s,
        s.context.workspaces.waitingToEditID!
      );
      return workspace === undefined;
    }),
    map((s) => {
      return getWorkspaceDetails.request(s.context.workspaces.waitingToEditID!);
    }),
    throttleTime(100) // prevent multiple getWorkspaceDetails.requests for matching states
  );

export const autoEditQueuedWorkspaceWhenReadyEpic: AppEpic = (
  action$,
  state$,
  { getCurrentDate }
) =>
  state$.pipe(
    filter((s) => !!s.context.workspaces.waitingToEditID),
    filter(
      (s) =>
        s.mode === AppMode.LOADING_WORKSPACE ||
        s.mode === AppMode.LOADING_DASHBOARD || // -> loading workspace through URL
        s.mode === AppMode.DASHBOARD || // -> "open shared workspace" button
        s.mode === AppMode.EDITING
    ),
    filter((s) => !!s.context.workspaces.existing.length),
    filter((s) => {
      const workspace = getWorkspaceById(
        s,
        s.context.workspaces.waitingToEditID!
      );
      return (
        workspace !== undefined && workspace.workspaceType && !workspace.isFullscreen
      );
    }),
    mergeMap((s) => {
      const workspace = getWorkspaceById(
        s,
        s.context.workspaces.waitingToEditID!
      );
      if (
        workspace &&
        (workspace.workspaceType === "SKETCH" ||
          workspace.workspaceType === "FLOW")
      ) {
        openWorkspaceInNewWindow(workspace, s.context.config);
        return [showDashboard()];
      }
      if (
        workspace?.templateId &&
        !s.context.workspaces.waitingToEditAllowTemplateEdit
      ) {
        return [createWorkspaceFromTemplate(workspace)];
      }
      return [
        editWorkspace({
          workspaceId: s.context.workspaces.waitingToEditID!,
          lastAccess: getCurrentDate(),
        }),
      ];
    })
  );

export const pushStateOnEditWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { syncHistory }
) =>
  action$.pipe(
    filter(isActionOf(editWorkspace)),
    tap((a) => syncHistory(a.payload.workspaceId)),
    ignoreElements()
  );

export const popStateOnShowDashboardEpic: AppEpic = (
  action$,
  state$,
  { syncHistory }
) =>
  action$.pipe(
    filter(isActionOf(showDashboard)),
    tap(() => syncHistory("DASHBOARD")),
    ignoreElements()
  );

export const setFocusLoadedWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { focusWorkspace, sendMessageToWorkspace }
) =>
  action$.pipe(
    filter(isActionOf([editWorkspace, setFocusAction])),
    tap(() => {
      let loadedWorkspace = getWorkspaceFullscreen(state$.value);
      if (loadedWorkspace) {
        focusWorkspace(loadedWorkspace.workspaceId);
        sendMessageToWorkspace(loadedWorkspace.workspaceId, {
          action: "TOGGLE_FOCUS",
          isFocused: true,
        });
      }
    }),
    ignoreElements()
  );
