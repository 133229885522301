import { Button } from "@hoylu/client-common";
import React, { useRef, useState } from "react";
import { Localized } from "../../../strings";
import { sendEmail } from "../../../utils/sharing.helpers";
import Styles from "./CopyEmailLink.module.css";
import { TextCopier } from "../../../utils/text.copier";

type CopyEmailLinkProps = {
  workspaceName?: string;
  shareLink: string;
};

export const CopyEmailLink: React.FC<CopyEmailLinkProps> = ({
  workspaceName,
  shareLink,
}) => {
  const strings = Localized.object("COPY_EMAIL_LINK");
  const [copyClicked, setCopyClicked] = useState(false);
  const shareLinkRef = useRef<HTMLSpanElement>(null);

  const copyLink = (): void => {
    setCopyClicked(true);
    const copier = new TextCopier();
    copier.copy(shareLink);
  };

  const isSmall = window.innerWidth < 760;

  let copyText = isSmall
    ? copyClicked
      ? strings.SMALL_COPIED_LINK
      : strings.SMALL_COPY_LINK
    : copyClicked
    ? strings.COPIED_LINK
    : strings.COPY_LINK;
  let emailText = isSmall ? strings.SMALL_EMAIL : strings.EMAIL;

  return (
    <div className={Styles.copyEmailLink}>
      {shareLink && (
        <div className={Styles.description}>
          <span ref={shareLinkRef}>{shareLink.replace("https://", "")}</span>
        </div>
      )}
      <div className={Styles.buttons}>
        <Button
          type={"primary"}
          toolTip={strings.COPY_WORKSPACE_LINK}
          onClick={copyLink}
          classNames={[Styles.button]}
        >
          {copyText}
        </Button>
        <Button
          type={"primary"}
          toolTip={strings.EMAIL_WORKSPACE_LINK}
          onClick={() => sendEmail(workspaceName ?? "", shareLink)}
          classNames={[Styles.button]}
        >
          {emailText}
        </Button>
      </div>
    </div>
  );
};
