import type { Option } from "@hoylu/client-common";
import type { ProjectOption } from "./types";
import { Localized } from "../../strings";
import { RequestNewProject } from "./defaults";
import { requestNewProject } from "../../emails/request.new.project";

export const getEmptyProjectOption = (): Option<ProjectOption> => {
  const strings = Localized.object("FILTER_BY_NAME");

  return {
    label: strings.NEW_PROJECT,
    value: {
      id: RequestNewProject,
      icon: "plus",
      name: strings.NEW_PROJECT,
      status: null,
      color: "var(--global--accent--Color)",
      onSelected: requestNewProject,
      hasAdmin: false,
      endDate: null,
    },
  };
};
