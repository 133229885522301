import { UploadService } from "@hoylu/web-io-upload";
import { AppState } from "../../state/root.reducer";
import { from } from "rxjs";

let uploadServiceSingleton: UploadService | undefined = undefined;

function getUploadService(host: string) {
  if (!uploadServiceSingleton) {
    uploadServiceSingleton = new UploadService(host);
  }
  return uploadServiceSingleton;
}

export function uploadThumbnail(
  state: AppState,
  workspaceId: string,
  file: Blob
) {
  const config = state.context.config;
  const uploadService = getUploadService(config.serviceConfig.documentImages);
  uploadService.setTokenProvider(() => state.context.user);
  return from(
    uploadService
      .setLargeObject(workspaceId, "thumbnail", file.type, file)
      .then((r) => r.url)
      .catch((e) => {
        throw new Error(e);
      })
  );
}

export function removeThumbnail(state: AppState, workspaceId: string) {
  const config = state.context.config;
  const uploadService = getUploadService(config.serviceConfig.documentImages);
  uploadService.setTokenProvider(() => state.context.user);
  return from(
    uploadService
      .deleteObject(workspaceId, "thumbnail")
      .then((r) => r)
      .catch((e) => {
        throw new Error(e);
      })
  );
}
