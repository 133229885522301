import React, { FormEvent, useState } from "react";
import { Label, WorkspaceLabels } from "../../state/workspaces/types";
import { Localized } from "../../strings";
import { TileTag } from "./editLabel/TileTag";
import { Checkbox, Divider, TextInput } from "@hoylu/client-common";
import {
  loadHiddenWorkspaces,
  toggleHiddenWorkspaces,
} from "../../state/workspaces/workspaces.actions";
import { maximumDashboardPageSize } from "../../utils/defaultDashboardPageSize";
import { useDispatch, useSelector } from "react-redux";
import { isHiddenSectionVisible } from "../../state/workspaces/workspaces.selector";
import Styles from "./EditLabelContent.module.css";

export type EditLabelContentProps = {
  allLabels: WorkspaceLabels;
  selected: WorkspaceLabels | undefined;
  updateSelected: (selected: WorkspaceLabels) => void;
  canAddNewLabels?: boolean;
  subscribeLabelsChanges?: boolean;
  enableHiddenWorkspaceSearch?: boolean;
};

type Tag = {
  checked: boolean;
  name: string;
};

export const EditLabelContent: React.FC<EditLabelContentProps> = ({
  allLabels,
  selected,
  updateSelected,
  canAddNewLabels = true,
  subscribeLabelsChanges = false,
  enableHiddenWorkspaceSearch = false,
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("EDIT_LABEL_DIALOG");
  const currentWorkspaceLabels =
    selected?.map((label: Label) => ({
      checked: true,
      name: label.name,
    })) || [];
  const isChecked = useSelector(isHiddenSectionVisible);
  const [labelField, setLabelField] = useState("");
  const [tags, setTags] = useState(
    currentWorkspaceLabels
      .concat(
        allLabels.map((label: Label) => ({
          checked: false,
          name: label.name,
        })) || []
      )
      .filter(
        (tag: Tag, index, self) =>
          self.map((val: Tag) => val.name).indexOf(tag.name) === index
      )
  );
  if (subscribeLabelsChanges) {
    // todo Workaround?
    const selectedLabelsOneDimension = currentWorkspaceLabels.map(
      (label: Label) => label.name
    );
    const updatedTags = tags.map((label: Label) => ({
      name: label.name,
      checked: selectedLabelsOneDimension.includes(label.name),
    }));
    if (JSON.stringify(updatedTags) !== JSON.stringify(tags)) {
      setTags(updatedTags);
    }
  }

  const searchTagIndex = (str: string) => {
    return tags.findIndex(
      (tag: Tag) =>
        tag.name.trim().toLocaleLowerCase() === str.trim().toLocaleLowerCase()
    );
  };

  const searchTag = (str: string) => {
    return tags.find(
      (tag: Tag) =>
        tag.name.trim().toLocaleLowerCase() === str.trim().toLocaleLowerCase()
    );
  };

  const filterTags = (str: string) => {
    return tags.filter(
      (tag: Tag) =>
        tag.name
          .trim()
          .toLocaleLowerCase()
          .indexOf(str.trim().toLocaleLowerCase()) !== -1
    );
  };

  const requestUpdateLabels = (tags: Tag[]) => {
    updateSelected(
      tags
        .filter((tag: Tag) => tag.checked)
        .map((tag: Tag) => ({ name: tag.name }))
    );
  };

  const submitAction = (e: FormEvent) => {
    e.preventDefault();
    const value = labelField.trim();
    if (value === "") return;
    const existTagIndex = searchTagIndex(value);
    let newTags = [...tags];
    if (existTagIndex !== -1) {
      newTags[existTagIndex] = {
        ...newTags[existTagIndex],
        checked: !newTags[existTagIndex].checked,
      };
    } else if (canAddNewLabels) {
      newTags = tags.concat([{ checked: true, name: labelField }]);
    }
    setTags(newTags);
    requestUpdateLabels(newTags);
    setLabelField("");
  };

  const onToggleTag = (text: string, checked: boolean) => {
    const newTags = tags.map((tag: Tag) => ({
      name: tag.name,
      checked: tag.name === text ? checked : tag.checked,
    }));
    setTags(newTags);
    requestUpdateLabels(newTags);
  };

  const showHiddenWorkspaces = () => {
    if (!isChecked) {
      dispatch(loadHiddenWorkspaces.request({ limit: maximumDashboardPageSize}));
    }
    dispatch(toggleHiddenWorkspaces());
  };

  const foundTag = searchTag(labelField);
  const filteredTags = filterTags(labelField);
  const hasCheckedTag = foundTag && foundTag.checked;
  const shouldDisplayAddRemoveIcon =
    labelField.trim() !== "" && (foundTag || canAddNewLabels);
  const inputClass = [Styles.input];
  if (shouldDisplayAddRemoveIcon) {
    inputClass.push(Styles.rightSpacing);
  }

  return (
    <form autoComplete="off" onSubmit={submitAction}>
      <div className={Styles.content} data-test-id="workspace-labels-editor">
        <TextInput
          label={""}
          watermark={strings.ENTER_LABEL}
          onChange={(value) => setLabelField(value)}
          onKeyUp={(e) => {
            if (e.key === "Escape") setLabelField("");
          }}
          value={labelField}
          isFocus={true}
          classNames={inputClass}
        />
        {shouldDisplayAddRemoveIcon && (
          <div
            title={hasCheckedTag ? strings.REMOVE : strings.ADD}
            className={`${Styles.addRemoveIcon}  ${
              hasCheckedTag ? "hoylu-ui-icons-x" : "hoylu-ui-icons-plus"
            }`}
            onClick={submitAction}
          />
        )}
      </div>
      <div className={Styles.labels}>
        {filteredTags.map((tag: Tag) => (
          <TileTag
            key={tag.name}
            text={tag.name}
            checked={tag.checked}
            onToggle={onToggleTag}
          />
        ))}
      </div>
      {enableHiddenWorkspaceSearch && (
        <>
          <Divider horizontal={true} width={"80%"} />
          <div className={Styles.hiddenWorkspaceSearch}>
            <Checkbox checked={isChecked} onChange={showHiddenWorkspaces}>
              {Localized.string(
                "FILTER_BY_WORKSPACE_LABEL.SHOW_HIDDEN_WORKSPACES"
              )}
            </Checkbox>
          </div>
        </>
      )}
    </form>
  );
};
