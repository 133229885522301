import { ProjectStatus } from "../../state/workspaces/types";
import { ProjectStatuses } from "../../state/workspaces/workspaces.reducer";
import Styles from "./ProjectSelectorLabel.module.css";
import React from "react";
import { hiconToHoyluUiIconsCentered } from "../../utils/icon.map";
import { hexToRgba } from "../../utils/hexToRgba";
import { remainingDaysInProject } from "./remaining.days.in.project";
import { Label } from "../label";
import { Localized } from "../../strings";

type ProjectSelectorLabelProps = {
  icon: string;
  name: string;
  status: ProjectStatus | null;
  color?: string;
  endDate?: string | null;
  hasAdmin: boolean;
};

export function ProjectSelectorLabel({
  icon,
  name,
  status,
  color,
  endDate,
  hasAdmin,
}: ProjectSelectorLabelProps) {
  const daysRemaining = remainingDaysInProject(endDate);
  const isProjectInactive = status === ProjectStatuses.Inactive;
  const colorVariant = isProjectInactive
    ? hexToRgba(color ?? "", 0.5, "#716C83")
    : color;

  return (
    <div className={Styles.projectSelector} title={name}>
      <div
        style={{ color: colorVariant }}
        className={`${
          hiconToHoyluUiIconsCentered(icon) || "hoylu-ui-icons-box"
        } ${Styles.projectIcon}`}
      />
      <div
        className={`${Styles.projectName} ${
          isProjectInactive ? Styles.inactive : ""
        }`}
      >
        {name}
      </div>
      {!!daysRemaining && hasAdmin && (
        <Label
          text={Localized.string("PROJECT.DURATION", daysRemaining)}
          title={Localized.string("PROJECT.REMAINING_DURATION", daysRemaining)}
        />
      )}
    </div>
  );
}
