import React from "react";
import Styles from "./SectionLayout.module.css";

type SectionLayoutProps = {
  title?: string;
  className?: string;
  ariaLabel?: string;
};

export const SectionLayout = ({
  title,
  className = "",
  ariaLabel = title,
  children,
}: React.PropsWithChildren<SectionLayoutProps>) => (
  <section
    className={`${Styles.sectionLayout} ${className}`}
    tabIndex={0}
    aria-label={ariaLabel}
  >
    <div className={Styles.content}>
      {title && <span className={Styles.title}>{title}</span>}
      {children}
    </div>
  </section>
);

export default SectionLayout;
