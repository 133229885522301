import { ShareType, ShareOptionValue } from "../ui/modals/sharing/ShareType";
import { WorkspacePermissionsAndUsers } from "../state/workspaces/types";
import { Localized } from "../strings";
import { jwtDecode } from "jwt-decode";
import { UserFullPayload } from "./credential.helpers";

export function calculatePermissions(globalAccess: string) {
  let publicPermissionLevel: ShareOptionValue;
  if (globalAccess === "WRITE") {
    publicPermissionLevel = ShareType.EDIT;
  } else if (globalAccess === "READ") {
    publicPermissionLevel = ShareType.READ;
  } else {
    publicPermissionLevel = ShareType.NO_ACCESS;
  }
  return publicPermissionLevel;
}

export function sortUsers(permissionLevelSets: WorkspacePermissionsAndUsers) {
  return [
    ...permissionLevelSets.administrators.map((user) => ({
      email: user,
      permission: ShareType.ADMIN,
    })),
    ...permissionLevelSets.writers.map((user) => ({
      email: user,
      permission: ShareType.EDIT,
    })),
    ...permissionLevelSets.readers.map((user) => ({
      email: user,
      permission: ShareType.READ,
    })),
    ...permissionLevelSets.unspecifiedUsers.map((user) => ({
      email: user,
      permission: ShareType.NO_ACCESS,
    })),
  ].sort((userA, userB) => {
    if (userA.email < userB.email) {
      return -1;
    }
    if (userA.email > userB.email) {
      return 1;
    }
    return 0;
  });
}

export function formatWorkspaceId(workspaceId: string) {
  let formattedId: string = "";
  for (let i = 1; i <= workspaceId.length; i++) {
    formattedId += workspaceId[i - 1];
    if (i % 3 === 0 && i !== workspaceId.length) {
      formattedId += " ";
    }
  }
  return formattedId;
}

export const sanitizeUrl = (string: string): string => {
  const stringUrl = string.trim();
  try {
    let url = new URL(stringUrl);
    if (url.protocol === "http:" || url.protocol === "https:") {
      // When string is a valid url - remove www. prefix from hostname
      return url.hostname.replace("www.", "");
    }
  } catch (_) {
    // When string is not a valid url - remove www. prefix from string
    return string.replace("www.", "");
  }
  return string;
};

export const isOrganizationAdmin = (token: string) => {
  if (!token) return false;

  const userFullPayload = jwtDecode<UserFullPayload>(token);
  return !!userFullPayload.Permissions.Administer[0];
}

export function sendEmail(
  workspaceName: string,
  shareLink: string,
  recipients?: string[]
) {
  workspaceName = sanitizeUrl(workspaceName);
  const strings = Localized.object("COPY_EMAIL_LINK");
  let mailTo = "";
  if (recipients) {
    recipients.forEach((recipient, index) => {
      mailTo += recipient;
      if (recipients.length - 1 !== index) {
        mailTo += ";";
      }
    });
  }
  const emailLink =
    `mailto:${mailTo}?subject=${
      strings.WORKSPACE_SHARED_WITH_YOU
    } ${encodeURIComponent(workspaceName)}&` +
    `body=${strings.WORKSPACE_SHARED_WITH_YOU}%0D${encodeURIComponent(
      workspaceName
    )}%0D${shareLink}%0D%0D${strings.LEARN_MORE}`;
  window.open(emailLink, "_blank");
}
