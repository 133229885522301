import { WorkspaceType } from "../state/workspaces/WorkspaceType";
import { ConfigState } from "../state/config/config.reducer";
import { AppState } from "../state/root.reducer";
import { StoredSearchParams } from "../state/workspaces/workspaces.reducer";

export type DeepLinkTypes = "bookmark" | "element" | "layout";

/** Based on generateDeepLink in packages/web-suite/src/util/deep.link.utils.ts */
export function generateDeepLink(
  linkType: DeepLinkTypes,
  workspaceId: string,
  elementId: string
) {
  const origin = new URL(window.location.href).origin;

  return origin ? `${origin}/${workspaceId}?${linkType}=${elementId}` : null;
}

export function getOriginLink(config: ConfigState) {
  return config.isBeta
    ? config.serviceConfig.hoylu_appbeta
    : config.serviceConfig.hoylu_app;
}

export function getShareLink(workspaceId: string, config: ConfigState) {
  const originLink = getOriginLink(config);
  return originLink + "/" + workspaceId;
}

export function getProjectDeeplink(origin: string, projectId: string) {
  return `${origin}/?project=${projectId}`;
}

export function checkUrlPathForWorkspaceId(path: string): string | false {
  let match = path.match(/\/(\d{9})/);

  if (match && match[1]) {
    return match[1];
  }
  return false;
}

export function getSearchParamsForDeepLinks() {
  return new URL(window.location.href).search;
}

export function checkURLForProjectLink() {
  return new URLSearchParams(window.location.search).has("project");
}

export function getProjectIdFromUrl() {
  return new URLSearchParams(window.location.search).get("project");
}

const urlCommands = ["new"] as const;
export type UrlCommand = typeof urlCommands[number]; // the magic stuff to have string literal type based on an const array

export function checkURLPathForCommand(path: string): UrlCommand | false {
  let match = path.match(/\/([a-zA-Z]*)/);

  if (match && match[1] && urlCommands.includes(match[1] as UrlCommand)) {
    return match[1] as UrlCommand;
  }
  return false;
}

/** check for mode=webex in URL parameters */
export function isWebexModeUrl(locationSearch: URLSearchParams) {
  return locationSearch.get("mode") === "webex";
}

/** check for `&teams`, `/teams` (both deprecated) and `mode=teams` in URL parameters */
export function isTeamsModeUrl(url: URL) {
  const search = url.searchParams;
  if (search.get("mode") === "teams") return true;

  const path = url.pathname;
  // check for configuration url (as defined in teams manifest)
  if (path === "/teams") return true;

  //TODO: remove support for old teams URLs once they are not used anymore
  return path === "/&teams" || !!path.match(/^\/\d{9}&teams$/);
}

/** Add mode=teams to url*/
export function appendTeamsMode(locationHref: string) {
  const url = new URL(locationHref);
  url.searchParams.set("mode", "teams");
  return url.toString();
}

/** Add mode=webex to url*/
export function appendWebexMode(locationHref: string) {
  const url = new URL(locationHref);
  url.searchParams.set("mode", "webex");
  return url.toString();
}

export function getLoginRedirectUrl(appState: AppState) {
  let url = window.location.origin;
  const wState = appState.context.workspaces;
  if (wState.waitingToEditID) {
    url += `/${wState.waitingToEditID}`;
  } else if (wState.isCreatingNewWorkspaceUrl) {
    url += "/new";
  }

  const cState = appState.context.config;
  if (cState.webexMode) {
    url = appendWebexMode(url);
  } else if (cState.teamsClient) {
    url = appendTeamsMode(url);
  }

  return url;
}

export function getWorkspaceURL(
  workspaceType: WorkspaceType = WorkspaceType.HOYLU,
  workspaceId: string,
  config: ConfigState,
  moduleName: string,
  storedSearchParams?: StoredSearchParams
) {
  if (__isLegacy(workspaceType)) {
    return __getLegacyURL(
      workspaceType === WorkspaceType.SKETCH ? "sketch" : "flow",
      workspaceId,
      config
    );
  }
  if (__useW2020Legacy(moduleName, config)) {
    return `w2020/index.html?w=${workspaceId}`;
  }

  let workspaceUrl = `w/index.html?w=${workspaceId}`;

  if (storedSearchParams && storedSearchParams[workspaceId]) {
    workspaceUrl += `&${storedSearchParams[workspaceId]}`;
  }

  return workspaceUrl;
}

/** Only return the url again, if it is a safe URL, otherwise return undefined */
export function dropInvalidExternalLink(
  urlCandidate: string | undefined | null
) {
  if (!urlCandidate) return urlCandidate;

  try {
    const url = new URL(urlCandidate);
    if (url.protocol === "https:") {
      return url.toString();
    }
  } catch {
    // drop URLs that are not well-formed
  }
  return undefined;
}

// ** Begin Legacy web-app support functions **//

function __useStagingEnv(config: ConfigState) {
  if (config.isDev) return true;
  if (config.serviceConfig.login.lastIndexOf("hoylu.com") !== -1) return false;
  if (config.serviceConfig.login.split(".")[1] === "staging") return true;
  return false;
}

function __isLegacy(workspaceType: WorkspaceType): boolean {
  return (
    workspaceType === WorkspaceType.FLOW ||
    workspaceType === WorkspaceType.SKETCH
  );
}

function __useW2020Legacy(moduleName: string, config: ConfigState): boolean {
  return (
    moduleName === "pullplanning" ||
    moduleName === "pullplanningv2" ||
    (moduleName === "pullplanningv3" &&
      config.featureFlags.redirectPullPlanningV3ToLegacy)
  );
}

function __getLegacyURL(
  type: "sketch" | "flow",
  workspaceId: string,
  config: ConfigState
) {
  if (__useStagingEnv(config)) {
    return `https://${type}-beta.hoylu.com/${workspaceId}?serviceEnv=staging`;
  }
  return config.serviceConfig.login.replace("login", type) + `/${workspaceId}`;
}

// ** End Legacy web-app support functions **//
