import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import type { RootState } from "typesafe-actions";
import {
  config,
  duplicateApiSupportsPassword,
} from "../../state/config/config.selector";
import {
  isPasswordRequired,
  user,
  workspacesPrivateByDefault,
} from "../../state/user/user.selector";
import {
  cancelDashboardOption,
  duplicateWorkspace,
  queueEditWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { augmentWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { DuplicateWorkspaceBaseDialog } from "./DuplicateWorkspaceDialog";

const getTemplateWorkspaceDetails = createSelector(
  [
    (state: RootState) => state.context.workspaces.waitingToCreateFromTemplate,
    config,
    user,
  ],
  (workspace, config, user) => {
    if (workspace) {
      return augmentWorkspaceDetails(workspace, config, user.profile.email);
    }
    return undefined;
  }
);

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getTemplateWorkspaceDetails(state),
  isPasswordRequired: isPasswordRequired(state),
  workspacesPrivateByDefault: workspacesPrivateByDefault(state),
  duplicateApiSupportsPassword: duplicateApiSupportsPassword(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  duplicateWorkspace: duplicateWorkspace.request,
  editWorkspaceTemplate: queueEditWorkspace,
};

type CreateWorkspaceFromTemplateProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const CreateWorkspaceFromTemplate: React.FC<CreateWorkspaceFromTemplateProps> =
  ({
    cancelDashboardOption,
    duplicateWorkspace,
    editWorkspaceTemplate,
    selectedWorkspaceDetails,
    isPasswordRequired,
    workspacesPrivateByDefault,
    duplicateApiSupportsPassword,
  }) => {
    const t = (address: string, ...parameters: any) =>
      Localized.string(
        "CREATE_WORKSPACE_FROM_TEMPLATE_DIALOG." + address,
        parameters
      );

    if (!selectedWorkspaceDetails) return null;

    const isWorkspaceAdmin = !!selectedWorkspaceDetails?.isAdmin;

    const editWorkspace = isWorkspaceAdmin
      ? () => {
          cancelDashboardOption();
          editWorkspaceTemplate({
            workspaceId: selectedWorkspaceDetails.workspaceId,
            allowEditTemplates: true,
          });
        }
      : undefined;

    return (
      <DuplicateWorkspaceBaseDialog
        cancel={cancelDashboardOption}
        apply={(params) =>
          duplicateWorkspace({
            workspaceId: selectedWorkspaceDetails.workspaceId,
            open: true,
            ...params,
          })
        }
        alternate={editWorkspace}
        selectedWorkspaceDetails={selectedWorkspaceDetails}
        isPasswordRequired={isPasswordRequired}
        workspacesPrivateByDefault={workspacesPrivateByDefault}
        duplicateApiSupportsPassword={duplicateApiSupportsPassword}
        strings={{
          shortTitle: t("SHORT_TITLE"),
          longTitle: t("SHORT_TITLE"),
          newWorkspaceNameLabel: t("NEW_WORKSPACE_NAME"),
          newWorkspaceNameTooltip: t("NEW_WORKSPACE_NAME"),
          newWorkspaceNameDefault: t("NEW_WORKSPACE_NAME"),
          passwordLabel: t("PASSWORD"),
          passwordTooltip: t("PASSWORD"),
          applyButtonText: t("CREATE"),
          cancelButtonText: t("CANCEL"),
          alternateButtonText: t("EDIT_TEMPLATE"),
          alternateButtonTooltip: t("EDIT_TEMPLATE_TOOLTIP"),
          newPasswordLabel: t("NEW_PASSWORD"),
          showPassword: t("SHOW_PASSWORD"),
          characters: t("CHARACTERS"),
          privateWorkspace: t("PRIVATE_WORKSPACE"),
          privateWorkspaceTooltip: t("PRIVATE_WORKSPACE_TOOLTIP"),
        }}
      />
    );
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkspaceFromTemplate);
