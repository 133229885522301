/** This function may be used to deep compare objects. Especially useful for React.memo comparator.
 * Note: It compares object for every value in it. Also for NaN values.
 * Even if NaN === NaN is always false by default in JS, compareDeeply(NaN, NaN) will return true.
 * Reference:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NaN#testing_against_nan */
export const compareDeeply = (obj1: any, obj2: any) : boolean => {
      if (obj1 === obj2) return true; // also matches undefined === undefined and null === null
      if (
        obj1 === undefined ||
        obj2 === undefined ||
        obj1 === null ||
        obj2 === null
      ) {
        return false;
      }

    if(Number.isNaN(obj1) && Number.isNaN(obj2)){
        return true;
    }

    if(obj1 !== Object(obj1) && obj2 !== Object(obj2))
        return obj1 === obj2;

    if(Object.keys(obj1).length !== Object.keys(obj2).length)
        return false;

    for(let key in obj1)
    {
        if(!(key in obj2)) return false;
        if(obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)){
            if(!compareDeeply(obj1[key], obj2[key])) return false;
        }else {
            return false;
        }
    }
    return true;
}
