import React from "react";
import { connect } from "react-redux";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import headerGraphic from "../../assets/images/space-illustration.svg";
import { RootState } from "typesafe-actions";
import { isElectronMac } from "../../utils/is.electron.mac";
import { ErrorDialogWithImage } from "./errorDialogs/ErrorDialogWithImage";

const mapDispatchToProps = {
  onCancel: cancelDashboardOption,
  onConfirm: (url: string) => {
    window.open(url, "_blank");
    return cancelDashboardOption();
  },
};

const mapStateToProps = (state: RootState) => ({
  title: state.context.workspaces.modalTitle,
  text: state.context.workspaces.modalText,
  buttonText: state.context.workspaces.modalButtonText,
  buttonLinkUrl: state.context.workspaces.modalButtonLinkUrl,
  enableUpgrade: state.context.config.featureFlags.enableUpgradeWithinApp,
});

type FreeUpsellDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const FreeUpsellDialog: React.FC<FreeUpsellDialogProps> = ({
  onCancel,
  onConfirm,
  title,
  text,
  buttonText,
  buttonLinkUrl,
  enableUpgrade
}) => {
  const isMacApp: boolean = isElectronMac();
  const enableUpgradeWithinApp = buttonLinkUrl === "https://www.hoylu.com/pricing/" ? enableUpgrade : true;

  return (
    <ErrorDialogWithImage
      onCancel={onCancel}
      onButtonClick={() => onConfirm(buttonLinkUrl ?? "")}
      imageUrl={headerGraphic}
      title={title ?? ""}
      text={text ?? ""}
      buttonText={isMacApp ? undefined : buttonText}
      showButton={enableUpgradeWithinApp}
      showExternalLinkSymbol={!!buttonLinkUrl}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeUpsellDialog);
