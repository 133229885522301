import React, { FormEvent, useState } from "react";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import Styles from "./ConfirmationDialog.module.css";
import {
  Button,
  Checkbox,
  DraggableWindowHeader,
  TextInput,
} from "@hoylu/client-common";
import { textInputLocalization } from "../../utils/text.input.localization";

type ConfirmationDialogProps = {
  icon: string;
  cancelAction: () => void;
  title: string;
  message: string;
  submitAction: (e: FormEvent) => void;
  buttonText: string;
  passwordRequired?: boolean;
  confirmWithCheckmark?: boolean;
  confirmDelete?: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  icon,
  cancelAction,
  title,
  message,
  submitAction,
  buttonText,
  passwordRequired = false,
  confirmWithCheckmark = false,
  confirmDelete = "",
}) => {
  const strings = Localized.object("CONFIRMATION_DIALOG");
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    setChecked(!checked);
  };

  const buttons = () => (
    <div className={Styles.footer}>
      {confirmWithCheckmark && (
        <div className={Styles.confirmationCheckbox}>
          <Checkbox checked={checked} onChange={toggleChecked}>
            {confirmDelete}
          </Checkbox>
        </div>
      )}
      <div className={Styles.buttons}>
        <Button
          type={"negative"}
          toolTip={strings.CANCEL}
          onClick={cancelAction}
        >
          {strings.CANCEL}
        </Button>
        <Button
          type={"primary"}
          toolTip={buttonText}
          onClick={(e) => !passwordRequired && submitAction(e)}
          disabled={confirmWithCheckmark ? !checked : false}
          formType={passwordRequired ? "submit" : "button"}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal>
      <div className={Styles.confirmationDialog}>
        <DraggableWindowHeader
          icon={icon}
          title={title}
          onClose={cancelAction}
        />
        <div className={Styles.message}>{message}</div>
        {passwordRequired ? (
          <form className={Styles.form} onSubmit={submitAction}>
            <TextInput
              type={"password"}
              label={strings.PASSWORD}
              name={"password"}
              localizationDict={textInputLocalization()}
            />
            {buttons()}
          </form>
        ) : (
          buttons()
        )}
      </div>
    </Modal>
  );
};
