import React from "react";
import { Modal } from "./Modal";
import { Localized } from "../../strings";
import { LdsRoller } from "./LdsRoller";
import Styles from "./WorkspaceDialog.module.css";

export const RequestingSnapshotDialog = () => {
  const strings = Localized.object("THUMBNAIL_EDITOR");

  return (
    <Modal>
      <div className={Styles.header}>
        <div className={Styles.title}>{strings.GENERATE_THUMBNAIL}</div>
        <LdsRoller />
      </div>
    </Modal>
  );
};
