import React from "react";
import HoyluLogo from "../HoyluLogo";
import { RootState } from "typesafe-actions";
import { connect } from "react-redux";
import { FlatRippleSpinner } from "../RippleSpinner";
import { AppMode } from "../../state/mode/mode.reducer";
import Styles from "./SimplifiedHeader.module.css";

type SimplifiedHeaderProps = ReturnType<typeof mapStateToProps>;

export const SimplifiedHeader: React.FC<SimplifiedHeaderProps> = ({
  isLoading,
}) => (
  <div className={Styles.simplifiedHeader}>
    <div className={Styles.headerDivider} />
    <div className={Styles.logoContainer}>
      <HoyluLogo />
    </div>
    {isLoading && <FlatRippleSpinner />}
  </div>
);

const mapStateToProps = (state: RootState) => ({
  isLoading:
    state.mode === AppMode.LOADING_DASHBOARD ||
    state.mode === AppMode.LOADING_WORKSPACE ||
    state.context.workspaces.isLoadingSelectedProjectWorkspaces,
});

export default connect(mapStateToProps)(SimplifiedHeader);
