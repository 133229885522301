import { isWebexApplication } from "./webex.types";
import { log } from "@hoylu/client-common";
import { setWebexShareState } from "../../state/config/config.actions";
import { Store } from "redux";

const webexEmbeddedAppSdk =
  "https://binaries.webex.com/static-content-pipeline/webex-embedded-app/v1/webex-embedded-app-sdk.js";

export function loadWebexSdk(store: Store): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = webexEmbeddedAppSdk;
    script.addEventListener("load", async () => {
      const webexApp = getWebexApp();
      if (!webexApp) {
        return reject(Error("webex: Failed to get webex application object"));
      }
      try {
        // wait for webex to be ready
        await webexApp.onReady();
        webexApp.listen().then(() => {
          webexApp.on("application:shareStateChanged", (e: boolean) => {
              store.dispatch(setWebexShareState(e))
          })
        })
        .catch(function (reason) {
            console.error("listen: fail reason=" + reason);
        });
        resolve();
      } catch (e) {
        log.error("webex: loadWebexSdk onReady error", e as any);
        reject(e);
      }
    });
    script.addEventListener("error", (e) => {
      log.error("webex: loadWebexSdk script-load error", { errorEvent: e });
      reject(e);
    });
    document.head.appendChild(script);
  });
}

function getWebexApp() {
  //@ts-expect-error because window type definition does not include webex
  const webex = window.Webex;
  if ("Application" in webex) {
    const app = new webex.Application();
    if (isWebexApplication(app)) return app;
  }
  return undefined;
}

export async function setWebexShareUrl(workspaceId: string, title: string) {
  const webex = getWebexApp();
  if (!webex) throw Error("webex: Failed to get webex application object");
  const externalUrl = window.location.origin + "/" + workspaceId;
  const internalUrl = externalUrl + "?mode=webex";
  const result = await webex.setShareUrl(internalUrl, externalUrl, title);
  log.featureUsed("pin-workspace", "webex-integration", { shareurl: internalUrl });
  // see https://developer.webex.com/docs/sdks/embedded-apps-api-reference#Application+setShareUrl
  if (result === 0) return;
  if (result === 1) throw new Error("webex: setShareUrl: generic error");
  if (result === 2) throw new Error("webex: setShareUrl: invalid arguments");
  throw new Error("webex: setShareUrl: unkown return value");
}

export async function clearWebexShareUrl() {
  const webex = getWebexApp();
  if (!webex) throw Error("webex: Failed to get webex application object");
  const result = await webex.clearShareUrl();
  log.info("webex: clearShareUrl returned " + result);
}
