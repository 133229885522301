import React from "react";
import { PermissionIcon } from "./PermissionIcon";

export type LockIconProperties = {
  isAdmin: boolean;
  hasPassword: boolean;
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
};

export const LockIcon: React.FC<LockIconProperties> = ({
  isAdmin,
  hasPassword,
  isActive,
  toggleActive,
}) => {
  const unlocked = "hoylu-ui-icons-unlock";
  const locked = "hoylu-ui-icons-committed";
  const currentIcon = hasPassword ? locked : unlocked;

  return (
    <div>
      {isAdmin && (
        <PermissionIcon
          icon={currentIcon}
          isActive={isActive}
          toggleActive={toggleActive}
        />
      )}
    </div>
  );
};
