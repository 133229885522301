import {
  createWorkspace,
  deleteWorkspace,
  getWorkspaceDetails,
  toggleWorkspaceVisibility,
  updateWorkspaceDetails,
  removeWorkspacePassword,
  duplicateWorkspace,
  setWorkspacePassword,
  updateWorkspaceLabels,
  addWorkspaceToProject,
  removeWorkspaceFromProject,
  assignableProjects,
  getWorkspacesOfProject,
  loadMoreWorkspaces,
  populateAllWorkspaces,
  upsertWorkspaceUsers,
  removeWorkspaceUsers,
  updateWorkspaceName,
} from "./workspaces.actions";

// actions to automatically cause a token-refresh on 401 and then be re-tried
// also by default produces error message on any other error
export const retryableFailures = [
  createWorkspace.failure,
  deleteWorkspace.failure,
  getWorkspaceDetails.failure,
  updateWorkspaceLabels.failure,
  toggleWorkspaceVisibility.failure,
  updateWorkspaceDetails.failure,
  upsertWorkspaceUsers.failure,
  removeWorkspaceUsers.failure,
  updateWorkspaceName.failure,
  removeWorkspacePassword.failure,
  duplicateWorkspace.failure,
  setWorkspacePassword.failure,
  assignableProjects.failure,
  addWorkspaceToProject.failure,
  removeWorkspaceFromProject.failure,
  getWorkspacesOfProject.failure,
  loadMoreWorkspaces.failure,
  populateAllWorkspaces.failure,
];
