import React from "react";
import { TextInput } from "@hoylu/client-common";
import Styles from "./PasswordInput.module.css";
import { textInputLocalization } from "../../../utils/text.input.localization";

type PasswordInputProps = {
  password: string;
  passwordOnChange: (value: string) => void;
  placeholder: string;
  label?: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  passwordOnChange,
  placeholder,
  label = "",
}) => {
  return (
    <TextInput
      type={"password"}
      label={label}
      watermark={placeholder}
      onChange={passwordOnChange}
      value={password}
      useTogglePassword={true}
      classNames={[Styles.passwordInput]}
      localizationDict={textInputLocalization()}
    />
  );
};
