import type { RootState } from "typesafe-actions";
import { createSelector } from "reselect";
import { getSelectedProject } from "../state/workspaces/projects.selector";
import { getOriginLink, getProjectDeeplink } from "./url.helpers";

export const originLink = (state: RootState): string =>
  getOriginLink(state.context.config);

export const projectDeepLink: (state: RootState) => string = createSelector(
  originLink,
  getSelectedProject,
  (origin, project): string => {
    if (!project?.id) return "";
    return getProjectDeeplink(origin, project.id);
  }
);
