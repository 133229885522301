import React from "react";
import { Localized } from "../../strings";
import { useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import MenuItem from "./MenuItem";
import { enableWorkspaceInfo } from "../../state/config/config.selector";
import Styles from "./MoreOptions.module.css";
import { Divider } from "@hoylu/client-common";

type MoreOptionsProps = {
  shareClicked: () => void;
  workspaceInfoClicked: () => void;
  hideClicked: () => void;
  unhideClicked: () => void;
  leaveClicked: () => void;
  deleteClicked: () => void;
  duplicateClicked: () => void;
  editLabelClicked: () => void;
  removeFromProjectClicked: () => void;
  editTitleClicked: () => void;
  downloadClicked: () => void;
  canHide: boolean;
  canLeave: boolean;
  canDelete: boolean;
  canDuplicate: boolean;
  canEditLabels: boolean;
  canAssignProject: boolean;
  canRemoveProject: boolean;
  canEditTitle: boolean;
  canDownload: boolean;
  isHidden: boolean;
};

export const MoreOptions: React.FC<MoreOptionsProps> = ({
  shareClicked,
  workspaceInfoClicked,
  hideClicked,
  unhideClicked,
  leaveClicked,
  deleteClicked,
  duplicateClicked,
  editLabelClicked,
  removeFromProjectClicked,
  editTitleClicked,
  downloadClicked,
  canHide,
  canLeave,
  canDelete,
  canDuplicate,
  canEditLabels,
  canAssignProject,
  canRemoveProject,
  canEditTitle,
  canDownload,
  isHidden,
}) => {
  const strings = Localized.object("MORE_OPTIONS");
  const ellipsis = "...";
  const showWorkspaceInfo = useSelector((state: RootState) =>
    enableWorkspaceInfo(state)
  );

  return (
    <div className={Styles.moreOptions}>
      {showWorkspaceInfo && (
        <MenuItem
          icon={"hoylu-ui-icons-blank"}
          label={strings.WORKSPACE_INFO}
          onClick={workspaceInfoClicked}
        />
      )}
      <MenuItem
        icon={"hoylu-ui-icons-share"}
        label={strings.SHARE}
        title={strings.SHARE_SETTINGS}
        onClick={shareClicked}
      />
      {(canEditTitle || canAssignProject) && (
        <Divider horizontal={true} width={"100%"} />
      )}
      {!showWorkspaceInfo && canEditTitle && (
        <MenuItem
          icon={"hoylu-ui-icons-edit"}
          label={strings.EDIT_TITLE}
          onClick={editTitleClicked}
        />
      )}
      {canEditLabels && (
        <MenuItem
          icon={"hoylu-ui-icons-labels-edit"}
          label={strings.ADD_LABEL}
          title={strings.LABELS}
          onClick={editLabelClicked}
        />
      )}
      {canAssignProject && (
        <MenuItem
          icon={"hoylu-ui-icons-box"}
          label={strings.ASSIGN_PROJECT}
          onClick={workspaceInfoClicked}
        />
      )}
      {canRemoveProject && (
        <MenuItem
          icon={"hoylu-ui-icons-incomplete"}
          label={strings.REMOVE_PROJECT}
          onClick={removeFromProjectClicked}
        />
      )}
      {canDownload && (
        <MenuItem
          icon="hoylu-ui-icons-import"
          label={strings.DOWNLOAD_ARCHIVE}
          onClick={downloadClicked}
        />
      )}
      <Divider horizontal={true} width={"100%"} />
      {canDuplicate && (
        <MenuItem
          icon={"hoylu-ui-icons-copy"}
          label={strings.DUPLICATE + ellipsis}
          title={strings.DUPLICATE_WORKSPACE}
          onClick={duplicateClicked}
        />
      )}
      {canHide &&
        (isHidden ? (
          <MenuItem
            icon={"hoylu-ui-icons-indicator-show"}
            label={strings.UNHIDE + ellipsis}
            title={strings.UNHIDE_WORKSPACE}
            onClick={unhideClicked}
          />
        ) : (
          <MenuItem
            icon={"hoylu-ui-icons-indicator-hide"}
            label={strings.HIDE + ellipsis}
            title={strings.HIDE_WORKSPACE}
            onClick={hideClicked}
          />
        ))}
      {canLeave && (
        <MenuItem
          icon={"hoylu-ui-icons-leave"}
          label={strings.LEAVE + ellipsis}
          title={strings.LEAVE_WORKSPACE}
          onClick={leaveClicked}
        />
      )}
      {canDelete && (
        <MenuItem
          icon={"hoylu-ui-icons-trash"}
          label={strings.DELETE + ellipsis}
          title={strings.DELETE_WORKSPACE}
          onClick={deleteClicked}
        />
      )}
    </div>
  );
};
