import { DateTime } from "luxon";

export const remainingDaysInProject = (
  endDate: string | undefined | null
): number | undefined => {
  if (!endDate) return undefined;
  let today = DateTime.utc();
  today = today.startOf("day");
  const endDateUtc = fromIsoToUtc(endDate);

  const difference = differenceInDays(today, endDateUtc);

  if (!isDifferenceValid(difference)) return undefined;

  return difference;
};

const fromIsoToUtc = (date: string) => {
  return DateTime.fromISO(date, { zone: "utc" }).startOf("day");
};

const differenceInDays = (start: DateTime, end: DateTime) => {
  const difference = end.diff(start, "days").days;
  return Math.floor(difference);
};

const isDifferenceValid = (difference: number) => {
  return difference > 0 && difference <= 30;
};
