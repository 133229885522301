import type {
  WorkspacePermissionsAndUsers,
  WorkspaceLabels,
} from "../../state/workspaces/types";
import type { WorkspaceTypeName } from "../../state/workspaces/WorkspaceType";
import type { GlobalAccess } from "./global.access";

/**
 * Used by documentid-server project responses
 */
export type WorkspaceMinimalResponse = {
  documentId: string;
  documentType: WorkspaceTypeName | "DELETED";
  createTime: number;
  lastModified: number;
};

export type ErrorResponse<T> = {
  code: T; // Note: type not used yet here, but this is how its defined in the service as well
  message: string;
};

export function isErrorResponse(obj: any): obj is ErrorResponse<string> {
  if (!obj) return false;
  return typeof obj.message === "string" && typeof obj.code === "string";
}
