import React, { CSSProperties, useCallback, useState } from "react";
import { Icon } from "@hoylu/client-common";
import Styles from "./IconsShared.module.css";
import { TextCopier } from "../../../../utils/text.copier";

export type CopyIconProps = {
  text: string;
  icon?: string;
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
};

export const CopyIcon: React.FC<CopyIconProps> = ({
  text,
  icon = "hoylu-ui-icons-copy",
  style,
  hoverStyle,
}) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const copy = useCallback(() => {
    const copier = new TextCopier();
    copier.copy(text);
  }, [text]);

  const flashCheckMarkBriefly = (): void => {
    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 1000);
  };
  const copyLinkAndFlashTheMark = (): void => {
    copy();
    flashCheckMarkBriefly();
  };

  return (
    <div className={Styles.rightSpacing}>
      {copyClicked ? (
        <Icon
          icon={"hoylu-ui-icons-check"}
          style={style}
          hoverStyle={hoverStyle}
        />
      ) : (
        <Icon
          icon={icon}
          style={style}
          hoverStyle={hoverStyle}
          onClick={copyLinkAndFlashTheMark}
        />
      )}
    </div>
  );
};
