import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import {
  Button,
  RadioWithLabel,
  Icon,
  DraggableWindowHeader,
} from "@hoylu/client-common";
import * as workspaceActions from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import Styles from "./RemoveWorkspaceDialog.module.css";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  activateDashboardOption: workspaceActions.activateDashboardOption,
};

type RemoveWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

enum ActionType {
  HIDE = "HIDE",
  LEAVE = "LEAVE",
  DELETE = "DELETE",
}

export const RemoveWorkspaceDialog: React.FC<RemoveWorkspaceDialogProps> = ({
  activateDashboardOption,
  selectedWorkspaceDetails,
}) => {
  const strings = Localized.object("INFO_SECTION_REMOVE_WORKSPACE_DIALOG");
  const canLeaveWorkspace = !!selectedWorkspaceDetails?.canLeave;
  const canHideWorkspace =
    !!selectedWorkspaceDetails?.isFromMyWorkspaces &&
    !selectedWorkspaceDetails.isHidden;

  const selectInitialOption = () => {
    if (canHideWorkspace) return ActionType.HIDE;
    if (canLeaveWorkspace) return ActionType.LEAVE;
    return ActionType.DELETE;
  };
  const [actionType, setActionType] = useState<ActionType>(selectInitialOption);
  const isDeleteAction = actionType === ActionType.DELETE;
  const iconStyles = {
    padding: "0",
    fontSize: "var(--global--size-h1)",
    color: isDeleteAction
      ? "var(--global--text--Color)"
      : "var(--global--dark-primary--Color)",
    marginRight: "var(--global--spacing--medium)",
  };
  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const onClose = () => {
    activateDashboardOption({
      optionType: DashboardOption.INFO,
      workspaceId: selectedWorkspaceDetails?.workspaceId,
    });
  };

  const onSubmit = () => {
    switch (actionType) {
      case ActionType.HIDE:
        activateDashboardOption({
          optionType: DashboardOption.HIDE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        });
        break;
      case ActionType.LEAVE:
        activateDashboardOption({
          optionType: DashboardOption.LEAVE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        });
        break;
      case ActionType.DELETE:
        activateDashboardOption({
          optionType: DashboardOption.DELETE,
          workspaceId: selectedWorkspaceDetails?.workspaceId,
        });
        break;
      default:
        return;
    }
    return;
  };

  const renderInputs = () => {
    const inputs = [];

    for (let action in ActionType) {
      /*
        Skip "hide" option for hidden workspaces.
        Skip "leave" option for the last admin.
        Skip "delete" option for non admin.
       */
      if (
        (action === ActionType.HIDE && !canHideWorkspace) ||
        (action === ActionType.LEAVE && !canLeaveWorkspace) ||
        (action === ActionType.DELETE && !selectedWorkspaceDetails?.isAdmin)
      ) {
        continue;
      }

      const identity = `${action.toLowerCase()}-workspace`;

      let input = (
        <RadioWithLabel
          key={identity}
          id={identity}
          label={strings[`${action}_WORKSPACE`]}
          value={identity}
          checked={actionType === action}
          handleChange={() =>
            setActionType(ActionType[action as keyof typeof ActionType])
          }
        />
      );

      inputs.push(input);
    }

    return inputs;
  };

  const mapButtonIcon = useMemo(() => {
    switch (actionType) {
      case ActionType.HIDE:
        return "hoylu-ui-icons-indicator-hide";
      case ActionType.LEAVE:
        return "hoylu-ui-icons-leave";
      case ActionType.DELETE:
        return "hoylu-ui-icons-trash";
      default:
        return "";
    }
  }, [actionType]);

  return (
    <Modal
      handleOutsideClick={onClose}
      allowContentToOverflow={true}
      classNames={[Styles.front]}
    >
      <div
        className={`${Styles.front} ${Styles.removeWorkspaceDialog}`}
        data-test-id="remove-ws-info-section"
      >
        <DraggableWindowHeader title={strings.TITLE} onClose={onClose} />
        <div className={Styles.inputs}>{renderInputs()}</div>
        <div className={Styles.buttons}>
          <Button
            onClick={onClose}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={isDeleteAction ? "attention" : "primary"}
            onClick={onSubmit}
            dataTestId={strings[actionType]}
            additionalStyles={buttonStyles}
          >
            <Icon
              icon={mapButtonIcon}
              style={iconStyles}
              hoverStyle={{ backgroundColor: "none", color: "unset" }}
            />
            {strings[actionType]}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveWorkspaceDialog);
