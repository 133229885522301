import { Middleware } from "redux";
import { log } from "@hoylu/client-common";
import { debug } from "debug";
import { isActionOf, RootAction } from "typesafe-actions";
import {
  removeWorkspacePassword,
  setWorkspacePassword,
} from "./workspaces/workspaces.actions";
const localDebug = debug("redux-action");

const defaultLogFunction = (data: RootAction) => {
  // some of the action payloads can be quite large, and we haven't proven they're actually useful
  // so until such a time as we know we need the payload detail for some things just log the action type
  // and omit the payload.
  log.event("redux action", {
    "event.kind": "event",
    data: { type: data.type },
  });
};

export function createActionTelemetryMiddleware(
  logFun: typeof defaultLogFunction = defaultLogFunction
): Middleware {
  return (store) => (next) => (action) => {
    localDebug(action?.type);
    logFun(redactPasswords(action));
    return next(action);
  };
}

function redactPasswords(action: any) {
  // setWorkspacePassword
  if (isActionOf(setWorkspacePassword.request, action)) {
    return setWorkspacePassword.request({
      workspaceId: action.payload.workspaceId,
      currPassword: "*",
      newPassword: "*",
    });
  } else if (isActionOf(setWorkspacePassword.success, action)) {
    return setWorkspacePassword.success({
      workspaceId: action.payload.workspaceId,
      currPassword: "*",
      newPassword: "*",
    });
  } else if (isActionOf(setWorkspacePassword.failure, action)) {
    return setWorkspacePassword.failure({
      ...action.payload,
      requestAction: {
        ...action.payload.requestAction,
        payload: {
          ...action.payload.requestAction.payload,
          currPassword: "*",
          newPassword: "*",
        },
      },
    });
  }
  // removeWorkspacePassword
  else if (isActionOf(removeWorkspacePassword.request, action)) {
    return removeWorkspacePassword.request({
      workspaceId: action.payload.workspaceId,
      currPassword: "*",
    });
  } else if (isActionOf(removeWorkspacePassword.success, action)) {
    return removeWorkspacePassword.success({
      workspaceId: action.payload.workspaceId,
      currPassword: "*",
    });
  } else if (isActionOf(removeWorkspacePassword.failure, action)) {
    return removeWorkspacePassword.failure({
      ...action.payload,
      requestAction: {
        ...action.payload.requestAction,
        payload: { ...action.payload.requestAction.payload, currPassword: "*" },
      },
    });
  }
  return action;
}
