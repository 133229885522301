import { Button } from "@hoylu/client-common";
import React, { useState } from "react";
import { Localized } from "../../../strings";
import { PasswordInput } from "./PasswordInput";
import Styles from "./WorkspacePassword.module.css";

export const MODE = {
  DEFAULT: "DEFAULT",
  ADD: "ADD",
  REMOVE: "REMOVE",
  CHANGE: "CHANGE",
};

export const WorkspacePassword: React.FC<WorkspacePasswordProps> = ({
  hasPassword,
  confirmPasswordAction,
  requiresPassword,
}) => {
  const strings = Localized.object("WORKSPACE_PASSWORD");
  const [mode, setMode] = useState(MODE.DEFAULT);
  const [isAddingPassword, toggleAddPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");

  function handleChooseMode(newMode: string) {
    switch (newMode) {
      case MODE.ADD:
        toggleAddPassword(true);
        setMode(MODE.ADD);
        break;
      case MODE.REMOVE:
        toggleAddPassword(true);
        setMode(MODE.REMOVE);
        break;
      case MODE.CHANGE:
        toggleAddPassword(true);
        setMode(MODE.CHANGE);
        break;
      default:
        setMode(MODE.DEFAULT);
    }
  }

  const passwordValidation = new RegExp(
    "^([a-zA-Za-zA-Z@#$%^&-+=()\\d]).{7,32}"
  );

  let allowAddPassword: boolean = hasPassword
    ? !requiresPassword || passwordValidation.test(newPassword)
    : passwordValidation.test(newPassword) && newPassword === confirmPassword;

  let allowChangePassword: boolean =
    allowAddPassword &&
    newPassword.length !== 0 &&
    confirmPassword.length !== 0 &&
    newPassword === confirmPassword;

  const triggerPasswordAction = (mode: string): void => {
    confirmPasswordAction(newPassword, currentPassword, mode);
    wipeOutData();
  };

  const wipeOutData = (): void => {
    toggleAddPassword(false);
    setNewPassword("");
    setCurrentPassword("");
    updateConfirmPassword("");
  };

  const setHeaderText = (mode: string): string => {
    switch (mode) {
      case MODE.ADD:
        return strings.ADD_WORKSPACE_PASSWORD;
      case MODE.REMOVE:
        return strings.REMOVE_WORKSPACE_PASSWORD;
      case MODE.CHANGE:
        return strings.CHANGE_WORKSPACE_PASSWORD;
      default:
        return "";
    }
  };

  return (
    <>
      <div className={!isAddingPassword ? Styles.hidden : ""}>
        <div className={Styles.addPassword}>{setHeaderText(mode)}</div>
        {mode !== MODE.REMOVE && (
          <div className={Styles.minCharacters}>{strings.CHARACTERS}</div>
        )}
        {mode === MODE.REMOVE && (
          <PasswordInput
            password={newPassword}
            passwordOnChange={setNewPassword}
            placeholder={strings.ENTER_CURRENT_PASSWORD}
          />
        )}
        {mode === MODE.ADD && (
          <React.Fragment>
            <PasswordInput
              password={newPassword}
              passwordOnChange={setNewPassword}
              placeholder={strings.SET_NEW_PASSWORD}
            />
            <PasswordInput
              password={confirmPassword}
              passwordOnChange={updateConfirmPassword}
              placeholder={strings.CONFIRM_NEW_PASSWORD}
            />
          </React.Fragment>
        )}
        {mode === MODE.CHANGE && (
          <React.Fragment>
            <PasswordInput
              password={currentPassword}
              passwordOnChange={setCurrentPassword}
              placeholder={strings.ENTER_CURRENT_PASSWORD}
            />
            <PasswordInput
              password={newPassword}
              passwordOnChange={setNewPassword}
              placeholder={strings.SET_NEW_PASSWORD}
            />
            <PasswordInput
              password={confirmPassword}
              passwordOnChange={updateConfirmPassword}
              placeholder={strings.CONFIRM_NEW_PASSWORD}
            />
          </React.Fragment>
        )}
        <div className={Styles.setPasswordFooter}>
          <Button
            type="negative"
            toolTip={strings.CANCEL}
            onClick={() => wipeOutData()}
          >
            {strings.CANCEL}
          </Button>
          {(mode === MODE.ADD || mode === MODE.REMOVE) && (
            <Button
              type="primary"
              toolTip={
                hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD
              }
              onClick={() => triggerPasswordAction(mode)}
              disabled={!allowAddPassword}
            >
              {hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD}
            </Button>
          )}
          {mode === MODE.CHANGE && (
            <Button
              type="primary"
              toolTip={strings.CHANGE_PASSWORD}
              onClick={() => triggerPasswordAction(mode)}
              disabled={!allowChangePassword}
            >
              {strings.CHANGE_PASSWORD}
            </Button>
          )}
        </div>
      </div>
      <div
        className={`${Styles.changeSetPassword} ${
          isAddingPassword ? Styles.hidden : ""
        }`}
      >
        <span className={Styles.passwordInformation}>
          {hasPassword
            ? strings.WORKSPACE_PASSWORD_SET
            : strings.WORKSPACE_NO_PASSWORD}
        </span>
        {hasPassword && (
          <Button
            type="primary"
            toolTip={strings.CHANGE}
            onClick={() => handleChooseMode(MODE.CHANGE)}
          >
            {strings.CHANGE}
          </Button>
        )}

        <Button
          type={"primary"}
          disabled={hasPassword && requiresPassword}
          toolTip={hasPassword ? strings.REMOVE_PASSWORD : strings.ADD_PASSWORD}
          onClick={() =>
            hasPassword
              ? handleChooseMode(MODE.REMOVE)
              : handleChooseMode(MODE.ADD)
          }
        >
          {hasPassword ? strings.REMOVE : strings.ADD_PASSWORD}
        </Button>
      </div>
    </>
  );
};

type WorkspacePasswordProps = {
  hasPassword: boolean;
  confirmPasswordAction: (
    password: string,
    currentPassword: string,
    mode: string
  ) => void;
  requiresPassword: boolean;
};
