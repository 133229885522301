import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { ShareSection } from "./ShareSection";
import { ShareSectionHeader } from "./ShareSectionHeader";
import {
  Button,
  OpenDropdownRef,
  TextInput,
  useValidatedState,
} from "@hoylu/client-common";
import SimpleWorkspaceCard from "../../cards/SimpleWorkspaceCard";
import { useDispatch, useSelector } from "react-redux";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import { cancelDashboardOption } from "../../../state/workspaces/workspaces.actions";
import { Localized } from "../../../strings";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import { RootState } from "typesafe-actions";
import { InfoSection } from "./ShareType";
import { editThumbnail } from "../../../state/workspaces/workspaces.actions";
import { getUserOrgId } from "../../../state/user/user.selector";
import Styles from "./WorkspaceInfoSection.module.css";
import { isRequiredValidation } from "../../../utils/is.required.validation";
import { isWithinCharLimitValidation } from "../../../utils/is.within.char.limit.validation";
import { RemoveButton } from "../modalElements/RemoveButton";
import { WorkspaceDetails } from "../../../state/workspaces/types";
import { useAssignWorkspaceToProject } from "../../project/use.assign.workspace.to.project";
import { ProjectDropdownWithRemoveButton } from "../../project/ProjectDropdownWithRemoveButton";

export type WorkspaceInfoSectionProps = {
  initiallyOpen: boolean;
  isOrgAdmin: boolean;
  handleChangeSectionType: (section: InfoSection) => void;
  workspaceDetails: WorkspaceDetails;
  openDropdownRef: React.Ref<OpenDropdownRef>;
};

export const WorkspaceInfoSection = ({
  initiallyOpen,
  isOrgAdmin,
  handleChangeSectionType,
  workspaceDetails,
  openDropdownRef,
}: WorkspaceInfoSectionProps) => {
  const dispatch = useDispatch();
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");

  const {
    currentProjects,
    setCurrentProjects,
    projectOptions,
    saveProject,
    projectHasChanged,
  } = useAssignWorkspaceToProject(workspaceDetails);

  const isWorkspaceAdmin = !!workspaceDetails.isAdmin;

  const userOrgId = useSelector(getUserOrgId);

  const showTemplateCreationUIFlag = useSelector(
    (state: RootState) =>
      state.context.config.featureFlags.showTemplateCreationUI
  );
  const showOrgTemplateCreationUIFlag = useSelector(
    (state: RootState) => state.context.config.featureFlags.orgTemplates
  );
  const showPublishAsTemplate =
    showTemplateCreationUIFlag || (isOrgAdmin && showOrgTemplateCreationUIFlag);

  const isWorkspaceOwnedByUserOrg = workspaceDetails.orgId === userOrgId;

  const [isOpenSection, setIsOpenSection] = useState(initiallyOpen);
  const [workspaceName, setWorkspaceName, nameError] = useValidatedState<
    string | undefined
  >(workspaceDetails.workspaceName, [
    isRequiredValidation,
    isWithinCharLimitValidation,
  ]);

  const nameHasChanged = useMemo(
    () => workspaceDetails.workspaceName !== workspaceName,
    [workspaceDetails.workspaceName, workspaceName]
  );

  const canSave = useMemo(() => {
    return (nameHasChanged || projectHasChanged) && !nameError;
  }, [nameHasChanged, projectHasChanged, nameError]);

  const saveWorkspaceName = () => {
    if (!workspaceName || nameError || !nameHasChanged) return;

    dispatch(
      workspaceActions.updateWorkspaceName.request({
        workspaceId: workspaceDetails.workspaceId,
        name: workspaceName,
      })
    );
  };

  const showRemoveWorkspacePrompt = () =>
    dispatch(
      workspaceActions.activateDashboardOption({
        optionType: DashboardOption.REMOVE,
        workspaceId: workspaceDetails.workspaceId,
      })
    );

  const toggleOnHeaderClick = () => {
    setIsOpenSection((prevState) => !prevState);
  };

  const close = () => dispatch(cancelDashboardOption());

  return (
    <ShareSection testId="workspace-info">
      <ShareSectionHeader
        sectionIcon={"hoylu-ui-icons-blank"}
        sectionTitle={strings.WORKSPACE_INFO}
        isSectionVisible={isOpenSection}
        onHeaderClick={toggleOnHeaderClick}
        alwaysShowIcons={true}
      />
      {isOpenSection && !!workspaceDetails && (
        <div className={Styles.workspaceInfoSection}>
          <div className={Styles.workspaceInfoEditContainer}>
            <SimpleWorkspaceCard workspaceDetails={workspaceDetails}>
              {isWorkspaceAdmin ? (
                <div
                  className={Styles.workspaceCard}
                  onClick={() => dispatch(editThumbnail({ flag: true }))}
                >
                  <span className={"hoylu-ui-icons-edit"} />
                  <span>{strings.CHANGE_THUMBNAIL}</span>
                </div>
              ) : (
                <span
                  className={Styles.truncate}
                  title={workspaceDetails.workspaceName}
                >
                  {workspaceDetails.workspaceName}
                </span>
              )}
            </SimpleWorkspaceCard>
            <div className={Styles.infoContainer}>
              <TextInput
                label={strings.WORKSPACE_TITLE}
                value={workspaceName}
                onChange={setWorkspaceName}
                error={nameError}
                style={{ flex: 1 }}
                disabled={!isWorkspaceAdmin}
              />
              <ProjectDropdownWithRemoveButton
                workspaceDetails={workspaceDetails}
                currentProjects={currentProjects}
                projectOptions={projectOptions}
                setCurrentProjects={setCurrentProjects}
                isEditing={true}
                openDropdownRef={openDropdownRef}
              />
            </div>
          </div>
          <div className={Styles.footer}>
            <RemoveButton
              onClick={showRemoveWorkspacePrompt}
              text={strings.REMOVE_WORKSPACE}
            />
            {isWorkspaceAdmin && (
              <div>
                <Button
                  type={"secondary"}
                  toolTip={strings.CANCEL}
                  onClick={close}
                >
                  {strings.CANCEL}
                </Button>
                {showPublishAsTemplate && (
                  <Button
                    type={"primary"}
                    toolTip={
                      !isWorkspaceOwnedByUserOrg
                        ? strings.PUBLISH_AS_TEMPLATE_NOT_ALLOWED
                        : strings.PUBLISH_AS_TEMPLATE
                    }
                    onClick={() =>
                      handleChangeSectionType(InfoSection.TEMPLATE)
                    }
                    disabled={!isWorkspaceOwnedByUserOrg}
                  >
                    {strings.PUBLISH_AS_TEMPLATE}
                  </Button>
                )}
                <Button
                  type={"primary"}
                  toolTip={"Save"}
                  onClick={() => {
                    saveWorkspaceName();
                    saveProject();
                  }}
                  disabled={!canSave}
                >
                  {strings.SAVE}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </ShareSection>
  );
};
