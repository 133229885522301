import React, { useMemo } from "react";
import UserProfileWidget from "../UserProfileWidget";
import HoyluLogo from "../HoyluLogo";
import HeaderButtons from "./HeaderButtons";
import ProjectNavigationDropdown from "../project/ProjectNavigationDropdown";
import { FilterByWorkspaceTag } from "../FilterByWorkspaceTag";
import { FilterByWorkspaceName } from "../FilterByWorkspaceName";
import { WorkspaceLabels } from "../../state/workspaces/types";
import { FlatRippleSpinner } from "../RippleSpinner";
import { connect, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { AppMode } from "../../state/mode/mode.reducer";
import {
  activateDashboardOption,
  cancelDashboardOption,
} from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import {
  getAllProjects,
  getSelectedProjectColor,
} from "../../state/workspaces/projects.selector";
import {
  getAllLabels,
  isNotificationCenterOpen,
} from "../../state/workspaces/workspaces.selector";
import { hexToRgba } from "../../utils/hexToRgba";
import {
  enableInAppProjectCreation,
  selectCanUseProjects,
  showNotificationsUI,
} from "../../state/config/config.selector";
import { ProjectStatuses } from "../../state/workspaces/workspaces.reducer";
import { ProjectNavigationInformation } from "./ProjectNavigationInformation";
import { NotificationIcon } from "@hoylu/notifications";
import { Localized } from "../../strings";
import Styles from "./Header.module.css";

const mapStateToProps = (state: RootState) => ({
  allLabels: getAllLabels(state),
  allProjects: getAllProjects(state),
  isLoggedIn: state.context.user.isLoggedIn === true,
  isLoading:
    state.mode === AppMode.LOADING_DASHBOARD ||
    state.mode === AppMode.LOADING_WORKSPACE ||
    state.context.workspaces.isLoadingSelectedProjectWorkspaces,
  projectThemeColor: getSelectedProjectColor(state),
  selectedProject: state.context.workspaces.selectedProject,
  searchTerm: state.context.workspaces.searchTerm,
  showProjects: selectCanUseProjects(state),
  allowCreatingProject: enableInAppProjectCreation(state),
  showSearch: ![
    AppMode.NOT_VERIFIED_POLICY_RESTRICTED,
    AppMode.BETA_NOT_ALLOWED,
  ].includes(state.mode),
  showNotificationsUI: showNotificationsUI(state),
  isNotificationCenterOpen: isNotificationCenterOpen(state),
});

const mapDispatchToProps = {
  handleSearchClick: () =>
    activateDashboardOption({
      optionType: DashboardOption.OPEN,
    }),
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: {
    selectedLabels: WorkspaceLabels;
    handleLabelsChange: (selected: WorkspaceLabels) => void;
  }
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

type HeaderProps = ReturnType<typeof mergeProps>;

export const Header: React.FC<HeaderProps> = ({
  isLoggedIn,
  showSearch,
  handleSearchClick,
  allProjects,
  selectedProject,
  showProjects,
  selectedLabels,
  allLabels,
  handleLabelsChange,
  searchTerm,
  isLoading,
  projectThemeColor,
  allowCreatingProject,
  showNotificationsUI,
  isNotificationCenterOpen,
}) => {
  const strings = Localized.object("NOTIFICATIONS");
  const dispatch = useDispatch();

  const backgroundColor = useMemo(
    () =>
      projectThemeColor
        ? hexToRgba(projectThemeColor)
        : "var(--gradient-from-color-theme)",
    [projectThemeColor]
  );

  /* Inactive projects last */
  const sortedProjects = useMemo(
    () =>
      allProjects?.sort((a, b) => {
        const statusA = a.status === ProjectStatuses.Inactive ? 1 : 0;
        const statusB = b.status === ProjectStatuses.Inactive ? 1 : 0;

        return statusA - statusB;
      }),
    [allProjects]
  );

  const toggleNotificationCenterVisibility = () => {
    if (isNotificationCenterOpen) {
      dispatch(cancelDashboardOption());
    } else {
      dispatch(
        activateDashboardOption({
          optionType: DashboardOption.NOTIFICATION_CENTER,
          dialogPosition: { top: 130, right: 30 },
        })
      );
    }
  };

  return (
    <div className={Styles.header}>
      <div className={Styles.headerDivider} style={{ backgroundColor }} />
      <div className={Styles.headerContent}>
        <div className={Styles.headerBody}>
          <div className={Styles.logoContainer}>
            <HoyluLogo />
            {isLoggedIn && !selectedProject && (
              <HeaderButtons
                showSearch={showSearch}
                handleSearchClick={handleSearchClick}
              />
            )}
          </div>
          <div className={Styles.projectNavigationContainer}>
            <ProjectNavigationDropdown
              allProjects={sortedProjects ?? []}
              selectedProject={selectedProject}
              showProjects={showProjects}
              allowCreatingProject={allowCreatingProject}
            />
            <ProjectNavigationInformation />
          </div>
          <div className={Styles.filterWorkspaceContainer}>
            <FilterByWorkspaceName searchTerm={searchTerm} />
            <FilterByWorkspaceTag
              selectedLabels={selectedLabels}
              allLabels={allLabels}
              handleLabelsChange={handleLabelsChange}
            />
            {showNotificationsUI && (
              <NotificationIcon
                title={strings.TITLE}
                onClick={toggleNotificationCenterVisibility}
                showValue={false}
                styles={{
                  color: "var(--theme-text)",
                  lineHeight: 1,
                  paddingRight: 0,
                }}
              />
            )}
            <div className={Styles.userProfileWidgetContainer}>
              {isLoggedIn && <UserProfileWidget />}
            </div>
          </div>
        </div>
        {isLoading && <FlatRippleSpinner />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header);
