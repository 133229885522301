import React from "react";
import leftPathIcon from "../../assets/images/left-path.svg";
import rightPathIcon from "../../assets/images/right-path.svg";
import darkLogoOnly from "../../assets/images/logos/logo-only-theme-dark.svg";
import { Localized } from "../../strings";
import Styles from "./CenteredContentWithLogo.module.css";
import AppStyles from "../App.module.css";

export const CenteredContentWithLogo: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const strings = Localized.object("CENTERED_CONTENT_WITH_LOGO");
  return (
    <div className={AppStyles.hideScrollbar}>
      <img
        className={Styles.leftPath}
        src={leftPathIcon}
        alt={strings.LEFT_PATH_ICON}
      />
      <div className={Styles.centered}>
        <div className={Styles.content}>
          <img
            className={Styles.hoyluLogo}
            src={darkLogoOnly}
            alt={strings.HOYLU_LOGO}
          />
          {children}
        </div>
        <img
          className={Styles.rightPath}
          src={rightPathIcon}
          alt={strings.RIGHT_PATH_ICON}
        />
      </div>
    </div>
  );
};
