export const isHexColor = (str: string) => {
  const hexRegex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;

  return hexRegex.test(str);
}

export const hexToRgba = (hex: string, opacity = 1, defaultReturnValue?: string) => {
  if (!isHexColor(hex)) return defaultReturnValue ?? 'transparent';

  let r, g, b, a;

  if (hex.length === 9) {
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
    a = parseInt(hex.substring(7, 9), 16) / 255;
  } else if (hex.length === 7) {
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
    a = opacity;
  } else if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
    a = opacity;
  }

  return `rgba(${r},${g},${b},${a})`;
}
