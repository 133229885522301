import React, { CSSProperties, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { moduleDefaultImage } from "../../utils/defaultImages";
import { Localized } from "../../strings";
import { AssetId, AssetRendererProps } from "@hoylu/client-common";
import debug from "debug";
import Styles from "./WorkspaceTemplateCard.module.css";

const trace = debug("hoylu:dashboard:workspace-template-card");

export type TemplateAsset = {
  templateName: string;
  templateDescription?: string;
  fetchThumbnail?: () => Promise<Response>;
  onCreate?: () => void;
  onPreview?: () => void;
} & AssetId;

export const WorkspaceTemplateCard: React.FC<AssetRendererProps<
  TemplateAsset
>> = ({
  asset: {
    templateName,
    templateDescription,
    fetchThumbnail,
    onCreate,
    onPreview,
  },
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>(
    moduleDefaultImage("") // get the default workspace image because no module info here;
  );

  const truncateStyles: CSSProperties = {
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
  };

  useAsyncEffect(
    async (isStillMounted) => {
      if (!fetchThumbnail) return;

      try {
        // fetching here to nicely handle 404's and avoid situation when default thumbnails are blinking
        const response = await fetchThumbnail();
        if (response.status != 200 || !isStillMounted()) return;
        const blob = await response.blob();
        if (isStillMounted() && blob) setImgSrc(URL.createObjectURL(blob));
      } catch (err) {
        trace("Error fetching thumbnail", { error: err });
      }
    },
    [fetchThumbnail]
  );

  return (
    <div
      className={Styles.workspaceTemplateCard}
      onClick={() => setShowCreate(!showCreate)}
    >
      <div
        className={`${Styles.animate} ${
          !showCreate ? Styles.visible : Styles.invisible
        } ${Styles.thumbnail}`}
        onClick={(e) => {
          if (onPreview) {
            e.stopPropagation();
          }
        }}
      >
        {!!onPreview && (
          <Tile
            icon={"hoylu-ui-icons-indicator-show"}
            label={Localized.string("WORKSPACE_TEMPLATES.PREVIEW")}
            className={`${Styles.preview} ${Styles.animate}`}
            onClick={onPreview}
          />
        )}
        <img
          className={Styles.templateImage}
          src={imgSrc}
          alt={templateName}
          title={templateName}
        />
      </div>

      <div className={Styles.template}>
        <div className={Styles.templateName} title={templateName}>
          {templateName}
        </div>
        <div
          className={Styles.templateDescription}
          style={truncateStyles}
          title={templateDescription}
        >
          {templateDescription}
        </div>
      </div>
      <Tile
        icon={"hoylu-ui-icons-quill"}
        label={Localized.string("WORKSPACE_TEMPLATES.CREATE")}
        className={`${Styles.createWorkspace} ${Styles.animate} ${
          showCreate ? Styles.visible : Styles.invisible
        }`}
        onClick={onCreate}
      />
    </div>
  );
};

type TileProps = {
  icon: string;
  label: string;
  onClick?: () => void;
  className?: string;
};

const Tile = ({ icon, label, onClick, className = "" }: TileProps) => {
  return (
    <div
      className={`${Styles.tile} ${className}`}
      title={label}
      onClick={(e) => {
        onClick?.();
        e.stopPropagation();
      }}
    >
      <div className={`${Styles.tileIcon} ${icon}`} />
      <div className={Styles.tileLabel}>{label}</div>
    </div>
  );
};
