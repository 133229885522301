import { of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { AppEpic } from "../types";

import {
  getLicenseInfo,
  getOrgBanner,
  getOrgBannerConfig,
  getOrgPolicies,
  getToken,
  getUserProfile,
  inviteUsers,
  isOrgBannerConfigResponse,
  LicenseType,
  login as loginAction,
  login,
  logout,
  PoliciesResponse,
  registerEmail,
  requestDelete,
  sendValidationEmail,
  skipValidationEmail,
  userIdUpdated,
  UserInvitePayload,
  UserInviteResponse,
  UserProfileResponse,
  userRegistrationComplete,
} from "../../state/user/user.actions";
import { updateFlags } from "../../state/config/config.actions";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import {
  authorizationHeader,
  getUserOrgId,
} from "../../state/user/user.selector";

export const watchForUserIdChanges: AppEpic = (action$, state$) =>
  state$.pipe(
    map((s) => s.context.user.profile.userId),
    distinctUntilChanged(),
    filter((id) => !!id),
    map((id) => userIdUpdated(id))
  );

export const getOrgPoliciesEpic: AppEpic = (action$, state$, { getJSON }) => {
  return action$.pipe(
    filter(isActionOf([loginAction.success, getToken.success])),
    mergeMap(() => {
      return getJSON<PoliciesResponse>(
        `${
          state$.value.context.config.serviceConfig.auth
        }/api/v1/orgs/${getUserOrgId(state$.value)}/policies/active`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => of(getOrgPolicies.success(response))),
        catchError((error) => of(getOrgPolicies.failure(error)))
      );
    })
  );
};

export const getOrgBannerEpic: AppEpic = (action$, state$, { requestBlob }) => {
  return action$.pipe(
    filter(() => state$.value.context.config.featureFlags.enableOrgBanner),
    filter(isActionOf(updateFlags)),
    mergeMap(() => {
      return requestBlob(
        `${
          state$.value.context.config.serviceConfig.documentImages
        }/api/v2/blob/org/${getUserOrgId(state$.value)}/banner.png`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) =>
          of(getOrgBanner.success({ bannerBlobUrl: response }))
        ),
        catchError((error) => of(getOrgBanner.failure(error)))
      );
    })
  );
};

export const getOrgBannerConfigEpic: AppEpic = (
  action$,
  state$,
  { getJSON }
) => {
  return action$.pipe(
    filter(
      () =>
        state$.value.context.config.featureFlags.enableOrgBanner &&
        !!getUserOrgId(state$.value) &&
        getUserOrgId(state$.value) !== "00000000-0000-0000-0000-000000000000" // do not use for global org
    ),
    filter(isActionOf(updateFlags)),
    mergeMap(() => {
      return getJSON(
        `${
          state$.value.context.config.serviceConfig.documentImages
        }/api/v2/blob/org/${getUserOrgId(state$.value)}/banner.json`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => {
          if (!isOrgBannerConfigResponse(response)) {
            return of(
              getOrgBannerConfig.failure(
                Error("Response is not a OrgBannerConfigResponse object")
              )
            );
          }
          return of(getOrgBannerConfig.success(response));
        }),
        catchError((error) => of(getOrgBannerConfig.failure(error)))
      );
    })
  );
};

export const sendValidationEmailEpic: AppEpic = (
  action$,
  state$,
  { getJSON }
) => {
  return action$.pipe(
    filter(isActionOf([sendValidationEmail.request])),
    mergeMap(() => {
      return getJSON<string>(
        `${state$.value.context.config.serviceConfig.auth}/authenticated_users/${state$.value.context.user.profile.email}/resend_validation`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => of(sendValidationEmail.success(response))),
        catchError((error) => of(sendValidationEmail.failure(error)))
      );
    })
  );
};

export type LicenseInfoResponse = {
  type: LicenseType;
  expiration: string;
  orgOwnerId: string;
  allowedPremiumModules: string[];
  isExpired: boolean;
};

export const getLicenseInfoEpic: AppEpic = (action$, state$, { getJSON }) => {
  return action$.pipe(
    filter(
      isActionOf([loginAction.success, getToken.success, registerEmail.success])
    ),
    mergeMap(() => {
      return getJSON<LicenseInfoResponse>(
        `${state$.value.context.config.serviceConfig.licensing}/api/v2/licenses/my`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => {
          const expiration = new Date(response.expiration);

          const license = { ...response, expiration };
          return of(getLicenseInfo.success(license));
        }),
        catchError((error) => of(getLicenseInfo.failure(error)))
      );
    })
  );
};

export const getUserProfileEpic: AppEpic = (action$, state$, { getJSON }) => {
  return action$.pipe(
    filter(isActionOf([loginAction.success, getToken.success])),
    mergeMap(() => {
      return getJSON<UserProfileResponse>(
        `${state$.value.context.config.serviceConfig.auth}/userProfile/${state$.value.context.user.profile.userId}`,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => of(getUserProfile.success(response))),
        catchError((error) => of(getUserProfile.failure(error)))
      );
    })
  );
};

export const requestDeleteAccountEpic: AppEpic = (
  action$,
  state$,
  { putJSON }
) => {
  return action$.pipe(
    filter(isActionOf([requestDelete.request])),
    mergeMap(() => {
      return putJSON(
        `${state$.value.context.config.serviceConfig.auth}/userProfile/${state$.value.context.user.profile.userId}/requestDelete`,
        undefined,
        authorizationHeader(state$.value),
        true
      ).pipe(
        mergeMap(() => of(logout.request())),
        catchError((error) => {
          console.warn(error);
          return of(cancelDashboardOption());
        })
      );
    })
  );
};

export const skipEmailValidation: AppEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf([skipValidationEmail.request])),
    mergeMap(() => of(skipValidationEmail.success()))
  );
};

export const sendUserRegistrationToHubspotEpic: AppEpic = (
  action$,
  state$,
  { hubspotTryTrackUserRegistration }
) => {
  return action$.pipe(
    filter(isActionOf(userRegistrationComplete)),
    filter(() => state$.value.context.config.featureFlags.enableHubspot),
    tap((a) => hubspotTryTrackUserRegistration(a.payload)),
    ignoreElements()
  );
};

export const loginOnUserRegistrationCompleteEpic: AppEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf(userRegistrationComplete)),
    mergeMap((a) => of(login.success({ token: a.payload })))
  );
};

export const autoUpdateLogUserId: AppEpic = (action$, state$, { log }) =>
  action$.pipe(
    filter(isActionOf(userIdUpdated)),
    tap((a) => log.setLabels({ userId: a.payload })),
    ignoreElements()
  );

export const inviteUsersToHoylu: AppEpic = (action$, state$, { postJSON }) =>
  action$.pipe(
    filter(isActionOf(inviteUsers.request)),
    mergeMap((a) => {
      return postJSON<UserInvitePayload, UserInviteResponse>(
        `${state$.value.context.config.serviceConfig.auth}/invite`,
        a.payload,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => of(inviteUsers.success(response))),
        catchError((error) => {
          console.warn("Unable to invite users:", a.payload, error);
          //For now we don't really care about the error handling here
          return of(inviteUsers.failure(error));
        })
      );
    })
  );
