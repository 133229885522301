import { of } from "rxjs";
import {
  filter,
  mergeMap,
  catchError,
  tap,
  ignoreElements,
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { waitForConfigLoaded, waitForLicense } from "../helpers/waitForState";
import {
  login as loginAction,
  logout as logoutAction,
  HoyluToken,
  getToken,
  registerEmail as registerEmailAction,
  fetchWorkspaceToken,
  getLicenseInfo,
  callWithLoginConfirm,
} from "../../state/user/user.actions";
import { AppEpic } from "../types";
import {decodeToken} from "../../utils/credential.helpers";

export const requireLoginConfirmEpic: AppEpic = (action$, state$, { login }) => {
  return action$.pipe(
    filter(isActionOf(callWithLoginConfirm)),
    mergeMap((action) => {
      return login(state$.value).pipe(
        mergeMap((response) => {
          const userPayload = decodeToken(response);
          if (userPayload.userId != state$.value.context.user.profile.userId){
            return of(action.payload.reject)
          }
          return of(action.payload.accept)
        }),
        catchError(() => of(action.payload.reject))
      )
    }))
}

export const loginEpic: AppEpic = (action$, state$, { login }) => {
  return action$.pipe(
    filter(isActionOf(loginAction.request)),
    waitForConfigLoaded(state$),
    mergeMap(() => {
      return login(state$.value).pipe(
        mergeMap((response) =>
          of(loginAction.success({ token: response as HoyluToken }))
        ),
        catchError((error) => of(loginAction.failure(error)))
      );
    })
  );
};

export const logoutEpic: AppEpic = (action$, state$, { logout }) => {
  return action$.pipe(
    filter(isActionOf(logoutAction.request)),
    waitForConfigLoaded(state$),
    mergeMap(() => {
      return logout(
        state$.value.context.config.serviceConfig.login,
      ).pipe(
        mergeMap(() => of(logoutAction.success())),
        catchError((error) => of(logoutAction.failure(error)))
      );
    })
  );
};

export const getTokenSilentEpic: AppEpic = (
  action$,
  state$,
  { getTokenSilent, sendMessageToAllWorkspaces }
) => {
  return action$.pipe(
    filter(
      isActionOf([
        getToken.request,
        fetchWorkspaceToken.request,
      ])
    ),
    waitForConfigLoaded(state$),
    mergeMap(() => {
      return getTokenSilent(
        state$.value.context.config.serviceConfig.login,
        state$.value.context.user
      ).pipe(
        mergeMap((response) => {
          sendMessageToAllWorkspaces({
            action: "TOKEN_PAYLOAD",
            token: response,
          });
          return of(
            getToken.success({ token: response as HoyluToken })
          );
        }),
        catchError((error) => of(getToken.failure(error)))
      );
    })
  );
};

export const registerEmailEpic: AppEpic = (
  action$,
  state$,
  { registerEmail }
) => {
  return action$.pipe(
    filter(isActionOf(registerEmailAction.request)),
    waitForConfigLoaded(state$),
    mergeMap((a) => {
      return registerEmail(state$.value, a.payload).pipe(
        mergeMap((response) =>
          of(registerEmailAction.success({ token: response as HoyluToken }))
        ),
        catchError((error) => of(registerEmailAction.failure(error)))
      );
    })
  );
};

export const sendLicenseInfoToWorkspace: AppEpic = (
  action$,
  state$,
  { sendMessageToAllWorkspaces }
) => {
  return action$.pipe(
    filter(isActionOf(getLicenseInfo.request)),
    waitForLicense(state$),
    tap(() => {
      sendMessageToAllWorkspaces({
        action: "LICENSE_PAYLOAD",
        license: state$.value.context.user.licenseInfo,
      });
    }),
    ignoreElements()
  );
};
