import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  populateAllWorkspaces,
  updateWorkspaceLabels,
} from "../../state/workspaces/workspaces.actions";
import {
  getAllLabels,
  getSelectedWorkspaceDetails,
} from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { WorkspaceLabels } from "../../state/workspaces/types";
import { EditLabelContent } from "./EditLabelContent";
import Styles from "./EditLabelDialog.module.css";
import { DraggableWindowHeader } from "@hoylu/client-common";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
  allLabels: getAllLabels(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  updateLabels: updateWorkspaceLabels.request,
};

type DuplicateProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const EditLabel: React.FC<DuplicateProps> = ({
  cancelDashboardOption,
  allLabels,
  selectedWorkspaceDetails,
  updateLabels,
}) => {
  const strings = Localized.object("EDIT_LABEL_DIALOG");

  // on component load, check to see if all ws have been loaded, if not, make call to populate all to ensure we have all workspace labels
  const isLoadingAll = useSelector(
    (state: RootState) => state.context.workspaces.isLoadingAll
  );
  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasPopulatedAllWorkspaces) {
      dispatch(populateAllWorkspaces.request());
    }
  });

  const requestUpdateLabels = (labels: WorkspaceLabels) => {
    updateLabels({
      workspaceId: selectedWorkspaceDetails!.workspaceId,
      labels,
    });
  };

  return (
    <Modal handleOutsideClick={cancelDashboardOption}>
      <div className={Styles.editLabelDialog}>
        <DraggableWindowHeader
          title={strings.LABELS}
          onClose={cancelDashboardOption}
        />
        {!isLoadingAll ? (
          <EditLabelContent
            allLabels={allLabels}
            selected={selectedWorkspaceDetails?.labels}
            updateSelected={requestUpdateLabels}
          />
        ) : (
          <h1 className={Styles.loadingHeader}>{strings.LOADING_WORKSPACES}</h1>
        )}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLabel);
