import React, { useEffect, useRef, useState } from "react";

export default function useAutoVisibility(isVisible: boolean) {
  const [componentVisible, setComponentVisible] = useState(isVisible);
  const ref = useRef<HTMLDivElement>(null);
  const outsideElementRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref !== null &&
      ref.current !== null &&
      !ref.current.contains(event.target as Node) &&
      (outsideElementRef === null ||
        outsideElementRef.current === null ||
        !outsideElementRef.current.contains(event.target as Node))
    ) {
      // click-outside should only hide the drop-down if it is really visible - on mobile this component is not shown, but instead a drawer is used. In this case, click-outside is not working correctly.
      const computedDisplay = window
        .getComputedStyle(ref.current)
        .getPropertyValue("display");
      if (computedDisplay !== "none") {
        setComponentVisible(false);
      }
    }
  };

  useEffect(() => {
    if (ref === null || ref.current === null || !componentVisible) {
      return;
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentVisible]);

  return { ref, componentVisible, setComponentVisible, outsideElementRef };
}