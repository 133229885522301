import { RootAction } from "typesafe-actions";
import { Store } from "redux";
import {
  showDashboard,
  workspaceChannelReady,
  workspaceDocumentReady,
  activateDashboardOption,
  workspaceShowingPrompt,
  workspaceProgressUpdate,
  workspaceSnapshot,
  getMyTonnage,
  queueProjectLoading,
  cancelDashboardOption,
} from "./state/workspaces/workspaces.actions";
import {
  fetchWorkspaceToken,
  getLicenseInfo,
  logout,
} from "./state/user/user.actions";
import { setFocusAction } from "./state/workspaces/workspaces.actions";
import { DashboardOption } from "./state/workspaces/DashboardOption";

const channels: {
  [key: string]: Window;
} = {};

function updateChannels(event: MessageEvent) {
  if (event.data.workspaceId) {
    channels[event.data.workspaceId] = event.source as Window;
  }
}

export function sendMessageToWorkspace(
  workspaceId: string,
  data: any
): boolean {
  if (channels[workspaceId]) {
    channels[workspaceId].postMessage(data, window.origin);
  }
  return false;
}

export function sendMessageToAllWorkspaces(data: any): boolean {
  Object.values(channels).forEach((channel) => {
    channel.postMessage(data, window.origin);
  });
  return false;
}

export function connectPostMessageToDispatch(store: Store) {
  function handlePostMessage(event: MessageEvent) {
    if (event.origin !== window.origin) return;
    updateChannels(event);
    const actions = messageToActions(event.data);
    actions.forEach((a) => store.dispatch(a));
  }

  window.addEventListener("message", handlePostMessage);
}

export function messageToActions(data: any): RootAction[] {
  switch (data.message || data) {
    case "SHOW_DASHBOARD":
      return [showDashboard(), cancelDashboardOption(), getMyTonnage.request()];
    case "WORKSPACE_SHOWING_PROMPT":
      return [workspaceShowingPrompt()];
    case "CHANNEL_READY":
      return [workspaceChannelReady(data.workspaceId), setFocusAction()];
    case "DOCUMENT_READY":
      return [workspaceDocumentReady(data.workspaceId)];
    case "REQUEST_TOKEN":
      return [fetchWorkspaceToken.request()];
    case "REQUEST_LICENSE":
      return [getLicenseInfo.request()];
    case "CHANGE_LANGUAGE":
      window.location.reload();
      return [];
    case "SHOW_SHARE_MODAL":
      return [
        activateDashboardOption({
          optionType: DashboardOption.SHARE,
          workspaceId: data.workspaceId,
        }),
      ];
    case "SHOW_PERMISSION_MODAL":
      return [
        activateDashboardOption({
          optionType: DashboardOption.PERMISSIONS,
          workspaceId: data.workspaceId,
          emailPreset: data.emailPreset,
          showOverlay: data.showOverlay,
        }),
      ];
    case "SHOW_WORKSPACE_INFO_MODAL":
      return [
        activateDashboardOption({
          optionType: DashboardOption.INFO,
          workspaceId: data.workspaceId,
          emailPreset: data.emailPreset,
        }),
      ];
    case "SHOW_NOTIFICATION_CENTER_MODAL":
      return [
        activateDashboardOption({
          optionType: DashboardOption.NOTIFICATION_CENTER,
          workspaceId: data.workspaceId,
          dialogPosition: data.dialogPosition,
        }),
      ];
    case "PROGRESS_UPDATE":
      return [workspaceProgressUpdate(data)];
    case "UPLOAD_SNAPSHOT":
      const { workspaceId, snapshot } = data;
      if (!workspaceId || !snapshot) return [];
      return [workspaceSnapshot.success({ workspaceId, snapshot })];
    case "SHOW_PROJECT":
      return [
        showDashboard(),
        getMyTonnage.request(),
        queueProjectLoading(data.projectId),
      ];
    case "LOGOUT":
      return [logout.request()];
    default:
      return [];
  }
}
