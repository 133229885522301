import {
  MyWorkspacePermissions,
  Label,
  WorkspaceDetails,
} from "../../../state/workspaces/types";
import { WorkspaceTypeName} from "../../../state/workspaces/WorkspaceType";
import { metadataV3toWorkspaceDetails } from "./to.workspace.details";
import { GlobalAccess } from "../global.access";

export type WorkspaceModule = {
  name: string;
  configuration?: Object
}

export type WorkspacePageSize = {
  width: number;
  height: number;
}

export type CreateWorkspaceDetails = {
  containerId?: string;
  globalAccess: GlobalAccess;
  module: WorkspaceModule;
  password?: string;
  workspaceName?: string;
}

export type CreateWorkspaceRequestMetadata = CreateWorkspaceDetails & {
  workspaceName: string;
  workspaceType: WorkspaceTypeName;
  pageSize?: WorkspacePageSize;
  schemaVersion: number;
  labels?: Label[];
}

export type DuplicateWorkspaceDetails = {
  workspaceId: string;
  containerId?: string;
  workspaceName: string;
  globalAccess: GlobalAccess;
  password?: string;
  newPassword?: string;
  open?: boolean;
}

export type DuplicateWorkspaceRequestMetadata = {
  workspaceName: string,
  containerId?: string,
  globalAccess: GlobalAccess,
  password: string | null
}

export interface MyWorkspaceMetadataV3 {
  containerId: string | null;
  createTime: string; // ISO 8601
  hasPassword: boolean;
  id: number;
  isHidden: boolean | null;
  labels: Label[] | null;
  lastAccess: string | null; // ISO 8601
  lastModified: string; // ISO 8601
  module: {
    name: string;
    configuration: Object | null;
  };
  myPermissions: MyWorkspacePermissions;
  orgId: string;
  schemaVersion: number;
  templateId: string | null;
  weight: number | null;
  workspaceId: string;
  workspaceName: string | null;
  workspaceType: WorkspaceTypeName | "DELETED";
}

export enum UpsertWorkspaceUsersPermissions {
  Administer = "Administer",
  Write = "Write",
  Read = "Read",
  None = "None",
}

export function isMyWorkspaceMetadataV3(
  obj: any
): obj is MyWorkspaceMetadataV3 {
  const ret =
    obj &&
    obj.containerId !== undefined &&
    obj.createTime &&
    obj.hasPassword !== undefined &&
    obj.id &&
    obj.isHidden !== undefined &&
    obj.labels !== undefined &&
    obj.lastAccess !== undefined &&
    obj.lastModified &&
    obj.module &&
    obj.module.name !== undefined &&
    obj.myPermissions &&
    obj.orgId &&
    obj.schemaVersion &&
    obj.templateId !== undefined &&
    obj.weight !== undefined &&
    obj.workspaceId &&
    obj.workspaceName !== undefined &&
    obj.workspaceType;
  if (!ret) {
    console.warn("Invalid workspace object", obj);
  }
  return ret;
}

export interface MyWorkspacesV3Response {
  workspaces: MyWorkspaceMetadataV3[] | null;
  offset: number | null;
}

export function isMyWorkspaceV3Response(
  obj: any
): obj is MyWorkspacesV3Response {
  return obj && obj.workspaces && Array.isArray(obj.workspaces);
  // note: is not checking deeply for MyWorkspaceMetadataV3 on purpose to allow those to be fixed up
}

export function assertMyWorkspaceV3Response(
  obj: any
): asserts obj is MyWorkspacesV3Response {
  if (!isMyWorkspaceV3Response(obj)) {
    throw new Error("Invalid response");
  }
}

export function stripInvalidWorkspaces(
  response: MyWorkspacesV3Response
): MyWorkspacesV3Response {
  return {
    ...response,
    workspaces: response.workspaces?.filter(isMyWorkspaceMetadataV3) ?? null,
  };
}

export const metadataV3toWorkspaceDetailsArray = (
  rawDetails: MyWorkspaceMetadataV3[]
): WorkspaceDetails[] => {
  return rawDetails.map((m) => metadataV3toWorkspaceDetails(m));
};
