import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { ConfirmationDialog } from "./ConfirmationDialog";
import {
  cancelDashboardOption,
  leaveWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
  leaveWorkspace: leaveWorkspace.request,
};

type LeaveWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const LeaveWorkspaceDialog: React.FC<LeaveWorkspaceDialogProps> = ({
  cancelDashboardOption,
  leaveWorkspace,
  selectedWorkspaceDetails,
}) => {
  const strings = Localized.object("LEAVE_WORKSPACE_DIALOG");

  return (
    <ConfirmationDialog
      icon={"hoylu-ui-icons-x"}
      title={`${strings.LEAVE} "${selectedWorkspaceDetails!.workspaceName}"`}
      message={strings.WARNING}
      buttonText={strings.LEAVE}
      submitAction={() => leaveWorkspace(selectedWorkspaceDetails!)}
      cancelAction={cancelDashboardOption}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveWorkspaceDialog);
