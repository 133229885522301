import { user } from "../../state/user/user.selector";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import React, { useCallback, useState } from "react";
import { Localized } from "../../strings";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { requestDelete } from "../../state/user/user.actions";
import { Button, Checkbox, DraggableWindowHeader } from "@hoylu/client-common";
import Styles from "./ConfirmAccountDelete.module.css";

export const ConfirmAccountDelete = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const [confirm, setConfirm] = useState(false);
  const strings = Localized.object("CONFIRM_ACCOUNT_DELETE");
  const close = useCallback(() => dispatch(cancelDashboardOption()), [
    dispatch,
  ]);
  return (
    <Modal handleOutsideClick={close}>
      <DraggableWindowHeader
        title={strings.TITLE + currentUser.profile.email}
        onClose={close}
      />
      <div className={Styles.confirmAccountDelete}>
        <span>{strings.P1}</span>
        <ul className={Styles.stepList}>
          <li className={Styles.listItem}>{strings.POINT1}</li>
          <li className={Styles.listItem}>{strings.POINT2}</li>
        </ul>
        <span className={Styles.confirmationEmailInfo}>{strings.P2}</span>
      </div>
      <div className={Styles.footer}>
        <div className={Styles.confirm}>
          <Checkbox checked={confirm} onChange={setConfirm}>
            {strings.CONFIRM}
          </Checkbox>
        </div>
        <div className={Styles.buttons}>
          <Button type={"primary"} toolTip={strings.CANCEL} onClick={close}>
            {strings.CANCEL}
          </Button>
          <Button
            type={"attention"}
            toolTip={strings.DELETE}
            onClick={() => dispatch(requestDelete.request())}
            disabled={!confirm}
          >
            {strings.DELETE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
