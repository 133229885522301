import { ajax } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { WorkspaceDetails } from "../../../state/workspaces/types";
import { PopulateWorkspacesResult } from "../../../state/workspaces/workspaces.actions";
import {
  DocumentMetadataV1Request,
  DocumentMetadataV1Response,
  LegacyMyWorkspacesV1Response,
  metadataV1toWorkspaceDetailsArray,
} from "./workspaces.v1.types";
import { putJSON, requestText } from "../../dependencies/ajaxRequests";
import {
  metadataV3toWorkspaceDetails,
  toWorkspaceDetails,
} from "./to.workspace.details";
import { encode } from "../../../utils/credential.helpers";
import { documentIdv3Requests } from "./documentIdv3.requests";

/**
 * Gets all workspaces for the given user (legacy endpoint, not paged).
 * @returns An array of workspaces (already converted to WorkspaceDetails) and a cursor for the next page (always null).
 */
function getAllMyWorkspaces(
  origin: string,
  token: string
): Observable<PopulateWorkspacesResult> {
  const url = `${origin}/api/v1/workspaces`;
  return ajax
    .getJSON<LegacyMyWorkspacesV1Response>(url, {
      Authorization: `Bearer ${token}`,
    })
    .pipe(
      mergeMap((response) => {
        return of({
          details: metadataV1toWorkspaceDetailsArray(response),
          cursorOrOffset: null,
        });
      })
    );
}

function updateWorkspaceName(
  origin: string,
  token: string,
  workspaceId: string,
  name: string
): Observable<WorkspaceDetails> {
  // Since v1 requires that we send an entire workspace metadata object, we need to get the current metadata first
  return documentIdv3Requests
    .getRawWorkspaceMetadata(origin, token, workspaceId)
    .pipe(
      mergeMap((metadata) => {
        if (metadata.workspaceType === "DELETED")
          throw new Error("Cannot update the name of a deleted workspace");

        const details = metadataV3toWorkspaceDetails(metadata);
        const updatedMetadata: DocumentMetadataV1Request = {
          schemaVersion: details.schemaVersion,
          documentType: details.workspaceType,
          pageSize: details.pageSize,
          documentName: name,
          module: details.module,
          // if we keep permissions undefined or null, they will not be changed
        };
        return updateRawWorkspaceMetadata(
          origin,
          token,
          workspaceId,
          updatedMetadata
        );
      }),
      // convert the response back to WorkspaceDetails
      mergeMap((metadata) => of(toWorkspaceDetails(metadata)))
    );
}

function updateRawWorkspaceMetadata(
  origin: string,
  token: string,
  workspaceId: string,
  metadata: DocumentMetadataV1Request
): Observable<DocumentMetadataV1Response> {
  const url = `${origin}/api/v1/${workspaceId}`;

  return putJSON<DocumentMetadataV1Request, DocumentMetadataV1Response>(
    url,
    metadata,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

function deleteWorkspace(
  origin: string,
  token: string,
  workspaceId: string,
  password?: string
) {
  const url = `${origin}/api/v1/${workspaceId}`;

  return ajax.delete(url, {
    Authorization: `Bearer ${token}`,
    "X-Hoylu-Document-Password": encode(password),
  });
}

function setWorkspacePassword(
  origin: string,
  token: string,
  workspaceId: string,
  currentPasword: string | undefined,
  password: string
) {
  const url = `${origin}/api/v1/${workspaceId}/password`;

  return requestText(
    url,
    { newPassword: password },
    {
      Authorization: `Bearer ${token}`,
      "X-Hoylu-Document-Password": encode(currentPasword),
    }
  );
}

function removeWorkspacePassword(
  origin: string,
  token: string,
  workspaceId: string,
  currentPassword: string
) {
  const url = `${origin}/api/v1/${workspaceId}/password`;

  return ajax.delete(url, {
    Authorization: `Bearer ${token}`,
    "X-Hoylu-Document-Password": encode(currentPassword),
  });
}

export const documentIdv1Requests = {
  getAllMyWorkspaces,
  deleteWorkspace,
  setWorkspacePassword,
  removeWorkspacePassword,
  updateWorkspaceName,
};
