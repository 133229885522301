export function isWindows() {
  return window.navigator.platform.toLocaleLowerCase().includes("win");
}
  
// return true if holding action button + left click or using middle click
export const hasNewTabInput = (event: React.MouseEvent): boolean => {
  if (!event) {
    return false;
  }
  const isActionKeyPressed = isWindows() ? event.ctrlKey : event.metaKey;
  return event.button === 1 || (event.button === 0 && isActionKeyPressed);
}

export default hasNewTabInput;