import React, { useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { AppMode } from "../state/mode/mode.reducer";
import { Localized } from "../strings";
import { selectProject } from "../state/workspaces/workspaces.actions";
import LogoOnlyDark from "../assets/images/logos/logo-only-theme-dark.svg";
import LogoOnlyLight from "../assets/images/logos/logo-only-theme-light.svg";
import Styles from "./HoyluLogo.module.css";

const mapStateToProps = (state: RootState) => ({
  isEditing: state.mode === AppMode.EDITING,
  isLoading: state.mode === AppMode.LOADING_WORKSPACE,
});

type LogoProps = ReturnType<typeof mapStateToProps>;

export const HoyluLogo: React.FC<LogoProps> = ({ isEditing, isLoading }) => {
  const dispatch = useDispatch();
  const strings = Localized.object("HOYLU_LOGO")["HOYLU_LOGO"];
  const isProjectSelected = useSelector(
    (state: RootState) => state.context.workspaces.selectedProject
  );
  const redirectToDashboard = () =>
    isProjectSelected ? dispatch(selectProject(null)) : null;

  const containerClassName = useMemo(() => {
    const className = [Styles.logoContainer];
    if (isProjectSelected) {
      className.push(Styles.cursorPointer);
    }
    return className.join(" ");
  }, [isProjectSelected]);

  return !isEditing && !isLoading ? (
    <div
      className={containerClassName}
      onClick={redirectToDashboard}
      tabIndex={0}
      title={"Hoylu Logo"}
    >
      {/* logo for medium+ screen sizes */}
      <img
        data-test-id="main-logo-only-dark"
        className={Styles.largeLogo}
        src={LogoOnlyDark}
        alt={strings}
      />
      <img
        data-test-id="main-logo-only-light"
        className={Styles.largeLogo}
        src={LogoOnlyLight}
        alt={strings}
      />
      {/* logo for small screen sizes */}
      <img
        data-test-id="main-logo-only-dark"
        className={Styles.smallLogo}
        src={LogoOnlyDark}
        alt={strings}
      />
      <img
        data-test-id="main-logo-only-light"
        className={Styles.smallLogo}
        src={LogoOnlyLight}
        alt={strings}
      />
    </div>
  ) : null;
};

export default connect(mapStateToProps)(HoyluLogo);
