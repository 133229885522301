import renderApp from "./ui/App";
import { createStore } from "./state";
import {
  getConfig,
  updateConfig,
  updateVersion,
} from "./state/config/config.actions";
import {
  populateInitialWorkspaces,
  queueEditWorkspace,
  handleFullscreenChange,
  queueCreateWorkspace,
  searchParamsForEditWorkspace,
  queueProjectLoading,
} from "./state/workspaces/workspaces.actions";
import { getToken } from "./state/user/user.actions";
import { connectPostMessageToDispatch } from "./post-message-dispatch";
import { connectHistoryToDispatch } from "./history-state-dispatch";
import {
  getSearchParamsForDeepLinks,
  checkURLForProjectLink,
  checkURLPathForCommand,
  checkUrlPathForWorkspaceId,
  getProjectIdFromUrl,
} from "./utils/url.helpers";
import { detectTeams } from "./utils/detectTeams";
import { connectFeatureFlagsToDispatch } from "./feature-flags-dispatch";
import packageJson from "../package.json";
import { English } from "./_localized/en";
import { allLocalizations, Localized } from "./strings";
import { v4 } from "uuid";
import { log } from "@hoylu/client-common";
import "@hoylu/client-common/dist/esm/assets/style/hoylu-global.css";
import "@hoylu/client-common/dist/esm/assets/style/hoylu-ui-icons-centered.css";
import "@hoylu/client-common/dist/esm/assets/style/fonts.css";
import "@hoylu/client-common/dist/esm/assets/style/theme.css";
import { detectWebex } from "./utils/detectWebex";
import { createRoot } from "react-dom/client";
import { defaultDashboardPageSize } from "./utils/defaultDashboardPageSize";

const logSessionId = v4();
// store in session storage so that workspace in iframe can use the same one (Note: it has a different log object)
sessionStorage.logSessionId = logSessionId;
log.setSessionId(logSessionId);

Localized.pushLocale("en", English);
const currentLanguage = Localized.getCurrentLanguage();
if (currentLanguage && currentLanguage !== "en") {
  Localized.pushLocale(currentLanguage, allLocalizations[currentLanguage]);
}

// preventing pinch zooming
document.addEventListener(
  "wheel",
  (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  },
  { passive: false }
);

const store = createStore();
const env = (window as any).__env;
store.dispatch(
  updateConfig({
    isDev: env === undefined,
    isBeta: window.origin.toLowerCase().includes("beta"),
    // Use default workspace ideas if env variable is undefined.
    // Notes: Empty string "" is indicating there is no workspace ideas workspace!
    //        WORKSPACE_IDEAS_ID is injected in app-dashboard.nomad, but not available (= undefined) when running locally, or in electron
    workspaceIdeasId: env?.WORKSPACE_IDEAS_ID ?? "608940606",
  })
);

store.dispatch(
  updateVersion(
    !window.localStorage.getItem("version") ||
      window.localStorage.getItem("version") !== packageJson.version
  )
);

const devConfigUrl =
  localStorage.getItem("devConfigUrl") ??
  "https://hoylu.staging.eng.hoy.lu/api/v1/config";
const configUrl: string = env?.CONFIG_URL || devConfigUrl;

detectTeams(store);
detectWebex(store);

const workspaceToEdit = checkUrlPathForWorkspaceId(window.location.pathname);
if (workspaceToEdit) {
  store.dispatch(queueEditWorkspace({ workspaceId: workspaceToEdit }));
  const searchParams = getSearchParamsForDeepLinks();

  if (searchParams) {
    store.dispatch(
      searchParamsForEditWorkspace({ [workspaceToEdit]: searchParams.slice(1) })
    );
  }
} else if (checkURLPathForCommand(window.location.pathname) === "new") {
  store.dispatch(queueCreateWorkspace());
} else if (checkURLForProjectLink()) {
  const id = getProjectIdFromUrl();
  if (id) {
    store.dispatch(queueProjectLoading(id));
  }
}

connectPostMessageToDispatch(store);
connectHistoryToDispatch(store);
connectFeatureFlagsToDispatch(store);

store.dispatch(getConfig.request({ configUrl }));
store.dispatch(getToken.request());
store.dispatch(
  populateInitialWorkspaces.request({ limit: defaultDashboardPageSize })
);
window.document.documentElement.onfullscreenchange = () => {
  store.dispatch(handleFullscreenChange());
};

const start = () => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  renderApp(root, store);
};

start();
