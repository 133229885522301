import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { activateDashboardOption } from "../state/workspaces/workspaces.actions";
import { CircularProgressBar } from "./CircularProgressBar";
import UserProfileMenu from "./UserProfileMenu";
import { effectiveTonnageUsage } from "../state/workspaces/workspaces.selector";
import { getUserMarbleColor } from "../state/user/user.selector";
import UserProfileMarble from "./UserProfileMarble";
import Styles from "./UserProfileWidget.module.css";

export const UserProfileWidget: React.FunctionComponent<HeaderProps> = ({
  profile,
  userColor,
  effectiveTonnageUsage,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const toggleProfileOpen = () => setProfileOpen(!profileOpen);

  return (
    <>
      <div ref={ref} className={Styles.userProfileWidget}>
        <CircularProgressBar
          percentage={effectiveTonnageUsage}
          themeColor={userColor}
          onClick={toggleProfileOpen}
        >
          <UserProfileMarble
            profile={profile}
            onClick={toggleProfileOpen}
            userColor={userColor}
          />
        </CircularProgressBar>
        <div className={Styles.userName}>
          <div>{profile.firstName}</div>
          <div>{profile.lastName}</div>
        </div>
      </div>
      {profileOpen && (
        <div className={Styles.userProfileContainer}>
          <UserProfileMenu
            close={() => setProfileOpen(false)}
            toggleRef={ref}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  profile: state.context.user.profile,
  effectiveTonnageUsage: effectiveTonnageUsage(state),
  userColor: getUserMarbleColor(state),
});

const mapDispatchToProps = {
  activateDashboardOption,
};

type HeaderProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileWidget);
