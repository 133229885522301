import { Divider, Icon } from "@hoylu/client-common";
import React, { useMemo } from "react";
import { getAllWorkspaces } from "../state/workspaces/workspaces.selector";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import WorkspaceCard from "./cards/WorkspaceCard/WorkspaceCard";
import Styles from "./TeamsSidePanel.module.css";

export const TeamsSidePanel: React.FunctionComponent<TeamsSidePanelProps> = ({
  workspaces,
}) => {
  const liveWorkspace = useMemo(() => workspaces.find((w) => w.isLive), [
    workspaces,
  ]);
  if (!liveWorkspace) return <></>;
  return (
    <div className={Styles.teamsSidePanel}>
      <Icon
        icon={"hoylu-ui-icons-arrowup"}
        style={{ color: "var(--global--notification--Color)" }}
        disabled={true}
      />
      <span className={Styles.shareDescription}>
        Use share button to share workspace in meeting
      </span>
      <Divider horizontal={true} width={"100%"} />
      <span className={Styles.selectedWorkspace}>
        Selected Hoylu Workspace:
      </span>
      <WorkspaceCard
        workspaceDetails={liveWorkspace}
        hide={true}
        order={liveWorkspace.order}
        key={liveWorkspace.workspaceId}
        editWorkspaceAction={() => {}}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  workspaces: getAllWorkspaces(state),
});

type TeamsSidePanelProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(TeamsSidePanel);
