import {
  TemplateCategory,
  TemplateMetadata,
} from "../../state/templates/types";

export type FetchTemplatesResponse = {
  templates: TemplateMetadata[];
  cursor: number | null;
};

export type FetchTemplateCategoriesResponse = {
  categories: TemplateCategory[];
  cursor: number | null;
};

export type FetchOrganizationTemplatesResponse = {
  templates: TemplateMetadata[];
  offset: number;
}

export const QUICK_ACCESS_CATEGORY_ID = "6825c168-6e9a-49a3-9782-50e6b8a3eb9d";
export const ORGANIZATION_CATEGORY_ID = "f6122144-c9ae-44c9-af7b-50e75bae3adb";