export class DocumentError extends Error {
  errorCode: number;

  constructor(message: string, code: number) {
    super(message);
    this.errorCode = code;
  }
}

export const ERRORCODES = {
  MISSING_DATA: 1000,
  INVALID_DATA: 1001,
  NOT_CONNECTED: 1002,
  PRECONDITION_FAILED: 412,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  NOT_AUTHORIZED: 401,
  REQUEST_ERROR: 400,
  SERVER_ERROR: 500,
} as const;
