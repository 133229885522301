import React, { FC, Fragment, useRef, useEffect } from "react";
import { RootState } from "typesafe-actions";
import { connect } from "react-redux";
import {
  HoyluToken,
  userRegistrationComplete,
} from "../../state/user/user.actions";
import { getLogin } from "../../services/dependencies/loginDependencies";
import Styles from "./CompleteAccount.module.css";

const mapStateToProps = (state: RootState) => ({
  email: state.context.user.profile.email,
  config: state.context.config,
});

const mapDispatchToProps = {
  complete: userRegistrationComplete,
};

type CompleteAccountProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const CompleteAccount: FC<CompleteAccountProps> = ({
  email,
  config,
  complete,
}) => {
  const completeRegistrationElement = useRef<HTMLDivElement>(null);
  const showForm = () => {
    if (!completeRegistrationElement.current) return;
    if (!config.serviceConfig.login) return;
    if (completeRegistrationElement.current.lastElementChild) {
      completeRegistrationElement.current.removeChild(
        completeRegistrationElement.current.lastElementChild
      );
    }
    const Login = getLogin({
      loginHost: config.serviceConfig.login,
    });
    Login.showCompleteRegistrationForm(
      email,
      completeRegistrationElement.current,
      (token: HoyluToken) => {
        complete(token);
      }
    );
  };
  useEffect(showForm, [config]);
  return (
    <Fragment>
      {/* overlay everything except header to prevent interaction, header is required to log out */}
      <div className={Styles.overlay} />

      <div className={Styles.completeAccount}>
        <div className={Styles.content} ref={completeRegistrationElement} />
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteAccount);
