import React from "react";
import { Localized } from "../strings";
import type { User } from "../state/user/user.reducer";
import { getMarbleContent, Marble } from "@hoylu/client-common";
import { hexToRgba } from "../utils/hexToRgba";

export type UserProfileMarbleProps = {
  profile: User;
  userColor: string;
  onClick?: () => void;
}

const UserProfileMarble: React.FC<UserProfileMarbleProps> = ({
  profile,
  userColor,
  onClick,
}) => {
  const accountMarbleStyles = {
    width: "2rem",
    height: "2rem",
    outline: "2px solid transparent",
    outlineOffset: "2px",
    margin: "0",
    lineHeight: 1.25,
    backgroundColor: userColor,
    fontSize: "var(--global--size-h1)",
    fontWeight: "700",
    color: hexToRgba("#000", 0.5),
  };

  return (
    <Marble
      title={profile.email}
      testId="logout-button"
      onClick={onClick}
      style={accountMarbleStyles}
      ariaLabel={Localized.string("USER_PROFILE_WIDGET.USER_MARBLE")}
    >
      {getMarbleContent(profile.firstName, profile.lastName)}
    </Marble>
  );
};

export default UserProfileMarble;