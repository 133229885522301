import type { Option } from "@hoylu/client-common";
import type { ProjectOption } from "./types";
import { ProjectSelectorLabel } from "./ProjectSelectorLabel";
import React from "react";

export const ProjectValueRenderer = (value: Option<ProjectOption>[]) => {
  const selectedOption = value[0];
  if (!selectedOption) return null;
  const project = selectedOption.value;

  return (
    <ProjectSelectorLabel
      icon={project.icon}
      name={project.name}
      status={project.status}
      color={project.color}
      endDate={project.endDate}
      hasAdmin={project.hasAdmin}
    />
  );
};
