import { Theme, THEME_DARK, useThemeContext } from "@hoylu/client-common";
import { useSelector } from "react-redux";
import { isEditingAWorkspace } from "../../state/workspaces/workspaces.selector";

export const useDashboardTheme = (): Theme => {
  const theme = useThemeContext();
  const isEditing = useSelector(isEditingAWorkspace);

  return isEditing ? THEME_DARK : theme.theme;
};
