import React from "react";
import { Button } from "@hoylu/client-common";
import Styles from "./RemoveButton.module.css";

export type RemoveButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

export const RemoveButton = ({
  text,
  onClick,
  disabled,
}: RemoveButtonProps) => {
  return (
    <Button
      type={"secondary"}
      classNames={[Styles.removeButton]}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={"hoylu-ui-icons-incomplete"} />
      {`${text}...`}
    </Button>
  );
};
