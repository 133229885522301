import React from "react";
import Styles from "./label.module.css";

type LabelProps = {
  text: string | number;
  title?: string;
};

export const Label = ({ text, title }: LabelProps) => {
  return (
    <div className={Styles.label} title={title}>
      {text}
    </div>
  );
};
