import { jwtDecode } from "jwt-decode";
import { HoyluToken } from '../state/user/user.actions';
import { User } from '../state/user/user.reducer';

export interface UserFullPayload {
  iss: string;
  aud: string;
  exp: number;
  jti: string;
  iat: number;
  nbf: number;
  sub: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  licenseValid: boolean;
  licenseExpiration: number;
  isPartialAccount: boolean;
  offlineUsageExpiration: number;
  org: string;
  Permissions: {
    Read: string[];
    Write: string[];
    ShareExternal: string[];
    Administer: string[];
  };
}

export function encode(str: any) {
  let buffer;

  if (str instanceof Buffer) {
    buffer = str;
  } else {
    buffer = Buffer.from(str.toString(), 'binary');
  }

  return buffer.toString('base64');
}

export function decodeToken(token: HoyluToken): User {
  const fullPayload = jwtDecode<UserFullPayload>(token);
  const selectedAttributes = {
    userId: fullPayload.userId,
    firstName: fullPayload.firstName,
    lastName: fullPayload.lastName,
    email: fullPayload.email,
    isPartialAccount: fullPayload.isPartialAccount,
    licenseValid: fullPayload.licenseValid,
    orgId: fullPayload.org,
    permissions: {
      read: fullPayload.Permissions.Read,
      write: fullPayload.Permissions.Write,
      shareExternal: fullPayload.Permissions.ShareExternal,
      administer: fullPayload.Permissions.Administer,
    },
  };
  return selectedAttributes;
}

// given first and last name strings, returns capitalized initals
export function nameToInitials(firstName: string, lastName: string): string {
  return firstName
    .charAt(0)
    .toUpperCase()
    .concat(lastName.charAt(0).toUpperCase());
}
