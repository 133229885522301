import React from "react";
import { useDispatch } from "react-redux";
import {
  activateDashboardOption,
  setSearchTerm,
} from "../state/workspaces/workspaces.actions";
import { Icon } from "@hoylu/client-common";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import Styles from "./FilterByWorkspaceName.module.css";
import { SearchInput } from "./inputs/SearchInput";

export const FilterByWorkspaceName: React.FC<{ searchTerm: string }> = ({
  searchTerm,
}) => {
  const searchIconStyle = { color: "var(--theme-text)" };
  const dispatch = useDispatch();

  const openSearchWorkspaceDialog = () =>
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.SEARCH_WORKSPACE,
      })
    );

  const handleChange = (value: string) => dispatch(setSearchTerm(value));

  return (
    <>
      <SearchInput
        searchTerm={searchTerm}
        handleChange={handleChange}
        responsive={true}
      />
      <div className={Styles.searchIcon}>
        <Icon
          icon={"hoylu-ui-icons-search"}
          style={searchIconStyle}
          hoverStyle={searchIconStyle}
          onClick={openSearchWorkspaceDialog}
          testId={"search-workspace"}
        />
      </div>
    </>
  );
};
