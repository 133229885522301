import type { OptionRendererProps } from "@hoylu/client-common";
import type { ProjectOption } from "./types";
import Styles from "./ProjectOptionRenderer.module.css";
import React from "react";
import { ProjectSelectorLabel } from "./ProjectSelectorLabel";

export const ProjectOptionRenderer = ({
  option,
  onClick,
  key,
}: OptionRendererProps<ProjectOption>) => {
  const project = option.value;
  return (
    <div
      key={key}
      className={Styles.option}
      onClick={() => {
        onClick();
        option.value.onSelected?.();
      }}
    >
      <ProjectSelectorLabel
        icon={project.icon}
        name={option.label}
        status={project.status}
        color={project.color}
        endDate={project.endDate}
        hasAdmin={project.hasAdmin}
      />
    </div>
  );
};
