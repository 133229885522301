import { AppEpic } from "../types";
import { EMPTY, merge, of } from "rxjs";

import {
  catchError,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import {
  UpsertWorkspaceUsersParameters,
  addWorkspaceToProject,
  assignableProjects,
  deleteWorkspace,
  getProjectsOfUser,
  getWorkspacesOfProject,
  importProjectUsers,
  populateInitialWorkspaces,
  removeWorkspaceFromProject,
  upsertWorkspaceUsers,
  getProjectCollaborators,
  duplicateWorkspace,
  selectProject,
} from "../../state/workspaces/workspaces.actions";
import type {
  AssignableProjectsResponse,
  UserProjectsResponse,
  WorkspaceDetailsWithUserRoles,
} from "../../state/workspaces/types";
import { Localized } from "../../strings";
import { isAjaxError } from "../dependencies/ajaxRequests";
import { waitForToken } from "../helpers/waitForState";
import { isErrorResponse } from "./types";
import { metadataV3toWorkspaceDetails } from "./api/to.workspace.details";
import { log } from "@hoylu/client-common";
import { GlobalAccess } from "./global.access";
import { UpsertWorkspaceUsersPermissions } from "./api/workspaces.v3.types";
import { selectCanUseProjects } from "../../state/config/config.selector";
import { isCreditLimitReached } from "../../state/workspaces/workspaces.selector";

// entry point - load project data once we have populated the workspaces
export const autoUpdateProjectsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(populateInitialWorkspaces.success)),
    filter(() => selectCanUseProjects(state$.value)),
    mergeMap(() =>
      merge(of(getProjectsOfUser.request()), of(assignableProjects.request()))
    )
  );

export const getAssignableProjectsEpic: AppEpic = (
  action$,
  state$,
  { getJSON }
) => {
  return action$.pipe(
    filter(isActionOf(assignableProjects.request)),
    waitForToken(state$),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;
      return getJSON<AssignableProjectsResponse>(
        `${documentMetadata}/api/v1/projects/my/assignable`,
        { Authorization: `Bearer ${user.token}` }
      ).pipe(
        mergeMap((response) => of(assignableProjects.success(response || []))),
        catchError((error) => {
          return of(
            assignableProjects.failure({
              requestAction: action,
              error,
              message: Localized.string("ERROR.SOMETHING_WENT_WRONG"),
            })
          );
        })
      );
    })
  );
};

export const getProjectsOfUserEpic: AppEpic = (
  action$,
  state$,
  { getJSON }
) => {
  return action$.pipe(
    filter(isActionOf(getProjectsOfUser.request)),
    waitForToken(state$),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;
      return getJSON<UserProjectsResponse>(
        `${documentMetadata}/api/v1/projects/my`,
        { Authorization: `Bearer ${user.token}` }
      ).pipe(
        mergeMap((response) => of(getProjectsOfUser.success(response || []))),
        catchError((error) => {
          return of(
            getProjectsOfUser.failure({
              requestAction: action,
              error,
              message: Localized.string("ERROR.SOMETHING_WENT_WRONG"),
            })
          );
        })
      );
    })
  );
};

export const addWorkspaceToProjectEpic: AppEpic = (
  action$,
  state$,
  { putJSON }
) => {
  return action$.pipe(
    filter(isActionOf(addWorkspaceToProject.request)),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;
      const { projectId, workspaceId } = action.payload;
      return putJSON<string[], undefined>(
        `${documentMetadata}/api/v1/projects/${projectId}/workspaces`,
        [workspaceId] /* string[] .. array of workspace ids to add */,
        {
          Authorization: `Bearer ${user.token}`,
        },
        true // allow empty response -> is set undefined anyways
      ).pipe(
        mergeMap(() => of(addWorkspaceToProject.success(action.payload))),
        catchError((error) => {
          let message = Localized.string("ERROR.CANNOT_APPLY_CHANGES"); // generic error
          if (isAjaxError(error)) {
            console.dir(error);
            switch (error.status) {
              case 400:
                message = [
                  Localized.string("ERROR.PROJECTS.CANNOT_ADD_WORKSPACE"),
                  isErrorResponse(error.response)
                    ? error.response.code === "WorkspaceLimitReached"
                      ? Localized.string("ERROR.PROJECTS.REASON_MAX_WORKSPACES")
                      : error.response.message
                    : String(error.response),
                ].join(" ");
                break;
              case 403:
                message = Localized.string(
                  "ERROR.PROJECTS.INSUFFICIENT_PERMISSIONS_TO_ADD_WORKSPACE",
                  String(error.response)
                );
                break;
            }
          }
          return of(
            addWorkspaceToProject.failure({
              requestAction: action,
              error,
              message,
            })
          );
        })
      );
    })
  );
};

export const reloadPageAfterAddWorkspaceToProjectSuccessEpic: AppEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(addWorkspaceToProject.success)),
    tap({
      next: () => {
        if (isCreditLimitReached(state$.value)) {
          window.location.reload();
        }
      },
    }),
    ignoreElements()
  );
};

export const removeWorkspaceFromProjectEpic: AppEpic = (
  action$,
  state$,
  { deleteAjax }
) => {
  return action$.pipe(
    filter(isActionOf(removeWorkspaceFromProject.request)),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;
      const { projectId, workspaceId } = action.payload;
      return deleteAjax(
        `${documentMetadata}/api/v1/projects/${projectId}/workspaces/${workspaceId}`,
        {
          Authorization: `Bearer ${user.token}`,
        }
      ).pipe(
        mergeMap(() => of(removeWorkspaceFromProject.success(action.payload))),
        catchError((error) => {
          let message = Localized.string("ERROR.CANNOT_APPLY_CHANGES"); // generic error
          if (isAjaxError(error) && error.status === 403) {
            message = Localized.string(
              "ERROR.PROJECTS.INSUFFICIENT_PERMISSIONS_TO_REMOVE_WORKSPACE"
            );
          }
          return of(
            removeWorkspaceFromProject.failure({
              requestAction: action,
              error,
              message,
            })
          );
        })
      );
    })
  );
};

export const updateWorkspacesOfProjectOnDuplicateWorkspaceEpic: AppEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(duplicateWorkspace.success)),
    filter(() => !!state$.value.context.workspaces.selectedProject?.id),
    mergeMap(() => {
      const projectId = state$.value.context.workspaces.selectedProject!.id;
      // Note ! -> must be defined because of filter defined above
      return of(getWorkspacesOfProject.request(projectId));
    })
  );
};

// When removing a workspace from a project -> reload the selected projects
export const updateWorkspacesOfProjectOnRemoveWorkspaceEpic: AppEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(removeWorkspaceFromProject.success)),
    filter(() => !!state$.value.context.workspaces.selectedProjectWorkspaces),
    mergeMap((action) => {
      return of(getWorkspacesOfProject.request(action.payload.projectId));
    })
  );
};

// When deleting a workspace > reload the selected projects
export const updateWorkspacesOfProjectOnDeleteWorkspaceEpic: AppEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(deleteWorkspace.success)),
    filter(
      () =>
        !!state$.value.context.workspaces.selectedProjectWorkspaces &&
        state$.value.context.workspaces.selectedProjectWorkspaces.length > 0 &&
        !!state$.value.context.workspaces.selectedProjectWorkspaces[0]
          .containerId
    ),
    mergeMap(() => {
      // we do not have a containerId in the action payload, so just reload from the project we currently have loaded.
      const projectId = state$.value.context.workspaces
        .selectedProjectWorkspaces![0].containerId!; // Note ! -> must be defined because of filter defined above
      return of(getWorkspacesOfProject.request(projectId));
    })
  );
};

// Note: potential service load optimization: Use local caching and only do bulkDetail request on workspaces that have changed (lastModified has changed)
export const getWorkspacesOfProjectEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(getWorkspacesOfProject.request)),
    waitForToken(state$),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;
      const projectId = action.payload;

      return documentIdv3Requests
        .getProjectWorkspaces(documentMetadata, user.token, projectId)
        .pipe(
          mergeMap((response) =>
            of(
              getWorkspacesOfProject.success(
                response.map((d) => metadataV3toWorkspaceDetails(d))
              )
            )
          ),
          catchError((error) =>
            of(
              getWorkspacesOfProject.failure({
                requestAction: action,
                error,
                message: Localized.string("ERROR.SOMETHING_WENT_WRONG"),
              })
            )
          )
        );
    })
  );

function globalAccessToUpsertWorkspaceUsersPermissions(
  globalAccess: GlobalAccess
): UpsertWorkspaceUsersPermissions {
  switch (globalAccess) {
    case GlobalAccess.READ:
      return UpsertWorkspaceUsersPermissions.Read;
    case GlobalAccess.WRITE:
      return UpsertWorkspaceUsersPermissions.Write;
    default:
      return UpsertWorkspaceUsersPermissions.None;
  }
}

function getUsersToAddToWorkspacePermissions(
  currentWorkspaceDetails: WorkspaceDetailsWithUserRoles,
  projectUsers: string[]
) {
  const { roles } = currentWorkspaceDetails;
  const caseInsensitiveRoles = {
    administrators: roles.administrators.map((v) => v.toLowerCase()),
    readers: roles.readers.map((v) => v.toLowerCase()),
    writers: roles.writers.map((v) => v.toLowerCase()),
    unspecifiedUsers: roles.unspecifiedUsers.map((v) => v.toLowerCase()),
  };

  const alreadyExists = (email: string) => {
    return (
      caseInsensitiveRoles.readers.includes(email) ||
      caseInsensitiveRoles.writers.includes(email) ||
      caseInsensitiveRoles.administrators.includes(email) ||
      caseInsensitiveRoles.unspecifiedUsers.includes(email)
    );
  };

  const globalAccess = roles.globalAccess;

  const usersToAdd: Omit<UpsertWorkspaceUsersParameters, "workspaceId"> = {
    permission: globalAccessToUpsertWorkspaceUsersPermissions(globalAccess),
    userEmails: projectUsers.filter((u) => !alreadyExists(u.toLowerCase())),
  };

  return usersToAdd;
}

export const importProjectCollaboratorsEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(getProjectCollaborators.request)),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;

      return documentIdv3Requests
        .getProjectUsers(documentMetadata, user.token, action.payload)
        .pipe(
          mergeMap((response) => {
            const userEmails = response.map((u) => u.email);

            return of(getProjectCollaborators.success(userEmails));
          }),
          catchError((e) => {
            log.error("Unable to import project users", e);
            return EMPTY;
          })
        );
    })
  );

export const importProjectUsersToWorkspacePermissionsEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(importProjectUsers)),
    mergeMap((action) => {
      const { documentMetadata } = state$.value.context.config.serviceConfig;
      const { user } = state$.value.context;

      return documentIdv3Requests
        .getProjectUsers(
          documentMetadata,
          user.token,
          action.payload.containerId
        )
        .pipe(
          mergeMap((response) => {
            const userEmails = response.map((u) => u.email);

            return of(
              upsertWorkspaceUsers.request({
                ...getUsersToAddToWorkspacePermissions(
                  action.payload,
                  userEmails
                ),
                workspaceId: action.payload.workspaceId,
              })
            );
          }),
          catchError((e) => {
            log.error("Unable to import project users", e);
            return EMPTY;
          })
        );
    })
  );

export const selectProjectEpic: AppEpic = (action$) => {
  return action$.pipe(
    filter(isActionOf(selectProject)),
    mergeMap((action) => {
      const projectId = action.payload?.id;
      if (!projectId) return EMPTY;
      return of(
        getWorkspacesOfProject.request(projectId),
        getProjectCollaborators.request(projectId)
      );
    })
  );
};
