import { createSelector } from "reselect";
import { RootState } from "typesafe-actions";
import { UserState } from "./user.reducer";

export const user = (state: RootState): UserState => state.context.user;

export const userId = (state: RootState): string => user(state).profile.userId;

export const isPasswordRequired = (state: RootState): boolean =>
  user(state).requiresPassword;

export const workspacesPrivateByDefault = (state: RootState): boolean =>
  !!user(state).policies?.workspacesPrivateByDefault;

export const authorizationHeader = createSelector([user], (user) => ({
  Authorization: `Bearer ${user.token}`,
}));

export const userToken = (state: RootState): string => user(state).token;

export const getUserOrgId = createSelector([user], (user) => user.profile.orgId);

export const getUserMarbleColor = createSelector([user], (user) => user.userMarbleColor);
