import React from "react";
import { connect, useSelector } from "react-redux";
import headerGraphic from "../../../assets/images/error-illustration.svg";
import { ErrorDialogWithImage } from "./ErrorDialogWithImage";
import { clearError } from "../../../state/mode/mode.actions";
import { Localized } from "../../../strings";
import { managementPortalBillingUrl } from "../../../utils/management.portal/management.portal.url.selectors";
import { openManagementPortal } from "../../../utils/management.portal/openManagementPortal";

const mapDispatchToProps = {
  clearError,
};

type CreditLimitReachedDialogProps = typeof mapDispatchToProps;

export const CreditLimitReachedDialog: React.FC<CreditLimitReachedDialogProps> = ({
  clearError,
}) => {
  const strings = Localized.object("CREDIT_LIMIT_REACHED_DIALOG");
  const billingUrl = useSelector(managementPortalBillingUrl);

  const redirectToBillingPage = () => {
    openManagementPortal(billingUrl);
    clearError();
  };

  return (
    <ErrorDialogWithImage
      onCancel={clearError}
      imageUrl={headerGraphic}
      title={strings.TITLE}
      buttonText={strings.BUTTON_TEXT}
      onButtonClick={redirectToBillingPage}
      showExternalLinkSymbol={true}
      buttonCustomStyles={{
        backgroundColor: "var(--global--attention--Background)",
        color: "var(--global--text--Color)",
      }}
    />
  );
};

export default connect(undefined, mapDispatchToProps)(CreditLimitReachedDialog);
