import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { Button, DraggableWindowHeader, TextInput } from "@hoylu/client-common";
import Styles from "./ChangeMasterDialog.module.css";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type ChangeMasterDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ChangeMasterDialog: React.FC<ChangeMasterDialogProps> = ({
  cancelDashboardOption,
}) => {
  const strings = Localized.object("CHANGE_MASTER_DIALOG");

  const changeMaster = () => {};

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      allowContentToOverflow={true}
    >
      <div data-test-id="remove-template-dialog">
        <DraggableWindowHeader
          title={strings.TITLE}
          onClose={cancelDashboardOption}
        />
        <div className={Styles.subtitle}>
          <span>{strings.SUBTITLE}</span>
          <TextInput
            label={""}
            style={{ width: "100%" }}
            inputStyle={{ paddingBottom: "4px" }}
          />
        </div>
        <div className={Styles.buttons}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={changeMaster}
            dataTestId={"change-linked-master"}
          >
            {strings.CHANGE_MASTER}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMasterDialog);
