import { createSelector } from "reselect";
import { RootState } from "typesafe-actions";
import {
  Project,
  ProjectPermissions,
  ProjectResponse,
  ProjectResponseWithRole,
  WorkspaceDetails,
  WorkspaceProjects,
} from "./types";
import { ProjectStatuses } from "./workspaces.reducer";
import { selectCanUseProjects } from "../config/config.selector";
import { selectHasAssignableProjects } from "./workspaces.selector";

const getAssignableProjectsFromState = (state: RootState) =>
  state.context.workspaces.assignableProjects;

// TODO: Move this bussiness logic to service: Requires Service endpoint that returns permissions instead of roles (or an endpoint to map roles -> permissions)
export const ProjectAdminRoleId = "D9F8F8E9-E9E0-4D4D-B8E8-D9F8F8E9E0D5";
export const UNKNOWN_PROJECT_LABEL = "Unknown";

function isProjectResponseWithRole(
  project: ProjectResponse
): project is ProjectResponseWithRole {
  // Looks somewhat stupid to "as cast" project to type we are checking for, but otherwise TS will complain about "userRole" field not beeing defined.
  return (
    typeof (project as ProjectResponseWithRole).userRole?.roleId === "string"
  );
}

const getProjectPermissions = (
  projectResponse: ProjectResponse
): ProjectPermissions[] => {
  if (!isProjectResponseWithRole(projectResponse)) return [];
  // admin role
  if (projectResponse.userRole.roleId.toUpperCase() === ProjectAdminRoleId)
    return ["AddProjectWorkspaces", "RemoveProjectWorkspaces"];
  // any other role (we do not have any other roles that we need to derived permissions for yet)
  return [];
};

const toProject = (projectResponse: ProjectResponse): Project => ({
  id: projectResponse.id,
  name: projectResponse.name,
  description: projectResponse.description ?? undefined,
  color: projectResponse.appearanceProperties?.color ?? "",
  icon: projectResponse.appearanceProperties?.icon || "box",
  userPermissions: getProjectPermissions(projectResponse),
  hasAdmin: !!(
    isProjectResponseWithRole(projectResponse) &&
    projectResponse.userRole.roleId.toUpperCase() === ProjectAdminRoleId
  ),
  status: projectResponse.status,
  endDate: projectResponse.endDate,
});

// Maps the project response to a Project object and sets the "hasAdmin" property to true.
// Assignable projects are those where the current user has an admin role.
const toAssignableProject = (projectResponse: ProjectResponse): Project => {
  return {
    ...toProject(projectResponse),
    hasAdmin: true,
  };
};

export const getAssignableProjects = createSelector(
  [getAssignableProjectsFromState],
  (assignableProjects): WorkspaceProjects => {
    if (!assignableProjects) return [];

    const allProjects = assignableProjects.map((ap) => toAssignableProject(ap));
    allProjects.sort((a, b) => a.name.localeCompare(b.name)); // alphabetically sorted
    return allProjects;
  }
);

const getProjects = (state: RootState) => state.context.workspaces.projects;

export const getSelectedProject = (state: RootState) =>
  state.context.workspaces.selectedProject;

export const getSelectedProjectColor = createSelector(
  [getSelectedProject],
  (project): string | undefined => {
    if (!project) return undefined;

    return project.color;
  }
);

export const getAllProjectCollaborators = (state: RootState) =>
  state.context.workspaces.projectCollaborators;

export const getProjectFromId = createSelector(
  [getProjects, (state: RootState, id: string | undefined) => id],
  (projects, id) => {
    if (!projects || !id) return undefined;
    const project = projects.get(id);
    if (!project) return undefined;
    return toProject(project);
  }
);

export const createSelectProjectByIdFillingUnknownOne = () =>
  createSelector(
    [getProjects, (state: RootState, id: string | undefined) => id],
    (projects, id): Project | undefined => {
      if (!id) return undefined;
      if (!projects) return undefined; // projects are not loaded
      const project = projects.get(id);
      if (!project)
        return {
          id,
          name: UNKNOWN_PROJECT_LABEL,
          icon: "box",
          color: "red",
          userPermissions: [],
          hasAdmin: false,
          status: ProjectStatuses.Active,
          endDate: null,
        };
      return toProject(project);
    }
  );

export const canAssignProjectToWorkspace = (state: RootState) => {
  return (workspaceDetails: WorkspaceDetails) => {
    const canUseProjects = selectCanUseProjects(state);
    const hasAssignableProjects = selectHasAssignableProjects(state);

    return (
      canUseProjects &&
      hasAssignableProjects &&
      !workspaceDetails.containerId &&
      !!workspaceDetails.isAdmin
    );
  };
};

export const createSelectCanRemoveProjectFromWorkspace = () =>
  createSelector(
    createSelectProjectByIdFillingUnknownOne(),
    selectCanUseProjects,
    (project, canUseProjects) => {
      return (
        canUseProjects &&
        !!project?.userPermissions.includes("RemoveProjectWorkspaces")
      );
    }
  );

export const getAllProjects = createSelector([getProjects], (projects):
  | WorkspaceProjects
  | undefined => {
  if (!projects) return undefined;

  const allProjects = [...projects.values()].map((ap) => toProject(ap));
  allProjects.sort((a, b) => a.name.localeCompare(b.name)); // alphabetically sorted
  return allProjects;
});

export const isProjectInactive = createSelector(
  [getProjectFromId],
  (project) => {
    if (!project) return false;
    return project.status === ProjectStatuses.Inactive;
  }
);

export const isAdminOfSelectedProject = createSelector(
  getSelectedProject,
  (selectedProject) => !!selectedProject?.hasAdmin
);
