import {
  filter,
  tap,
  ignoreElements,
  map,
  distinctUntilChanged,
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { getLicenseInfo } from "../../state/user/user.actions";
import { AppEpic } from "../types";

export const enablePendoEpic: AppEpic = (
  action$,
  state$,
  { identifyUserInPendo }
) => {
  return action$.pipe(
    // trigger on license info sucesss, because when this action is triggerd we have all the data in the state we need (at least until we need more)
    filter(isActionOf(getLicenseInfo.success)),
    filter(() => state$.value.context.config.featureFlags.enablePendo),
    map((_) => state$.value.context.user),
    distinctUntilChanged((a, b) => a.profile.userId === b.profile.userId), // getLicenseInfo.success is also triggered when load a workspace -> do not run when nothing has changed
    tap((user) => {
      const profile = user.profile;
      const enablePendoPII =
        state$.value.context.config.featureFlags.enablePendoPII;
      const piiData = {
        email: profile.email,
        name: [profile.firstName, profile.lastName].join(" "),
      };
      identifyUserInPendo(
        {
          userId: profile.userId,
          orgId: profile.orgId,
          isPartialAccount: profile.isPartialAccount,
          licenseType: user.licenseInfo?.type,
          isEmbeddedInTeams: !!state$.value.context.config.teamsClient,
          isEmbeddedInWebex: !!state$.value.context.config.webexMode,
        },
        enablePendoPII ? piiData : undefined
      );
    }),
    ignoreElements()
  );
};
