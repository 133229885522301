import type { RootState } from "typesafe-actions";
import type { ConfigState, FeatureFlags } from "./config.reducer";

export const config = (state: RootState): ConfigState => state.context.config;

export const workspaceIdeasId = (state: RootState): string | undefined =>
  config(state).workspaceIdeasId;

export const documentMetadata = (state: RootState): string =>
  config(state).serviceConfig.documentMetadata;

export const managementPortal = (state: RootState): string =>
  config(state).serviceConfig.management_portal;

export const transferService = (state: RootState): string =>
  config(state).serviceConfig.transfer;

export const notificationsService = (state: RootState): string =>
  config(state).serviceConfig.notification;

export const authService = (state: RootState): string =>
  config(state).serviceConfig.auth;

export const featureFlags = (state: RootState): FeatureFlags =>
  config(state).featureFlags;

export const duplicateApiSupportsPassword = (state: RootState): boolean =>
  featureFlags(state).duplicateApiSupportsPassword;

export const showLegacyModules = (state: RootState): boolean =>
  featureFlags(state).showLegacyModules;

export const showTemplateBasedModules = (state: RootState): boolean =>
  featureFlags(state).showTemplateBasedModules;

export const highlightEnterpriseFeatures = (state: RootState): boolean =>
  featureFlags(state).highlightEnterpriseFeatures;

export const enableSupplyChain = (state: RootState): boolean =>
  featureFlags(state).enableSupplyChain;

export const enableWorkspaceExport = (state: RootState): boolean =>
  featureFlags(state).enableWorkspaceExport;

export const enableInAppProjectCreation = (state: RootState): boolean =>
  featureFlags(state).enableInAppProjectCreation;

export const enableFavoritesWorkspaceSection = (state: RootState): boolean =>
  featureFlags(state).enableFavoritesWorkspaceSection;

export const showCreditLimitUi = (state: RootState): boolean =>
  featureFlags(state).showCreditLimitUi;

export const enableWorkspaceInfo = (state: RootState): boolean =>
  featureFlags(state).enableWorkspaceInfo;

export const showNotificationsUI = (state: RootState): boolean =>
  featureFlags(state).showNotificationsUI;

export const selectCanUseProjects = (state: RootState): boolean =>
  featureFlags(state).projectLicensing;
