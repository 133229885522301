import type { IAssignToProjectStrategy, ProjectOption } from "./types";
import type { Project } from "../../state/workspaces/types";
import type { Option } from "@hoylu/client-common";
import type { PayloadAction } from "typesafe-actions";
import type { Dispatch } from "react";
import { addWorkspaceToProject } from "../../state/workspaces/workspaces.actions";
import { addPrefixWhenProjectIsInactive } from "../../utils/add.prefix.when.project.is.inactive";

export class AssignToProjectStrategy implements IAssignToProjectStrategy {
  constructor(
    private readonly projects: Project[],
    private readonly assignableProjects: Project[],
    private readonly dispatch: Dispatch<unknown>
  ) {}

  getProjectOptions() {
    const options: Option<ProjectOption>[] = this.assignableProjects.map(
      (p) => ({
        label: addPrefixWhenProjectIsInactive(p),
        value: p,
      })
    );
    return options;
  }

  initialSelectedOption = (projectId?: string): Option<ProjectOption>[] => {
    if (!projectId) return [];

    const project = this.projects?.find((project) => project.id === projectId);
    if (!project) return [];

    return [
      {
        label: addPrefixWhenProjectIsInactive(project),
        value: project,
      },
    ];
  };

  assignToProject(workspaceId: string, project: ProjectOption): void {
    const action = this.getAssignToProjectAction(workspaceId, project);
    this.dispatchAction(action);
  }

  private getAssignToProjectAction(
    workspaceId: string,
    project: ProjectOption
  ) {
    return this.addToProjectRequest(workspaceId, project);
  }

  protected dispatchAction(action: PayloadAction<string, unknown>) {
    this.dispatch(action);
  }

  protected addToProjectRequest(workspaceId: string, project: ProjectOption) {
    return addWorkspaceToProject.request({
      workspaceId: workspaceId,
      projectId: project.id,
    });
  }
}
