import React, { useMemo, useRef, useState } from "react";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  importProjectUsers,
  removeWorkspacePassword,
  setWorkspacePassword,
  updateWorkspaceDetails,
} from "../../../state/workspaces/workspaces.actions";
import { connect, useSelector } from "react-redux";
import { Modal } from "../Modal";
import {
  getSelectedWorkspaceDetails,
  isWorkspaceLimited,
  isWorkspaceReadOnly,
  permissionLevelSets,
} from "../../../state/workspaces/workspaces.selector";
import { getShareLink } from "../../../utils/url.helpers";
import {
  calculatePermissions,
  formatWorkspaceId,
  isOrganizationAdmin,
} from "../../../utils/sharing.helpers";
import { WorkspacePermissionsAndUsers } from "../../../state/workspaces/types";
import { InfoSection, ShareOptionValue, ShareType } from "./ShareType";
import { MODE } from "./WorkspacePassword";
import { GlobalAccess } from "../../../services/workspaces/global.access";
import { Localized } from "../../../strings";
import { PermissionsSection } from "./PermissionsSection";
import { LinkSharingSection } from "./LinkSharingSection";
import { sendMessageToWorkspace } from "../../../post-message-dispatch";
import { createSelectProjectByIdFillingUnknownOne } from "../../../state/workspaces/projects.selector";
import { WorkspaceInfoSection } from "./WorkspaceInfoSection";
import { TemplateInfoSection } from "./TemplateInfoSection";
import { CreditLimitSection } from "./CreditLimitSection";
import Styles from "./ShareWorkspaceDialog.module.css";
import { DraggableWindowHeader, OpenDropdownRef } from "@hoylu/client-common";
import { selectCanUseProjects } from "../../../state/config/config.selector";

const options: ShareOptionValue[] = [
  ShareType.EDIT,
  ShareType.READ,
  ShareType.NO_ACCESS,
];
export const ShareWorkspaceDialog: React.FC<ShareWorkspaceDialogProps> = ({
  cancelDashboardOption,
  workspaceDetails,
  shareLink,
  updateWorkspaceDetails,
  importProjectUsers,
  isUpdatingWorkspace,
  permissionLevelSets,
  email,
  setWorkspacePassword,
  requiresPassword,
  removeWorkspacePassword,
  showOrgAdminPolicy,
  showProjectAdmin,
  emailPreset,
  openSection,
  isProjectAdmin,
  showOverlay,
  isOrgAdmin,
  isLimited,
  isReadOnly,
}) => {
  const openDropdownRef = useRef<OpenDropdownRef>(null);

  const showWarningSection =
    isLimited && !isReadOnly && !workspaceDetails.containerId;
  const initialSectionState = () =>
    workspaceDetails.templateId ? InfoSection.TEMPLATE : InfoSection.WORKSPACE;
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");
  const enableWorkspaceInfo = useSelector(
    (state: RootState) => state.context.config.featureFlags.enableWorkspaceInfo
  );
  const permissions: WorkspacePermissionsAndUsers | undefined =
    workspaceDetails.roles;
  const isAdmin = !!workspaceDetails.isAdmin;
  const [infoSectionType, setInfoSectionType] = useState<InfoSection>(
    initialSectionState
  );
  const [keepTemplateInfoOpen, setKeepTemplateInfoOpen] = useState(false);

  const changeInfoSection = (infoSection: InfoSection) => {
    setInfoSectionType(infoSection);
    if (infoSection === InfoSection.TEMPLATE) {
      setKeepTemplateInfoOpen(true);
    }
  };

  const commitUpdate = (shareOption: ShareOptionValue) => {
    let globalAccess;
    if (shareOption === ShareType.EDIT) {
      globalAccess = GlobalAccess.WRITE;
    } else if (shareOption === ShareType.READ) {
      globalAccess = GlobalAccess.READ;
    } else {
      globalAccess = GlobalAccess.NONE;
    }
    if (permissions) {
      updatePermissionsBar({
        ...permissions,
        globalAccess: globalAccess,
      });
    }
  };

  const close = () => {
    if (workspaceDetails.workspaceId) {
      sendMessageToWorkspace(workspaceDetails.workspaceId, {
        action: "PERMISSIONS_CHANGED",
      });
    }
    cancelDashboardOption();
  };

  const updatePermissionsBar = (newPermissions: WorkspacePermissionsAndUsers) =>
    // TODO: This is called when changing the globalAccess permission, replace it with a more specific action
    updateWorkspaceDetails({
      ...workspaceDetails,
      roles: newPermissions,
    });

  const publicPermissionLevel = useMemo(
    () => (permissions ? calculatePermissions(permissions.globalAccess) : null),
    [permissions]
  );
  const arePermissionsAndUsersLoaded = !!publicPermissionLevel;

  const handleWorkspacePassword = (
    password: string,
    currentPassword: string,
    mode: string
  ): void => {
    switch (mode) {
      case MODE.ADD:
      case MODE.CHANGE:
        setWorkspacePassword({
          workspaceId: workspaceDetails.workspaceId,
          currPassword: currentPassword,
          newPassword: password,
        });
        break;
      case MODE.REMOVE:
        removeWorkspacePassword({
          workspaceId: workspaceDetails.workspaceId,
          currPassword: password,
        });
        break;
    }
  };

  return (
    <Modal
      handleOutsideClick={close}
      allowContentToOverflow={true}
      showOverlay={showOverlay}
    >
      <div className={Styles.shareWorkspaceDialog} data-test-id="share-dialog">
        <div className={Styles.shareWorkspaceDialogContent}>
          <DraggableWindowHeader
            title={`${strings.SHARE_THIS_WORKSPACE}: ${formatWorkspaceId(
              workspaceDetails.workspaceId
            )}`}
            onClose={close}
          />
          {showWarningSection && (
            <CreditLimitSection
              openDropdown={() => openDropdownRef.current?.openDropdown()}
              workspaceDetails={workspaceDetails}
            />
          )}
          {enableWorkspaceInfo &&
            (infoSectionType === InfoSection.WORKSPACE ? (
              <WorkspaceInfoSection
                initiallyOpen={openSection === "info"}
                isOrgAdmin={isOrgAdmin}
                handleChangeSectionType={changeInfoSection}
                workspaceDetails={workspaceDetails}
                openDropdownRef={openDropdownRef}
              />
            ) : (
              <TemplateInfoSection
                workspaceDetails={workspaceDetails}
                isOrgAdmin={isOrgAdmin}
                handleChangeSectionType={changeInfoSection}
                initiallyOpen={keepTemplateInfoOpen || openSection === "info"}
                openDropdownRef={openDropdownRef}
              />
            ))}
          {arePermissionsAndUsersLoaded && (
            <LinkSharingSection
              workspaceName={workspaceDetails.workspaceName ?? ""}
              publicPermissionLevel={publicPermissionLevel}
              shareLink={shareLink}
              email={email}
              commitUpdate={commitUpdate}
              isAdmin={isAdmin}
              options={options}
              isUpdatingWorkspace={isUpdatingWorkspace}
              activeLinkSharing={
                openSection === ShareWorkspaceDialogSections.SHARE
              }
            />
          )}
          {arePermissionsAndUsersLoaded && (
            <PermissionsSection
              activePermission={
                openSection === ShareWorkspaceDialogSections.PERMISSION
              }
              emailPreset={{ emails: emailPreset ?? "" }}
              workspaceDetails={workspaceDetails}
              permissionLevelSets={permissionLevelSets}
              email={email}
              publicPermissionLevel={publicPermissionLevel}
              isUpdatingWorkspace={isUpdatingWorkspace}
              handleWorkspacePassword={(password, currentPassword, mode) =>
                handleWorkspacePassword(password, currentPassword, mode)
              }
              requiresPassword={requiresPassword}
              isAdmin={isAdmin}
              hasPassword={!!workspaceDetails.hasPassword}
              sectionTitle={strings.PERMISSIONS}
              shareLink={shareLink}
              showOrgAdminPolicy={showOrgAdminPolicy}
              showProjectAdmin={showProjectAdmin}
              importProjectUsers={
                isProjectAdmin ? importProjectUsers : undefined
              }
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  cancelDashboardOption,
  updateWorkspaceDetails: updateWorkspaceDetails.request,
  setWorkspacePassword: setWorkspacePassword.request,
  removeWorkspacePassword: removeWorkspacePassword.request,
  importProjectUsers,
};

const mapStateToProps = () => {
  const selectGetProjectByIdFillingUnknownOne = createSelectProjectByIdFillingUnknownOne();

  return (state: RootState) => {
    const workspaceDetails = getSelectedWorkspaceDetails(state)!;

    return {
      workspaceDetails,
      shareLink: getShareLink(
        workspaceDetails.workspaceId,
        state.context.config
      ),
      isUpdatingWorkspace: state.context.workspaces.isUpdatingWorkspace,
      permissionLevelSets: permissionLevelSets(state),
      email: state.context.user.profile.email,
      requiresPassword: state.context.user.requiresPassword,
      showOrgAdminPolicy: !!(
        state.context.user.policies?.allOrgAdminsHaveAdminAccess &&
        workspaceDetails.orgId === state.context.user.profile.orgId
      ),
      showProjectAdmin:
        selectCanUseProjects(state) && !!workspaceDetails.containerId,
      emailPreset: state.context.workspaces.emailPreset,
      isProjectAdmin: selectGetProjectByIdFillingUnknownOne(
        state,
        workspaceDetails.containerId
      )?.hasAdmin,
      showOverlay: state.context.workspaces.showOverlay,
      isOrgAdmin: isOrganizationAdmin(state.context.user.token),
      isLimited: isWorkspaceLimited(state, workspaceDetails.workspaceId),
      isReadOnly: isWorkspaceReadOnly(state, workspaceDetails.workspaceId),
    };
  };
};

export enum ShareWorkspaceDialogSections {
  SHARE = "share",
  PERMISSION = "permission",
  INFO = "info",
}

type ShareWorkspaceDialogProps = {
  openSection?: ShareWorkspaceDialogSections;
} & ReturnType<ReturnType<typeof mapStateToProps>> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareWorkspaceDialog);
