import { AppEpic } from "../types";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import {
  getMyTonnage,
  getUserTonnage,
  recalculateUserTonnage,
} from "../../state/workspaces/workspaces.actions";
import { waitForConfigLoaded, waitForToken } from "../helpers/waitForState";
import { documentMetadata } from "../../state/config/config.selector";
import { userToken } from "../../state/user/user.selector";
import { of } from "rxjs";

export const getMyTonnageEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(getMyTonnage.request)),
    waitForToken(state$),
    mergeMap(() => {
      return documentIdv3Requests
        .getMyWorkspacesTonnage(
          documentMetadata(state$.value),
          userToken(state$.value)
        )
        .pipe(
          mergeMap((res) => of(getMyTonnage.success(res))),
          catchError((error) => of(getMyTonnage.failure(error)))
        );
    })
  );

export const getUserByIdTonnageEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(getUserTonnage.request)),
    waitForToken(state$),
    waitForConfigLoaded(state$),
    mergeMap((action) => {
      return documentIdv3Requests
        .getUserWorkspacesTonnageById(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload
        )
        .pipe(
          map((res) => getUserTonnage.success(res)),
          catchError((error) => of(getUserTonnage.failure(error)))
        );
    })
  );

export const recalculateUserTonnageEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(recalculateUserTonnage.request)),
    waitForToken(state$),
    mergeMap((action) => {
      return documentIdv3Requests
        .recalculateUserTonnage(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload
        )
        .pipe(
          map(() => recalculateUserTonnage.success()),
          catchError((error) => of(recalculateUserTonnage.failure(error)))
        );
    })
  );
