import { Observable } from "rxjs";
import { putJSON } from "../../dependencies/ajaxRequests";
import { WorkspaceLabels } from "../../../state/workspaces/types";

/**
 * Update the labels of a workspace.
 */
function updateWorkspaceLabels(
  origin: string,
  token: string,
  workspaceId: string,
  labels: WorkspaceLabels
): Observable<WorkspaceLabels> {
  const url = `${origin}/api/v2/${workspaceId}/labels`;
  return putJSON<WorkspaceLabels, WorkspaceLabels>(url, labels, {
    Authorization: `Bearer ${token}`,
  });
}

export const documentIdv2Requests = {
  updateWorkspaceLabels,
};
