import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";
import {
  Button,
  DraggableWindowHeader,
  TextArea,
  usePasteOnlyEmail,
  useValidatedState,
} from "@hoylu/client-common";
import { inviteUsers } from "../../state/user/user.actions";
import { emailRegex } from "../../utils/emailRegex";
import Styles from "./InviteUsersDialog.module.css";

const reg = /\s+/;

export const InviteUserDialog = () => {
  const dispatch = useDispatch();
  const strings = Localized.object("INVITE_USERS");

  const ref = usePasteOnlyEmail(" ");
  const emailValidator = (emails: string[] | undefined) => {
    if (emails && !emails?.every((e) => !!e.match(emailRegex))) {
      return strings.ERR;
    }
  };

  const quantityValidator = (emails: string[] | undefined) => {
    if (emails && emails.length > 10) {
      return strings.ERR_QTY;
    }
  };

  const [emailList, setEmailList, emailListErr] = useValidatedState<string[]>(
    [],
    [emailValidator, quantityValidator]
  );
  const [emails, setEmails] = useState("");

  useEffect(() => {
    setEmailList(emails.split(reg).filter((e) => e));
  }, [emails]);

  const inviteUsersHandler = () => {
    dispatch(
      inviteUsers.request({ emails: emailList, reason: "Invite to join Hoylu" })
    );
    dispatch(cancelDashboardOption());
  };

  return (
    <Modal handleOutsideClick={() => dispatch(cancelDashboardOption())}>
      <DraggableWindowHeader
        title={strings.INVITE_USERS_HEADER}
        onClose={() => dispatch(cancelDashboardOption())}
      />
      <div className={Styles.inviteUsersDescription}>
        {strings.INVITE_USERS_DESCRIPTION}
      </div>
      <TextArea
        ref={ref}
        label={""}
        toolTip={emailListErr ?? strings.HINT}
        error={emailListErr}
        value={emails}
        textareaHeight={"12rem"}
        onChange={(value) => setEmails(value)}
        isFocus={true}
      />
      <div className={Styles.footer}>
        <Button
          type={"negative"}
          toolTip={strings.CANCEL}
          onClick={() => dispatch(cancelDashboardOption())}
        >
          {strings.CANCEL}
        </Button>
        <Button
          type={"primary"}
          toolTip={strings.INVITE}
          onClick={() => inviteUsersHandler()}
          disabled={emailList.length === 0 || !!emailListErr}
        >
          {strings.INVITE}
        </Button>
      </div>
    </Modal>
  );
};
