import { of, merge } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { isActionOf } from 'typesafe-actions';

import { AppEpic } from './types';
import { getToken } from '../state/user/user.actions';
import { retryableFailures } from '../state/workspaces/retryableFailures';

export const unauthorizedRetryEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(
      isActionOf(retryableFailures)
    ),
    filter(a => (a.payload.error as AjaxError).status === 401),
    mergeMap(action =>
      merge(of(getToken.request()), of(action.payload.requestAction))
    )
  );
};
