import { History, createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export function syncHistory (
  workspaceId: string,
  history: History = browserHistory
) {
  if (workspaceId === 'DASHBOARD') {
    if (history.location.state !== "DASHBOARD") {
      history.back();
    }
  } else if (history.location.state === 'DASHBOARD') {
    history.push(workspaceId, workspaceId);
  } else if (workspaceId !== history.location.state) {
    history.replace(workspaceId, workspaceId);
  }
}
