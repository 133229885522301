import React, { useState, FC, SetStateAction, Dispatch } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { login, registerEmail } from "../../state/user/user.actions";
import { CenteredContentWithLogo } from "../layouts/CenteredContentWithLogo";
import { Localized } from "../../strings";
import Styles from "./LoginPrompt.module.css";
import { Checkbox } from "@hoylu/client-common";

const ArrowRight: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    className={Styles.arrow}
  >
    <path
      fill="none"
      d="M303,21 L308.66,26.836592 M303,32.836593 L308.66,27 M296,26.92 L308.99,26.92"
      transform="translate(-296 -21)"
    />
  </svg>
);

const termsOfUseUrl = "https://www.hoylu.com/terms-of-use/"; // TODO: Do we have a spot where we store external links?

type RegisterFunction = (email: string) => void;

export const LoginPrompt: React.FC<PromptProps> = ({
  login,
  registerEmail,
  waitingToEditID,
}) => {
  const strings = Localized.object("LOGIN_PROMPT");
  const [enterGuest, setEnterGuest] = useState(false);

  return (
    <CenteredContentWithLogo>
      <p className={Styles.welcome}>{strings.WELCOME_TO_HOYLU}</p>
      {!enterGuest && (
        <React.Fragment>
          <div className={Styles.buttonContainer}>
            <button
              onClick={login}
              type="submit"
              className={Styles.button}
              title={strings.LOGIN}
            >
              <p className={Styles.buttonText}>{strings.LOGIN}</p>
            </button>
          </div>
          <p className={Styles.or}>{strings.OR}</p>
          <div className={Styles.buttonContainer}>
            <button
              onClick={() => setEnterGuest(true)}
              type="submit"
              className={Styles.button}
              title={waitingToEditID ? strings.ENTER_AS_GUEST : strings.SIGN_UP}
            >
              <p className={Styles.buttonText}>
                {waitingToEditID ? strings.ENTER_AS_GUEST : strings.SIGN_UP}
              </p>
            </button>
          </div>
        </React.Fragment>
      )}
      {enterGuest && (
        <RegisterEmailContent
          register={registerEmail}
          setEnterGuest={setEnterGuest}
        />
      )}
    </CenteredContentWithLogo>
  );
};

type RegisterEmailProps = {
  register: RegisterFunction;
  setEnterGuest: Dispatch<SetStateAction<boolean>>;
};

const RegisterEmailContent: React.FunctionComponent<RegisterEmailProps> = ({
  register,
  setEnterGuest,
}) => {
  const strings = Localized.object("LOGIN_PROMPT");
  const [hasAgreed, setHasAgreed] = useState(false);
  const [email, setEmail] = useState("");
  return (
    <>
      <div className={Styles.enterEmail}>
        <input
          type="email"
          className={Styles.input}
          placeholder={strings.ENTER_EMAIL}
          onChange={(e) =>
            setEmail(e.target.checkValidity() ? e.target.value : "")
          }
        />
        <button
          type="submit"
          className={hasAgreed && email ? Styles.accent : Styles.light}
          disabled={!(hasAgreed && email)}
          onClick={(_) => register(email)}
        >
          <p className={Styles.join} title={strings.JOIN}>
            {strings.JOIN} <ArrowRight />
          </p>
        </button>
      </div>
      <div className={Styles.acknowledgeContainer}>
        <Checkbox checked={hasAgreed} onChange={() => setHasAgreed(!hasAgreed)}>
          <div className={Styles.agreeLabel}>{strings.AGREE}</div>
        </Checkbox>
        <button
          onClick={() => window.open(termsOfUseUrl, "_blank")}
          className={Styles.termsOfUse}
          title={strings.TERMS}
        >
          {strings.TERMS}
        </button>
      </div>
      <div className={Styles.backToLoginContainer}>
        <button
          onClick={() => setEnterGuest(false)}
          className={Styles.backToLogin}
          title={strings.BACK_TO_LOGIN}
        >
          {strings.BACK_TO_LOGIN}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  waitingToEditID: state.context.workspaces.waitingToEditID,
});

const mapDispatchToProps = {
  login: login.request,
  registerEmail: registerEmail.request,
};

type PromptProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPrompt);
