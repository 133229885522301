export class TextCopier {
  copy(text: string) {
    if (this.isClipboardAvailable()) {
      this.writeToClipboard(text);
    } else {
      this.copyWithoutClipboard(text);
    }
  }

  private isClipboardAvailable() {
    return !!navigator.clipboard;
  }

  private writeToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  private copyWithoutClipboard(text: string) {
    if (!this.isSelectionAvailable()) return;
    const span = this.prepareSpanElement(text);
    this.selectTextInSpan(span);
    this.executeCopy();
    this.removeSpan(span);
  }

  private isSelectionAvailable() {
    return !!window.getSelection;
  }

  private prepareSpanElement(text: string) {
    const span = document.createElement("span");
    span.innerHTML = text;
    span.setAttribute("display", "none");
    document.body.appendChild(span);
    return span;
  }

  private selectTextInSpan(span: HTMLSpanElement) {
    const selection = window.getSelection();
    selection!.removeAllRanges();
    const range = document.createRange();
    range.selectNodeContents(span);
    selection!.addRange(range);
  }

  private executeCopy() {
    document.execCommand("copy");
  }

  private removeSpan(span: HTMLSpanElement) {
    document.body.removeChild(span);
  }
}
