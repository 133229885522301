import React from "react";
import { Modal } from "./Modal";
import { connect } from "react-redux";
import {
  cancelDashboardOption,
  queueEditWorkspace,
} from "../../state/workspaces/workspaces.actions";
import Cleave from "cleave.js/react";
import { Localized } from "../../strings";
import Styles from "./OpenSharedWorkspaceDialog.module.css";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";

const mapDispatchToProps = {
  handleClose: cancelDashboardOption,
  queueEditWorkspace: queueEditWorkspace,
};

type SearchWorkspaceDialogProps = typeof mapDispatchToProps;

const workspaceIDLength = 9;

export function rawValue(value: string) {
  let result = "";

  for (let i = 0; i < value.length; i++) {
    if (isNaN(parseInt(value.charAt(i)))) continue;
    result += value.charAt(i);
  }

  return result.slice(0, workspaceIDLength);
}

export const OpenSharedWorkspaceDialog: React.FC<SearchWorkspaceDialogProps> = ({
  handleClose,
  queueEditWorkspace,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const strings = Localized.object("SHARED_WORKSPACE");

  return (
    <Modal handleOutsideClick={handleClose}>
      <div className={Styles.openSharedWorkspaceDialog}>
        <DraggableWindowHeader
          title={strings.OPEN_SHARED_WORKSPACE}
          onClose={handleClose}
        />
        <div className={Styles.openWorkspace}>
          <div className={`hoylu-ui-icons-supply-chain ${Styles.icon}`} />
          {strings.ENTER_ID}
        </div>
        <form
          className={Styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            queueEditWorkspace({ workspaceId: searchTerm }); // Note: queueEdit will show the Upsell modal if needed
          }}
        >
          <Cleave
            className={Styles.cleave}
            type="text"
            // Cleave needs some sort of initial value for it's slice function when pasting on mobile
            // This initial value should never appear since it's set to numericOnly
            value="ID"
            options={{
              delimiters: ["-", "-"],
              blocks: [3, 3, 3],
              numericOnly: true,
            }}
            onChange={(e: any) => {
              let newValue = rawValue(e.target.value);
              if (newValue !== searchTerm) {
                setSearchTerm(newValue);
              }
            }}
            autoFocus
          />
          <Button
            data-test-id="open-workspace-button"
            type="primary"
            disabled={searchTerm.length !== workspaceIDLength}
            formType="submit"
            toolTip={strings.OPEN}
          >
            {strings.OPEN}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default connect(null, mapDispatchToProps)(OpenSharedWorkspaceDialog);
