/**
 * This is used mainly to handle projects and workspaces icons.
 * Before moving to @hoylu/client-common library we used different definition of hoylu-ui-icons-centered.
 * Projects and Workspaces are using old acronyms to the icons, and we should keep it that way for
 * backward compatibility.
 * To see all project acronyms look for management-portal-2 "DEFAULT_DROPDOWN_PICKER_ICONS" const.
 * To see all workspaces acronyms look for workspace module names.
 * */
const HICON_TO_HOYLU_UI_ICONS_CENTERED_MAP = new Map<string, string>([
  // *** PROJECTS ***
  ["hoylu", "hoylu-ui-icons-hoylu"],
  ["plus", "hoylu-ui-icons-plus"],
  ["star", "hoylu-ui-icons-star-filled"],
  ["bolt", "hoylu-ui-icons-lightning"],
  ["flag", "hoylu-ui-icons-flag"],
  ["emoji", "hoylu-ui-icons-emoji-grinning-face"],
  ["heart", "hoylu-ui-icons-heart"],
  ["construction", "hoylu-ui-icons-construction"],
  ["construction2", "hoylu-ui-icons-construction2"],
  ["vacation", "hoylu-ui-icons-vacation-on"],
  ["launch", "hoylu-ui-icons-launch"],
  ["location", "hoylu-ui-icons-location-pin"],
  ["send", "hoylu-ui-icons-send"],
  ["shipswheel", "hoylu-ui-icons-ships-wheel"],
  ["library", "hoylu-ui-icons-library"],
  ["bistribute", "hoylu-ui-icons-distribute"],
  ["medal", "hoylu-ui-icons-medal"],
  ["recolor", "hoylu-ui-icons-recolor"],
  ["measuretool", "hoylu-ui-icons-measure-tool"],
  ["pathtool", "hoylu-ui-icons-path-tool"],
  ["rulertool", "hoylu-ui-icons-ruler-tool"],
  ["binoculars", "hoylu-ui-icons-binoculars"],
  ["board", "hoylu-ui-icons-board"],
  ["retain", "hoylu-ui-icons-retain"],
  ["harddisk", "hoylu-ui-icons-hard-disk"],
  ["cloud", "hoylu-ui-icons-cloud"],
  ["key", "hoylu-ui-icons-key"],
  ["cash", "hoylu-ui-icons-cash"],
  ["robot", "hoylu-ui-icons-bot"],
  ["moustache", "hoylu-ui-icons-moustache"],
  ["cake", "hoylu-ui-icons-cake"],
  ["lab", "hoylu-ui-icons-lab"],
  ["box", "hoylu-ui-icons-box"],
  // *** WORKSPACES ***
  ["blank", "hoylu-ui-icons-blank"],
  ["business-model", "hoylu-ui-icons-business-model"],
  ["kanban", "hoylu-ui-icons-clipboard"],
  ["pi-planning", "hoylu-ui-icons-pi-planning"],
  ["pullplanning", "hoylu-ui-icons-pullplanning"],
  ["pullplanningv2", "hoylu-ui-icons-pullplanning"],
  ["pullplanningv3", "hoylu-ui-icons-pullplanning"],
  ["pullplanningv4", "hoylu-ui-icons-pullplanning"],
  ["retrospective", "hoylu-ui-icons-retrospective"],
  ["swot-analysis", "hoylu-ui-icons-swot-analysis"],
  ["simple-drawing", "hoylu-ui-icons-palette-brush"],
  ["value-proposition", "hoylu-ui-icons-value-proposition"],
  ["weekly-planner", "hoylu-ui-icons-planned"],
  ["agile-planning", "hoylu-ui-icons-agile-planning"],
  ["default", "hoylu-ui-icons-question-mark"],
  ["supply-chain", "hoylu-ui-icons-supply-chain"],
  ["sketch", "hoylu-ui-icons-sketch"],
  ["flow", "hoylu-ui-icons-flow"],
  ["reload", "hoylu-ui-icons-reset"]
]);

/**
 * Tries to find hicon mapping.
 * @return corresponding icon from hoylu-ui-icons-centered or empty string
 * if it fails.
 * */
export const hiconToHoyluUiIconsCentered = (hicon: string): string => {
  return HICON_TO_HOYLU_UI_ICONS_CENTERED_MAP.get(hicon) ?? "";
};
