import React, { CSSProperties } from "react";
import { Modal } from "../Modal";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";
import Styles from "./ErrorDialogWithImage.module.css";

export type ErrorDialogWithImageProps = {
  imageUrl: string;
  title: string;
  text?: string;
  buttonText?: string;
  showButton?: boolean;
  showExternalLinkSymbol?: boolean;
  onButtonClick?: () => any;
  onCancel?: () => any;
  buttonCustomStyles?: CSSProperties;
};

export const ErrorDialogWithImage: React.FC<ErrorDialogWithImageProps> = ({
  onCancel,
  onButtonClick,
  title,
  text,
  buttonText,
  imageUrl,
  showButton = true,
  showExternalLinkSymbol = false,
  buttonCustomStyles,
}) => {
  return (
    <Modal handleOutsideClick={onCancel}>
      <DraggableWindowHeader title={""} onClose={() => onCancel?.()} />
      <div className={Styles.errorDialogWithImage}>
        <img className={Styles.image} src={imageUrl} alt="headerGraphic" />
        <div className={Styles.header}>{title}</div>
        {text && <p className={Styles.description}>{text}</p>}
        {buttonText && showButton && (
          <Button
            type={"attention"}
            additionalStyles={buttonCustomStyles}
            onClick={() => onButtonClick && onButtonClick()}
          >
            {buttonText}
            {showExternalLinkSymbol && (
              <span
                className={`hoylu-ui-icons-link-from-task ${Styles.linkIcon}`}
              />
            )}
          </Button>
        )}
      </div>
    </Modal>
  );
};
