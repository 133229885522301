import type { UseAssignWorkspaceToProjectResult } from "./types";
import {
  Dropdown,
  OpenDropdownRef,
  SearchEngineType,
} from "@hoylu/client-common";
import { ProjectValueRenderer } from "./ProjectValueRenderer";
import { ProjectOptionRenderer } from "./ProjectOptionRenderer";
import { RemoveButton } from "../modals/modalElements/RemoveButton";
import React, { useMemo } from "react";
import { Localized } from "../../strings";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceDetails } from "../../state/workspaces/types";
import DefaultDropdownStyle from "@hoylu/client-common/dist/esm/assets/css.modules/default.dropdown.module.css";
import Styles from "./ProjectDropdownWithRemoveButton.module.css";
import { activateDashboardOption } from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import {
  canAssignProjectToWorkspace,
  createSelectCanRemoveProjectFromWorkspace,
} from "../../state/workspaces/projects.selector";
import { RootState } from "typesafe-actions";

type ProjectDropdownWithRemoveButton = {
  workspaceDetails: WorkspaceDetails;
  isEditing: boolean;
  openDropdownRef: React.Ref<OpenDropdownRef>;
} & Omit<
  UseAssignWorkspaceToProjectResult,
  "projectHasChanged" | "saveProject"
>;

export const ProjectDropdownWithRemoveButton = ({
  workspaceDetails,
  currentProjects,
  projectOptions,
  setCurrentProjects,
  isEditing,
  openDropdownRef,
}: ProjectDropdownWithRemoveButton) => {
  const dispatch = useDispatch();
  const strings = Localized.object("SHARE_WORKSPACE_DIALOG");

  const canAssignProject = useSelector(canAssignProjectToWorkspace)(
    workspaceDetails
  );
  const canRemoveProjectFromWorkspace = useMemo(
    () => createSelectCanRemoveProjectFromWorkspace(),
    []
  );
  const canRemoveProject = useSelector((state: RootState) =>
    canRemoveProjectFromWorkspace(state, workspaceDetails.containerId)
  );

  const dropdownClasses = useMemo(() => {
    return [
      DefaultDropdownStyle.dropdown,
      Styles.fixEmptyProjectPosition,
      Styles.dropdown,
    ];
  }, []);

  return (
    <div className={Styles.projectDropdownContainer}>
      <Dropdown
        title={strings.PROJECT}
        showLabel={true}
        values={currentProjects}
        options={projectOptions}
        onChange={setCurrentProjects}
        valueRenderer={ProjectValueRenderer}
        optionRenderer={ProjectOptionRenderer}
        searchEngineType={SearchEngineType.FUZZY}
        allowReset={false}
        classes={dropdownClasses}
        readonly={!isEditing || !canAssignProject}
        openDropdownRef={openDropdownRef}
      />
      {canRemoveProject && (
        <RemoveButton
          onClick={() =>
            dispatch(
              activateDashboardOption({
                optionType: DashboardOption.REMOVE_FROM_PROJECT,
                workspaceId: workspaceDetails.workspaceId,
              })
            )
          }
          text={strings.REMOVE_FROM_PROJECT}
          disabled={!isEditing}
        />
      )}
    </div>
  );
};
