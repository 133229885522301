import { ajax, AjaxError } from "rxjs/ajax";
import { map, catchError } from "rxjs/operators";
import {Observable} from "rxjs";

export function postJSON<TRequest, TResponse>(
  url: string,
  body?: TRequest,
  headers?: Object
) {
  return requestJSON<TRequest, TResponse>("POST", url, body, headers);
}
export function putJSON<TRequest, TResponse>(
  url: string,
  body?: TRequest,
  headers?: Object,
  allowEmptyResponse: boolean = false
) {
  return requestJSON<TRequest, TResponse>(
    "PUT",
    url,
    body,
    headers,
    allowEmptyResponse
  );
}

export function patchJSON<TRequest, TResponse>(
  url: string,
  body?: TRequest,
  headers?: Object,
  allowEmptyResponse: boolean = false
) {
  return requestJSON<TRequest, TResponse>(
    "PATCH",
    url,
    body,
    headers,
    allowEmptyResponse
  );
}
function requestJSON<TRequest, TResponse>(
  method: "POST" | "PUT" | "PATCH",
  url: string,
  body?: TRequest,
  headers?: Object,
  allowEmptyResponse: boolean = false
): Observable<TResponse> {
  return ajax({
    url,
    body,
    method,
    //@ts-expect-error TS2322
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    responseType: "text",
  }).pipe(
    // parse manually because we want the error content in case of error https://github.com/ReactiveX/rxjs/issues/2007
    map((r) => {
      if (!r.response && allowEmptyResponse) {
        return r;
      }
      return {
        ...r,
        response: JSON.parse(r.response as string),
        responseType: "json",
      };
    }),
    map((r) => r.response as TResponse),
    catchError((err) => {
      if (!isAjaxError(err)) throw err;
      // error response objects always seem to be text, json does not get parsed.
      // try to parse it here, to simplify access
      try {
        err.response = JSON.parse(err.response);
        err.responseType = "json";
      } catch {
        // if there is any error, keep the original state
      }
      throw err;
    })
  );
}

export function requestText<TRequest>(
  url: string,
  body?: TRequest,
  headers?: Object,
  method: "POST" | "PUT" | "DELETE" = "POST"
) {
  return ajax({
    url,
    body,
    method,
    //@ts-expect-error TS2322
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      ...headers,
    },
    responseType: "text",
  });
}
export function headRequest<TResponse>(method: "HEAD", url: string) {
  return ajax({
    method,
    url,
  }).pipe(map((r) => r.response as TResponse));
}

export function requestBlob(url: string, headers?: Object) {
  return ajax({
    url,
    method: "GET",
    //@ts-expect-error TS2322
    headers: {
      Accept: "image/png",
      ...headers,
    },
    responseType: "blob",
  }).pipe(map((r) => window.URL.createObjectURL(r.response as Blob)));
}

export function isAjaxError(err: any): err is AjaxError {
  return err instanceof AjaxError;
}
