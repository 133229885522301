import React, { Fragment } from "react";
import { Localized } from "../../../strings";
import { ShareOptionValue } from "./ShareType";
import Styles from "./HeaderPermissions.module.css";
import PermissionStyles from "./ShareType.module.css";

export type HeaderPermissionsProps = {
  enabledOption: ShareOptionValue;
  text: string;
  publicPermissionLevel?: ShareOptionValue;
};

export const HeaderPermissions: React.FC<HeaderPermissionsProps> = ({
  enabledOption,
  text,
  publicPermissionLevel,
}) => {
  const strings = Localized.object("PERMISSION_BAR");

  const computeStyle = (option: ShareOptionValue) => {
    let isNotSelectedStyle = Styles.inactivePill;
    if (
      !publicPermissionLevel ||
      enabledOption.value >= publicPermissionLevel.value
    ) {
      if (option === enabledOption) {
        return option.color;
      } else {
        return isNotSelectedStyle;
      }
    }

    if (option === publicPermissionLevel) {
      return option.color;
    } else if (option === enabledOption) {
      return `${isNotSelectedStyle} ${option.color} ${PermissionStyles.border}`;
    } else {
      return isNotSelectedStyle;
    }
  };
  const accessText: string = `${text} ${strings.HAS}`;
  return (
    <div className={Styles.headerPermissions}>
      <div title={accessText}>{accessText}</div>
      <div className={Styles.permissionsInfo}>
        <div className={Styles.permission}>
          <Fragment>
            <button
              className={`${Styles.permissionPill} ${computeStyle(
                enabledOption
              )}`}
              disabled={true}
            >
              <div className={Styles.permissionTitle}>
                {Localized.string(enabledOption.title)}
              </div>
            </button>
          </Fragment>
        </div>
        <div>{strings.ACCESS}</div>
      </div>
    </div>
  );
};
