import React, { useEffect, useState } from "react";
import { Localized } from "../../strings";
import { useSelector } from "react-redux";
import { user } from "../../state/user/user.selector";
import {
  getWorkspaceById,
  getWorkspaceThumbnailUrl,
  hasModifiedThumbnail,
} from "../../state/workspaces/workspaces.selector";
import { config } from "../../state/config/config.selector";
import { RootState } from "typesafe-actions";
import Styles from "./UseCaseCard.module.css";

type UseCaseCardProps = {
  useCaseName: string;
  enabled: boolean;
  workspaceId?: string;
  onClick?: () => void;
  imageURL?: string;
  tooltip?: string;
  tooltipPostfix?: string;
  isDefaultModule?: boolean;
};

export const UseCaseCard: React.FC<UseCaseCardProps> = ({
  imageURL,
  useCaseName: moduleName,
  onClick,
  enabled,
  workspaceId,
  tooltip = "",
  tooltipPostfix = "",
  isDefaultModule = false,
}) => {
  const strings = Localized.object("USE_CASE_CARD");
  const additionalProps = isDefaultModule
    ? {
        tabIndex: 0,
        "aria-label": Localized.string(
          "CREATE_WORKSPACES_SECTION.MODULES.BLANK_WORKSPACE"
        ),
      }
    : {};

  const currentUser = useSelector(user);
  const conf = useSelector(config);
  const workspaceDetails = useSelector((state: RootState) =>
    getWorkspaceById(state, workspaceId ?? "")
  );

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(
    undefined
  );
  const ignoreCache = useSelector((state: RootState) =>
    hasModifiedThumbnail(state, workspaceId ?? "")
  );

  useEffect(() => {
    const fetchThumbnail = async () => {
      let url = getWorkspaceThumbnailUrl(workspaceId!, conf);
      if (ignoreCache) {
        url += "?defyCache=" + Date.now();
      }

      return fetch(url, {
        headers: { Authorization: `Bearer ${currentUser.token}` },
      })
        .then((response: Response) => {
          if (response.status === 200) {
            response.blob().then((blob: Blob) => {
              if (blob instanceof Blob) {
                setThumbnailUrl(URL.createObjectURL(blob));
              }
            });
          }
        })
        .catch(() => setThumbnailUrl(undefined));
    };

    if (workspaceDetails?.thumbnailUrl?.startsWith("blob:")) {
      setThumbnailUrl(workspaceDetails.thumbnailUrl);
    } else if (workspaceId) {
      fetchThumbnail();
    }
  }, [workspaceDetails?.thumbnailUrl, workspaceId, ignoreCache]);

  return (
    <div
      onClick={onClick}
      title={tooltip + moduleName + tooltipPostfix}
      className={`${Styles.useCaseCard} ${!enabled ? Styles.disabled : ""}`}
      data-test-id={`create-${moduleName}`}
      {...additionalProps}
    >
      <div className={Styles.content}>
        {imageURL ? (
          <img
            className={Styles.moduleImage}
            src={thumbnailUrl ?? imageURL}
            alt={strings.TILE_IMAGE}
          />
        ) : (
          <div className={Styles.moduleStub}>
            <div className={`${Styles.moduleStubIcon} hoylu-ui-icons-plus`} />
          </div>
        )}
      </div>
      <header className={Styles.moduleDescription}>
        <div className={Styles.moduleName}>{moduleName}</div>
      </header>
    </div>
  );
};
