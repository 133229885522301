import { RootAction } from 'typesafe-actions';
import { Store } from 'redux';
import {
  showDashboard,
  editWorkspace
} from './state/workspaces/workspaces.actions';
import { browserHistory as history } from "./services/dependencies/syncHistory";
import { Update } from "history";

export function historyStateToAction(update: Update): RootAction | undefined {
  if (update.location.state === "DASHBOARD") return showDashboard();
  if (update.location.state) {
    return editWorkspace({
      workspaceId: update.location.state as string,
      lastAccess: new Date(),
    });
  }
  // Note: this only happens in a strange case in Safari, when doing something in pendo portal (feedback) after closing pendo portal and then navigating to dashboard
  return undefined;
}

export function connectHistoryToDispatch (store: Store) {
  history.replace("/", "DASHBOARD");
  history.listen((location) => {
    const action = historyStateToAction(location);
    if (action) {
      store.dispatch(action);
    }
  });
}
