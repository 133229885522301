import { createAsyncAction, createAction } from "typesafe-actions";
import {HostClientType} from "@microsoft/teams-js";

import { FeatureFlags, WebexEmbeddedAppState } from "./config.reducer";
import {TeamsFrameContexts} from "../../utils/detectTeams";
export interface ConfigResponse {
  serviceConfig: {
    documentImages: string;
    documentMetadata: string;
    auth: string;
    login: string;
    logs?: string;
    metrics?: string;
    hoylu_app: string;
    hoylu_appbeta: string;
    licensing: string;
    management_portal: string;
    transfer: string;
    notification: string;
  };
  launchDarkly?: {
    clientId: string;
    betaClientId: string;
  };
  webex?: {
    // clientId for Webex REST API
    clientId: string;
  };
}

export const getConfig = createAsyncAction(
  "CONFIG_REQUEST",
  "CONFIG_SUCCESS",
  "CONFIG_FAILURE"
)<{ configUrl: string }, ConfigResponse, Error>();

export const updateConfig =
  createAction("UPDATE_CONFIG")<{
    isDev: boolean;
    isBeta: boolean;
    workspaceIdeasId?: string;
  }>();

export const teamsClient = createAction("TEAMS_CLIENT")<HostClientType>();
export const teamsFrame = createAction("TEAMS_FRAME")<TeamsFrameContexts>();
export const webexDetected =
  createAction("WEBEX_DETECTED")<WebexEmbeddedAppState>();
export const setWebexShareState = createAction(
  "SET_WEBEX_SHARE_STATE"
)<boolean>();
export const updateVersion = createAction("UPDATE_VERSION")<boolean>();
export const updateFlags = createAction("UPDATE_FEATURE_FLAGS")<FeatureFlags>();
