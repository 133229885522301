import { useDispatch, useSelector } from "react-redux";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "./Modal";
import { Localized } from "../../strings";
import {
  getSelectedWorkspaceDetails,
  uploadedSnapshot,
} from "../../state/workspaces/workspaces.selector";
import {
  cancelDashboardOption,
  editThumbnail,
  uploadThumbnail,
  workspaceSnapshot,
  removeThumbnail,
} from "../../state/workspaces/workspaces.actions";
import SimpleWorkspaceCard from "../cards/SimpleWorkspaceCard";
import {
  Button,
  DraggableWindowHeader,
  Dropdown,
  Icon,
  Option,
} from "@hoylu/client-common";
import { RootState } from "typesafe-actions";
import Styles from "./ThumbnailEditorDialog.module.css";

enum ThumbnailType {
  EMOJI,
  TEMPLATE,
  CUSTOM,
  SNAPSHOT,
}

type ThumbnailSelectorProps = {
  setBlob: Dispatch<SetStateAction<Blob | null>>;
};
const CustomThumbnailSelector = ({ setBlob }: ThumbnailSelectorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFilename] = useState("Drag & Drop");
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const allowedFileSizeMB = 1; // in MB
  const strings = Localized.object("THUMBNAIL_EDITOR");

  const [err, setErr] = useState<string | null>(null);

  const checkAcceptedTypeError = (file?: File): string | null => {
    if (!file || acceptedImageTypes.includes(file.type)) return null;
    return strings.ERR;
  };

  const checkSizeError = (file?: File): string | null => {
    if (!file || allowedFileSizeMB * 1024 * 1024 >= file.size) return null;
    return strings.ERR;
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    const err = checkAcceptedTypeError(image) ?? checkSizeError(image);
    if (image && !err) {
      setErr(null);
      setFilename(image.name);
      setBlob(image);
    } else {
      setErr(err);
      setBlob(null);
    }
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const image = e.dataTransfer.files[0];
    const err = checkAcceptedTypeError(image) ?? checkSizeError(image);
    if (image && !err) {
      setErr(null);
      setFilename(image.name);
      setBlob(image);
    } else {
      setErr(err);
      setBlob(null);
    }
  };

  const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`${Styles.customThumbnailSelector} ${
          err ? Styles.borderAttention : Styles.borderIndicator
        }`}
        onDragOver={(e) => allowDrop(e)}
        onDrop={(e) => onFileDrop(e)}
      >
        <input
          ref={inputRef}
          className={Styles.hidden}
          type="file"
          onChange={onFileChange}
          accept={acceptedImageTypes.join(", ")}
          data-test-id="hidden-input"
        />
        {err && (
          <span className={Styles.error} title={err}>
            {err}
          </span>
        )}
        {!err && (
          <span className={Styles.fileName} title={fileName}>
            {fileName}
          </span>
        )}
        <Button
          type={"primary"}
          toolTip={strings.BROWSE}
          onClick={() => inputRef.current?.click()}
        >
          Browse
        </Button>
      </div>
    </>
  );
};

const SnapshotSelector = () => {
  const dispatch = useDispatch();
  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const strings = Localized.object("THUMBNAIL_EDITOR");

  if (!workspaceDetails) return null;
  return (
    <>
      <Icon icon={"hoylu-ui-icons-camera"} />
      <Button
        type={"primary"}
        toolTip={strings.TAKE_SNAPSHOT}
        onClick={() =>
          dispatch(
            workspaceSnapshot.request({
              workspaceId: workspaceDetails.workspaceId,
            })
          )
        }
      >
        {strings.TAKE_SNAPSHOT}
      </Button>
    </>
  );
};

export const ThumbnailEditorDialog = () => {
  const dispatch = useDispatch();
  const workspaceDetails = useSelector(getSelectedWorkspaceDetails);
  const close = useCallback(
    (closeAll: boolean = false) =>
      dispatch(
        closeAll ? cancelDashboardOption() : editThumbnail({ flag: false })
      ),
    []
  );
  const snapshot = useSelector((state: RootState) =>
    uploadedSnapshot(state, workspaceDetails!.workspaceId)
  );
  const strings = Localized.object("THUMBNAIL_EDITOR");

  const typeOptions = useMemo<Option<ThumbnailType>[]>(() => {
    const options = [
      //TODO: Part of future work
      // {label: "Emoji", value: ThumbnailType.EMOJI},
      // {label: "Template", value: ThumbnailType.TEMPLATE},
      { label: strings.CUSTOM_IMAGE, value: ThumbnailType.CUSTOM },
    ];
    if (workspaceDetails?.isLive) {
      options.push({ label: strings.SNAPSHOT, value: ThumbnailType.SNAPSHOT });
    }
    return options;
  }, [workspaceDetails?.isLive, strings]);

  const [thumbnailType, setThumbnailType] = useState<Option<ThumbnailType>[]>([
    typeOptions[0],
  ]);
  const [thumbnailUrl, setThumbnailUrl] = useState(
    workspaceDetails?.thumbnailUrl
  );
  const [newThumbnailBlob, setNewThumbnailBlob] = useState<Blob | null>(null);

  const blob = useMemo(() => snapshot ?? newThumbnailBlob, [
    snapshot,
    newThumbnailBlob,
  ]);

  useEffect(() => {
    let tmpUri: string;
    if (blob) {
      tmpUri = URL.createObjectURL(blob);
      setThumbnailUrl(tmpUri);
    }
    return () => {
      tmpUri && URL.revokeObjectURL(tmpUri);
    };
  }, [blob]);

  if (!workspaceDetails) return null;
  return (
    <Modal handleOutsideClick={() => close()} allowContentToOverflow={true}>
      <DraggableWindowHeader
        title={`${strings.TITLE}: ${workspaceDetails?.workspaceId}`}
        onClose={() => close(false)}
      />
      <div className={Styles.workspaceCardEditor}>
        <SimpleWorkspaceCard
          workspaceDetails={{ ...workspaceDetails, thumbnailUrl }}
        >
          <span
            className={Styles.truncate}
            title={workspaceDetails.workspaceName}
          >
            {workspaceDetails.workspaceName}
          </span>
        </SimpleWorkspaceCard>
        <div className={Styles.selectors}>
          <Dropdown
            options={typeOptions}
            values={thumbnailType}
            title={strings.TYPE}
            showLabel={true}
            onChange={setThumbnailType}
            allowReset={false}
          />
          {thumbnailType[0].value === ThumbnailType.CUSTOM && (
            <CustomThumbnailSelector setBlob={setNewThumbnailBlob} />
          )}
          {thumbnailType[0].value === ThumbnailType.SNAPSHOT && (
            <SnapshotSelector />
          )}
        </div>
      </div>
      <div className={Styles.footer}>
        <Button
          type={"secondary"}
          toolTip={strings.DELETE}
          onClick={() => {
            dispatch(
              removeThumbnail.request({
                workspaceId: workspaceDetails.workspaceId,
              })
            );
            close(false);
          }}
          classNames={[Styles.marginAutoRight]}
        >
          {strings.DELETE}
        </Button>
        <Button
          type={"secondary"}
          toolTip={strings.CANCEL}
          onClick={() => close()}
        >
          {strings.CANCEL}
        </Button>
        <Button
          type={"primary"}
          toolTip={strings.SAVE}
          onClick={() => {
            blob &&
              dispatch(
                uploadThumbnail.request({
                  workspaceId: workspaceDetails.workspaceId,
                  file: blob,
                })
              );
            close(false);
          }}
          disabled={!blob}
        >
          {strings.SAVE}
        </Button>
      </div>
    </Modal>
  );
};
