import React from "react";
import Styles from "./LdsRoller.module.css";

export const LdsRoller = () => {
  return (
    <div className={Styles.ldsRoller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
