import React from "react";
import { PermissionIcon } from "./PermissionIcon";

export type UsersIconProperties = {
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
};

export const UsersIcon: React.FC<UsersIconProperties> = ({
  isActive,
  toggleActive,
}) => {
  return (
    <PermissionIcon
      icon={"hoylu-ui-icons-users"}
      isActive={isActive}
      toggleActive={toggleActive}
    />
  );
};
