import React, { Dispatch, RefObject, SetStateAction, useMemo } from "react";
import { hiconToHoyluUiIconsCentered } from "../../../utils/icon.map";
import { FrameContexts } from "@microsoft/teams-js";
import { Project, WorkspaceDetails } from "../../../state/workspaces/types";
import { Localized } from "../../../strings";
import { ConfigState } from "../../../state/config/config.reducer";
import { ProjectStatuses } from "../../../state/workspaces/workspaces.reducer";
import { DateTime } from "luxon";
import Styles from "./WorkspaceCardThumbnail.module.css";

type UpdatedWorkspaceNameParams = {
  workspaceId: string;
  name: string;
};

type WorkspaceCardThumbnailProps = {
  config: ConfigState;
  isEditingWorkspaceName: boolean;
  isLimited: boolean;
  isOptionsMenuOpen: boolean;
  openMoreOptions: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  outsideElementRef: RefObject<HTMLDivElement>;
  project: Project | undefined;
  showWorkspaceModifiedNotification: boolean;
  toggleEditingName: Dispatch<SetStateAction<boolean>>;
  updateWorkspaceName: ({
    workspaceId,
    name,
  }: UpdatedWorkspaceNameParams) => void;
  workspaceDetails: WorkspaceDetails;
};

const WorkspaceCardThumbnail = ({
  config,
  isEditingWorkspaceName,
  isLimited,
  isOptionsMenuOpen,
  openMoreOptions,
  outsideElementRef,
  project,
  showWorkspaceModifiedNotification,
  toggleEditingName,
  updateWorkspaceName,
  workspaceDetails,
}: WorkspaceCardThumbnailProps) => {
  const strings = Localized.object("WORKSPACE_CARD");
  const isTemplate = !!workspaceDetails.templateId;
  const isWorkspaceHidden = workspaceDetails.isHidden;
  const workspaceName = workspaceDetails.workspaceName || strings.UNTITLED;

  const editName = (value: string) => {
    if (value !== workspaceDetails.workspaceName) {
      updateWorkspaceName({
        workspaceId: workspaceDetails.workspaceId,
        name: value,
      });
    }
  };

  let lastAccessed = workspaceDetails.lastAccess
    ? DateTime.fromJSDate(workspaceDetails.lastAccess).toLocaleString(
        DateTime.DATE_MED
      )
    : strings.NOT_YET;

  // WorkspaceCard title and footer styles
  const [
    displayedWorkspaceName,
    workspaceThumbnailFooterBg,
    workspaceSubtitle,
    color,
  ] = useMemo(() => {
    // ws under credit limit
    let name = workspaceName;
    let backGround = Styles.defaultThumbnail;
    let subtitle = `${strings.ACCESSED} ${lastAccessed}`;
    let color = "";

    if (project?.status === ProjectStatuses.Inactive) {
      // rules only for ws assigned to the inactive project
      name = `${Localized.string("FILTER_BY_NAME.INACTIVE")}: ${workspaceName}`;
      backGround = Styles.inactiveThumbnail;
    } else if (isLimited) {
      // rules only for ws (exclude project ws) when tonnage is exceeded
      name = `${strings.LIMITED_TITLE.toUpperCase()}: ${workspaceName}`;
      backGround = Styles.limitedThumbnail;
    } else if (isWorkspaceHidden) {
      subtitle = strings.HIDDEN_WORKSPACE;
      color = Styles.hidden;
    }

    return [name, backGround, subtitle, color];
  }, [
    project?.status,
    workspaceDetails.workspaceName,
    workspaceDetails.myPermissions?.canGrow,
    isWorkspaceHidden,
  ]);

  return (
    <div
      className={`${color} ${Styles.workspaceCardThumbnail} ${
        isTemplate ? Styles.template : workspaceThumbnailFooterBg
      }`}
    >
      <div className={Styles.workspaceInfo}>
        <div className={Styles.workspaceInfoItems}>
          <div className={Styles.workspaceNameEditorContainer}>
            {isEditingWorkspaceName ? (
              <textarea
                className={`${Styles.workspaceNameEditor} ${
                  isTemplate ? Styles.template : Styles.default
                }`}
                autoFocus
                defaultValue={workspaceDetails.workspaceName}
                // putting cursor to the end of input box
                onFocus={(e) => {
                  let temp = e.target.value;
                  e.target.value = "";
                  e.target.value = temp;
                }}
                onBlur={(e) => {
                  editName(e.currentTarget.value);
                  toggleEditingName(false);
                }}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    editName(e.currentTarget.value);
                    toggleEditingName(false);
                  }
                }}
              />
            ) : (
              <>
                {isWorkspaceHidden && (
                  <div
                    style={{ color }}
                    className={`hoylu-ui-icons-indicator-hide ${Styles.icon}`}
                  />
                )}
                {project && (
                  <div
                    style={{ color: project.color }}
                    className={`${hiconToHoyluUiIconsCentered(project.icon)} ${
                      Styles.icon
                    }`}
                  />
                )}
                <h1 className={Styles.workspaceName}>
                  {displayedWorkspaceName}
                </h1>
              </>
            )}
          </div>
          <div className={Styles.subtitleContainer}>
            {isTemplate ? (
              <span>{strings.TEMPLATE}</span>
            ) : (
              <div className={Styles.workspaceSubtitleContainer}>
                <p
                  className={Styles.workspaceSubtitle}
                  title={workspaceSubtitle}
                >
                  {workspaceSubtitle}
                </p>
                {showWorkspaceModifiedNotification && (
                  <div className={Styles.workspaceModifiedNotification} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.meatballsMenuContainer}>
        <div ref={outsideElementRef}>
          <button
            className={Styles.meatballsMenu}
            aria-label={workspaceDetails.workspaceName}
            title={strings.MORE_OPTIONS}
            onClick={openMoreOptions}
            data-test-id="workspace-options"
            disabled={config.teamsFrame === FrameContexts.sidePanel}
          >
            <svg
              fill={isOptionsMenuOpen ? "#AECBFA" : "#716C83"}
              width="18px"
              height="6px"
              viewBox="0 0 22 6"
            >
              <path
                d="M5.24,4.248 C5.24,4.952 4.664,5.496 3.992,5.496 L1.464,5.496 C0.76,5.496 0.216,4.92 0.216,4.248 L0.216,1.72 C0.216,1.016 0.792,0.472 1.464,0.472 L3.96,0.472 C4.664,0.472 5.208,1.048 5.208,1.72 L5.208,4.248 L5.24,4.248 Z"
                id="Path"
              ></path>
              <path
                d="M13.528,4.248 C13.528,4.952 12.952,5.496 12.28,5.496 L9.752,5.496 C9.048,5.496 8.504,4.92 8.504,4.248 L8.504,1.72 C8.504,1.016 9.08,0.472 9.752,0.472 L12.28,0.472 C12.984,0.472 13.528,1.048 13.528,1.72 L13.528,4.248 Z"
                id="Path"
              ></path>
              <path
                d="M21.816,4.248 C21.816,4.952 21.24,5.496 20.568,5.496 L18.04,5.496 C17.336,5.496 16.792,4.92 16.792,4.248 L16.792,1.72 C16.792,1.016 17.368,0.472 18.04,0.472 L20.568,0.472 C21.272,0.472 21.816,1.048 21.816,1.72 L21.816,4.248 Z"
                id="Path"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceCardThumbnail;
