import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Localized } from "../strings";
import { defaultDashboardPageSize } from "../utils/defaultDashboardPageSize";
import { loadMoreWorkspaces } from "../state/workspaces/workspaces.actions";
import { WorkspaceDetails } from "../state/workspaces/types";
import { Button } from "@hoylu/client-common";
import Styles from "./LoadMoreWorkspacesButton.module.css";

export const LoadMoreWorkspacesButton: React.FC = () => {
  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const selectedProjectWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.selectedProjectWorkspaces
  );
  const isLoadingSelectedProjectWorkspaces = useSelector(
    (state: RootState) =>
      state.context.workspaces.isLoadingSelectedProjectWorkspaces
  );
  const cursorOrOffset = useSelector(
    (state: RootState) => state.context.workspaces.cursorOrOffset
  );
  const dispatch = useDispatch();

  const showLoadMoreButton = (
    hasPopulatedAllWorkspaces: boolean,
    cursorOrOffset: string | number,
    selectedProjectWorkspaces: WorkspaceDetails[] | undefined
  ): boolean => {
    return !(
      hasPopulatedAllWorkspaces ||
      cursorOrOffset === "" ||
      selectedProjectWorkspaces != undefined ||
      isLoadingSelectedProjectWorkspaces
    );
  };

  const strings = Localized.object("LOAD_MORE_WORKSPACES");
  return (
    <React.Fragment>
      {showLoadMoreButton(
        hasPopulatedAllWorkspaces,
        cursorOrOffset,
        selectedProjectWorkspaces
      ) && (
        <div className={Styles.loadMoreWorkspaces}>
          <Button
            type="primary"
            onClick={() =>
              dispatch(
                loadMoreWorkspaces.request({
                  limit: defaultDashboardPageSize,
                  cursorOrOffset: cursorOrOffset,
                })
              )
            }
          >
            {strings.LOAD_MORE}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
