import React, { useMemo } from "react";
import {
  Label,
  Project,
  WorkspaceDetails,
} from "../../../state/workspaces/types";
import WorkspaceLabel from "./WorkspaceLabel";
import ProjectLabel from "./ProjectLabel";
import {
  createSelectProjectByIdFillingUnknownOne,
  UNKNOWN_PROJECT_LABEL,
} from "../../../state/workspaces/projects.selector";
import { ProjectStatuses } from "../../../state/workspaces/workspaces.reducer";
import { Localized } from "../../../strings";
import { useDispatch, useSelector } from "react-redux";
import {
  isWorkspaceLimited,
  isWorkspaceReadOnly,
} from "../../../state/workspaces/workspaces.selector";
import { RootState } from "typesafe-actions";
import Styles from "./WorkspacePreviewLabels.module.css";
import { selectProject } from "../../../state/workspaces/workspaces.actions";

type WorkspacePreviewLabelsProps = {
  canEditLabels: boolean;
  showEditLabelPrompt: () => void;
  workspaceDetails: WorkspaceDetails;
};

const WorkspacePreviewLabels = ({
  canEditLabels,
  showEditLabelPrompt,
  workspaceDetails,
}: WorkspacePreviewLabelsProps): JSX.Element => {
  const dispatch = useDispatch();
  const limitedLabelName = Localized.string("WORKSPACE_CARD.LIMITED_TITLE");
  const readOnlyLabelName = Localized.string("APP.READ_ONLY");
  const isLimited = useSelector((state: RootState) =>
    isWorkspaceLimited(state, workspaceDetails.workspaceId)
  );
  const isReadOnly = useSelector((state: RootState) =>
    isWorkspaceReadOnly(state, workspaceDetails.workspaceId)
  );
  const labels = workspaceDetails.labels ?? [];

  const selectProjectByIdFillingUnknownOne = useMemo(
    () => createSelectProjectByIdFillingUnknownOne(),
    []
  );
  const project = useSelector((state: RootState) =>
    selectProjectByIdFillingUnknownOne(state, workspaceDetails.containerId)
  );

  /*
  No Limited Label for project workspaces and templates. Project ws are either Active or Inactive.
  If Project is Expired (equal to Inactive) we set `canGrow` permission to `false`,
  however do nothing with styling with this WorkspaceCard.
  */
  const processedLabels = useMemo(() => {
    if (workspaceDetails.templateId) {
      return labels;
    }

    const additionalLabel =
      isLimited && !project
        ? { name: limitedLabelName }
        : isReadOnly
        ? { name: readOnlyLabelName }
        : null;
    return additionalLabel ? [...labels, additionalLabel] : labels;
  }, [isLimited, isReadOnly, project, labels, workspaceDetails.templateId]);

  const onProjectClick = (e: React.MouseEvent, project: Project) => {
    if (project.name === UNKNOWN_PROJECT_LABEL) return;

    if (project.status === ProjectStatuses.Inactive) {
      window.open("https://www.hoylu.com/projects", "_blank");
      e.stopPropagation();
      return;
    }

    if (canEditLabels) {
      e.stopPropagation();
      dispatch(selectProject(project));
    }
  };
  const onLabelClick = (
    e: React.MouseEvent,
    isWarningLabel: boolean = false
  ) => {
    if (isWarningLabel) {
      window.open("https://www.hoylu.com/plans", "_blank");
      e.stopPropagation();
      return;
    }

    if (canEditLabels) {
      e.stopPropagation();
      showEditLabelPrompt();
    }
  };

  return (
    <div className={Styles.workspacePreviewLabels}>
      {processedLabels.map((label: Label) => (
        <WorkspaceLabel
          onClick={onLabelClick}
          key={label.name}
          label={label}
          isLimited={isLimited && label.name === limitedLabelName}
          isReadOnly={isReadOnly && label.name === readOnlyLabelName}
        />
      ))}
      {project && (
        <ProjectLabel
          onClick={onProjectClick}
          key={project.name}
          project={project}
        />
      )}
    </div>
  );
};

export default WorkspacePreviewLabels;
