import { useClickOutside, useCombinedRefs } from "@hoylu/client-common";
import { NotificationCenter } from "@hoylu/notifications";
import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { sendMessageToWorkspace } from "../../post-message-dispatch";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import {
  getDialogPosition,
  getSelectedWorkspaceId,
} from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { generateDeepLink } from "../../utils/url.helpers";
import { useDashboardTheme } from "../../utils/hooks/useDashboardTheme";

const mapStateToProps = (state: RootState) => ({
  currentlyOpenedWorkspaceId: getSelectedWorkspaceId(state),
  position: getDialogPosition(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type NotificationCenterDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const NotificationCenterDialog: FC<NotificationCenterDialogProps> = ({
  position,
  currentlyOpenedWorkspaceId,
  cancelDashboardOption,
}) => {
  const strings = Localized.object("NOTIFICATIONS");
  const targetRef = useRef<HTMLDivElement>(null);

  const ref = useCombinedRefs(
    targetRef,
    useClickOutside(() => cancelDashboardOption())
  );
  const theme = useDashboardTheme();

  const [positionStyle, setPositionStyle] = useState<"fixed" | "absolute">(
    "fixed"
  );

  const navigateToElement = (triggerId: string) => {
    const [workspaceId, elementId] = triggerId.split("_");
    if (workspaceId === currentlyOpenedWorkspaceId) {
      sendMessageToWorkspace(workspaceId, {
        action: "NAVIGATE_TO_ELEMENT",
        elementId,
      });
    } else {
      const deepLink = generateDeepLink("element", workspaceId, elementId);
      if (deepLink) window.open(deepLink, workspaceId);
    }
  };

  useEffect(() => {
    const updatePositionStyle = () => {
      if (window.innerWidth < 1024 && positionStyle !== "absolute") {
        setPositionStyle("absolute");
      } else if (window.innerWidth >= 1024 && positionStyle !== "fixed") {
        setPositionStyle("fixed");
      }
    };

    window.addEventListener("resize", updatePositionStyle);

    return () => {
      window.removeEventListener("resize", updatePositionStyle);
    };
  }, [positionStyle]);

  /* In web-suite we need to focus on NotificationCenter in order to close it via
     Escape key and prevent catching by ToolMenu
  */
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.focus();
    }
  }, [targetRef]);

  useEffect(() => {
    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.keyCode === 27) {
        cancelDashboardOption();
      }
    };

    window.addEventListener("keydown", closeOnEsc);

    return () => {
      window.removeEventListener("keydown", closeOnEsc);
    };
  }, [ref]);

  return (
    <NotificationCenter
      ref={ref}
      initialPosition={position}
      workspaceId={currentlyOpenedWorkspaceId}
      navigateToElement={navigateToElement}
      onClose={cancelDashboardOption}
      notificationIconStyles={{ lineHeight: 1 }}
      styles={{
        zIndex: "var(--modal-position)",
        maxHeight: "50vh",
        position: positionStyle,
      }}
      classNames={[theme]}
      locales={{
        TITLE: strings.TITLE,
        NAVIGATE_TO_ELEMENT: strings.NAVIGATE_TO_ELEMENT,
        CLOSE_BUTTON: strings.CLOSE_BUTTON,
        SHOW_ALL_MESSAGES: strings.SHOW_ALL_MESSAGES,
        HIDE_READ: strings.HIDE_READ,
        NOTIFICATION_BADGE: strings.NOTIFICATION_BADGE,
        UNSUBSCRIBE: strings.UNSUBSCRIBE,
        SUBSCRIBE: strings.SUBSCRIBE,
        NO_MORE_MESSAGES: strings.NO_MORE_MESSAGES,
        MARK_ALL_AS_READ: strings.MARK_ALL_AS_READ,
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenterDialog);
