import React from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { Localized } from "../strings";
import { CenteredContentWithLogo } from "./layouts/CenteredContentWithLogo";
import Styles from "./BetaDisabledContent.module.css";

const mapStateToProps = (state: RootState) => ({
  appUrl: state.context.config.serviceConfig.hoylu_app,
});

type Props = ReturnType<typeof mapStateToProps>;
export const BetaDisabledContent: React.FC<Props> = ({ appUrl }) => {
  const strings = Localized.object("BETA_DISABLED_CONTENT");

  return (
    <CenteredContentWithLogo>
      <h1 className={Styles.header}>
        {strings.THANK_YOU}
        <br></br> {strings.BETA_PERIOD_ENDED}
      </h1>
      <div className={Styles.linkContainer}>
        <a href={appUrl} type="submit" className={Styles.link}>
          <p className={Styles.goBackToHoylu}>{strings.TAKE_ME}</p>
        </a>
      </div>
    </CenteredContentWithLogo>
  );
};

export default connect(mapStateToProps)(BetaDisabledContent);
