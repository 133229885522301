import {RootState} from "typesafe-actions";
import {TemplateDetails, TemplateCategoryState, TemplateMetadata} from "./types";
import {QUICK_ACCESS_CATEGORY_ID} from "../../services/templates/types";

export const templateCategories = (
  state: RootState
): TemplateCategoryState[] => {
  return state.context.templates.categories;
};

export const quickAccessTemplates = (
  state: RootState
): TemplateMetadata[] => {
  const quickAccessCategory = state.context.templates.categories.find((c) => c.categoryId === QUICK_ACCESS_CATEGORY_ID);

  return quickAccessCategory?.templates ?? [];
};

export const getSelectedTemplateDetails = (
  state: RootState,
  templateId: string
): TemplateDetails | undefined => {
  return state.context.templates.templates.find((t) => t.templateId === templateId);
};

export const isUpdatedTemplate = (
  state: RootState
): boolean => !!state.context.templates.isUpdated;

export const isLoadingTemplate = (
  state: RootState
): boolean => !!state.context.templates.isFetchingTemplateDetails;