import React from "react";
import { connect } from "react-redux";
import { CenteredContentWithLogo } from "./layouts/CenteredContentWithLogo";
import {
  sendValidationEmail,
  skipValidationEmail,
} from "../state/user/user.actions";
import { Localized } from "../strings";
import { RootState } from "typesafe-actions";
import { AppMode } from "../state/mode/mode.reducer";
import Styles from "./AccountVerificationError.module.css";
import { Button } from "@hoylu/client-common";

const mapDispatchToProps = {
  sendValidationEmailAction: sendValidationEmail.request,
  skip: skipValidationEmail.request,
};

const mapStateToProps = (state: RootState) => ({
  skippable: AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED === state.mode,
});

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

export const AccountVerificationError: React.FC<Props> = ({
  sendValidationEmailAction,
  skip,
  skippable = false,
}) => {
  const strings = Localized.object("ACCOUNT_VERIFICATION_ERROR");

  return (
    <CenteredContentWithLogo>
      <div className={Styles.verifyHeader}>
        <p>{strings.WE_NOTICED}</p>
        <p>{strings.WITHOUT_VERIFICATION}</p>
      </div>
      <div className={Styles.buttons}>
        <Button type={"negative"} onClick={sendValidationEmailAction}>
          {strings.VERIFIY_EMAIL}
        </Button>
        {skippable && (
          <Button type={"negative"} onClick={skip}>
            {strings.SKIP}
          </Button>
        )}
      </div>
    </CenteredContentWithLogo>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountVerificationError);
