import { Localized } from "../strings";

export const textInputLocalization = () => {
  const inputStrings = Localized.object("TEXT_INPUT");

  return {
    PASSWORD_VISIBILITY: inputStrings.TOGGLE_PASSWORD_VISIBILITY,
    CLEAR: inputStrings.CLEAR,
  };
};
