import { Button } from "@hoylu/client-common";
import React from "react";
import { useDispatch } from "react-redux";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { activateDashboardOption } from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";
import Styles from "./HeaderButtons.module.css";

type HeaderButtonsProps = {
  showSearch: boolean;
  handleSearchClick: () => void;
};

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  showSearch,
  handleSearchClick,
}) => {
  const dispatch = useDispatch();

  const openStr = Localized.object("SHARED_WORKSPACE");
  const inviteStr = Localized.object("INVITE_USERS");

  const invite = () =>
    dispatch(
      activateDashboardOption({
        optionType: DashboardOption.INVITE,
      })
    );

  return (
    <section className={Styles.buttonsContainer} tabIndex={0}>
      {showSearch && (
        <Button
          dataTestId={"Open shared workspace"}
          type={"blackAndWhite"}
          toolTip={openStr.OPEN_SHARED_WORKSPACE}
          onClick={handleSearchClick}
          classNames={[Styles.headerButton]}
        >
          {openStr.OPEN}
        </Button>
      )}
      <Button
        dataTestId={"Invite users"}
        type={"blackAndWhite"}
        toolTip={openStr.INVITE_USER}
        onClick={invite}
        classNames={[Styles.headerButton]}
      >
        {inviteStr.INVITE}
      </Button>
    </section>
  );
};

export default HeaderButtons;
