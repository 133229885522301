import { FormEvent } from "react";
import { WorkspaceDetails } from "../state/workspaces/types";
 
export function deleteWithPassword (
  e: FormEvent, 
  deleteWorkspace: ( workspace: {details: WorkspaceDetails, password: string}) => void, 
  selectedWorkspaceDetails?: WorkspaceDetails, 
){
  e.preventDefault();
  let password = null;

  if (selectedWorkspaceDetails?.hasPassword) {
    const form = new FormData(e.target as HTMLFormElement);
    password = form.get('password')?.toString();
  }
  
  deleteWorkspace({
    details: selectedWorkspaceDetails!,
    password: password || ''
  });
};
