import Styles from "./SearchInput.module.css";
import { populateAllWorkspaces } from "../../state/workspaces/workspaces.actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Localized } from "../../strings";
import { TextInput } from "@hoylu/client-common";
import { textInputLocalization } from "../../utils/text.input.localization";

type SearchInputProps = {
  searchTerm: string;
  handleChange: (value: string) => void;
  responsive?: boolean;
};

export const SearchInput = ({
  searchTerm,
  handleChange,
  responsive = false,
}: SearchInputProps) => {
  const strings = Localized.object("FILTER_BY_NAME");
  const isLoadingAll = useSelector(
    (state: RootState) => state.context.workspaces.isLoadingAll
  );
  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const dispatch = useDispatch();

  return (
    <div
      className={`${Styles.searchInput} ${responsive ? Styles.responsive : ""}`}
      onMouseOver={() => {
        if (!hasPopulatedAllWorkspaces) {
          dispatch(populateAllWorkspaces.request());
        }
      }}
    >
      <TextInput
        label={""}
        value={searchTerm}
        onChange={(value) => handleChange(value)}
        watermark={isLoadingAll ? strings.LOADING_WORKSPACES : strings.SEARCH}
        disabled={isLoadingAll}
        useMagnifier={true}
        classNames={[Styles.input]}
        useClear={true}
        localizationDict={textInputLocalization()}
      />
    </div>
  );
};
