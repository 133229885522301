import React from "react";
import Styles from "./IconsShared.module.css";
import { sendEmail } from "../../../../utils/sharing.helpers";
import { Icon } from "@hoylu/client-common";

export type EmailIconProps = {
  shareLink: string;
  workspaceName?: string;
};

export const EmailIcon: React.FC<EmailIconProps> = ({
  shareLink,
  workspaceName,
}) => {
  return (
    <div className={Styles.rightSpacing}>
      <Icon
        icon={"hoylu-ui-icons-envelope"}
        onClick={() => sendEmail(workspaceName ?? "", shareLink)}
      />
    </div>
  );
};
