import React, { useState, useEffect } from "react";
import { ShareOptionValue, ShareType } from "./ShareType";
import { SmallRippleSpinner } from "../../RippleSpinner";
import { Localized } from "../../../strings";
import { CloseIcon } from "../modalElements/CloseIcon";
import Styles from "./PermissionsBar.module.css";
import PermissionStyles from "./ShareType.module.css";

type PermissionsBar = {
  commitUpdate: (shareOption: ShareOptionValue) => void;
  enabledOption: ShareOptionValue;
  options: ShareOptionValue[];
  isUpdating?: boolean;
  text: string;
  currentUser?: string;
  publicPermissionLevel?: ShareOptionValue;
  isAdmin: boolean;
};

export const PermissionsBar: React.FC<PermissionsBar> = ({
  commitUpdate,
  enabledOption,
  options,
  isUpdating,
  text,
  currentUser,
  publicPermissionLevel,
  isAdmin,
}) => {
  const strings = Localized.object("PERMISSION_BAR");
  const [showLoadingSpinner, toggleLoadingSpinner] = useState(false);
  const setAccessOption = (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ): void => {
    if (option !== enabledOption && currentUser !== text) {
      if (currentUser) {
        toggleLoadingSpinner(true);
      }
      commitUpdate(option);
    }
  };

  useEffect(() => {
    if (!isUpdating) {
      toggleLoadingSpinner(false);
    }
  }, [isUpdating]);

  const computeStyle = (option: ShareOptionValue, isAdmin: boolean) => {
    const pill: string = Styles.permissionPill;
    const selectedPill: string = `${pill} ${Styles.selected}`;
    const enabledPill: string = `${pill} ${Styles.enabled}`;
    const disabledPill: string = `${pill} ${Styles.disabled}`;
    const notSelectedStyle: string = isAdmin ? enabledPill : disabledPill;

    if (
      !publicPermissionLevel ||
      enabledOption.value >= publicPermissionLevel.value
    ) {
      if (option === enabledOption) {
        return `${selectedPill} ${option.color}`;
      } else {
        return notSelectedStyle;
      }
    }

    if (option === publicPermissionLevel) {
      return `${selectedPill} ${option.color}`;
    } else if (option === enabledOption) {
      return `${notSelectedStyle} ${option.color} ${PermissionStyles.border}`;
    } else {
      return notSelectedStyle;
    }
  };

  const shouldRemoveBeDisplayed =
    text.indexOf(" ") === -1 && isAdmin && currentUser !== text;

  // TODO: Add tooltip for org permission once it is available
  const isOrgOnly = false;
  const permissionTooltip = !isOrgOnly
    ? text
    : publicPermissionLevel?.value !== 0
    ? strings.AUTHENTICATED_ACCOUNTS_READ_ONLY_ACCESS
    : strings.AUTHENTICATED_ACCOUNTS_NO_ACCESS;

  return (
    <div className={Styles.permissionBar}>
      <div title={permissionTooltip} className={Styles.permissionDescription}>
        {text}
      </div>
      <div className={Styles.permissions}>
        {showLoadingSpinner && (
          <span className={Styles.spinnerContainer}>
            <SmallRippleSpinner />
          </span>
        )}
        {currentUser && <div>{strings.HAS}</div>}
        <div
          className={Styles.permissionOptions}
          title={strings.ONLY_ADMINS_CHANGE_ACCESS}
        >
          {options.map((option, index) => {
            return (
              <PermissionOption
                key={index}
                isAdmin={isAdmin}
                index={index}
                option={option}
                enabledOption={enabledOption}
                setAccessOption={setAccessOption}
                computeStyle={computeStyle}
              />
            );
          })}
        </div>
        <div
          className={`${
            shouldRemoveBeDisplayed
              ? Styles.removeIconSpacing
              : Styles.noRemoveSpacing
          }`}
        >
          {strings.ACCESS}
        </div>
        {shouldRemoveBeDisplayed && (
          <CloseIcon
            onClose={() => setAccessOption(ShareType.REMOVE, enabledOption)}
            title={strings.REMOVE_USER}
          />
        )}
      </div>
    </div>
  );
};

type PermissionOptionProperties = {
  isAdmin: boolean;
  index: number;
  option: ShareOptionValue;
  enabledOption: ShareOptionValue;
  setAccessOption: (
    option: ShareOptionValue,
    enabledOption: ShareOptionValue
  ) => void;
  computeStyle: (
    option: ShareOptionValue,
    isAdmin: boolean
  ) => string | undefined;
};

const PermissionOption: React.FC<PermissionOptionProperties> = ({
  isAdmin,
  index,
  option,
  enabledOption,
  setAccessOption,
  computeStyle,
}) => {
  const connectorColor = isAdmin ? Styles.active : Styles.inactive;

  return (
    <>
      {index !== 0 && (
        <svg
          className={`${connectorColor} ${Styles.optionConnector}`}
          width="10"
          height="5"
        >
          <rect width={10} height={5} />
        </svg>
      )}
      <button
        data-test-id={option.title}
        className={computeStyle(option, isAdmin)}
        disabled={!isAdmin}
        onClick={() => {
          if (!isAdmin) return;
          setAccessOption(option, enabledOption);
        }}
      >
        <div className={Styles.permissionPillTitle}>
          {Localized.string(option.title)}
        </div>
      </button>
    </>
  );
};
