import {log} from '@hoylu/client-common';
import * as microsoftTeams from '@microsoft/teams-js';
import {FrameContexts} from '@microsoft/teams-js';

import {Store} from 'redux';
import {teamsClient, teamsFrame} from '../state/config/config.actions';
import {readEmbeddedAppMode, storeEmbeddedAppMode, storeTeamsMetadata} from "./embedded.app.mode";
import {isTeamsModeUrl} from "./url.helpers";
import debug from "debug";

const _LOG = debug("hoylu:teams-detect")

export enum CustomFrameContext {
  mainwindow = "mainwindow"
}

export type TeamsFrameContexts = FrameContexts | CustomFrameContext;

export function detectTeams(store: Store) {
  if (
    isTeamsModeUrl(new URL(window.location.href)) ||
    readEmbeddedAppMode() === "teams" // reloads of the app in ms teamsClient sometimes seem to strip the teamsClient mode url parameter - so restore it from session storage
  ) {
    storeEmbeddedAppMode("teams");
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        _LOG(context)
        log.featureUsed("teams-mode", "dashboard");
        store.dispatch(teamsClient(context.hostClientType!));
        const frameContext = (!context.chatId && !context.groupId) && context.frameContext === FrameContexts.content ? CustomFrameContext.mainwindow: context.frameContext
        store.dispatch(teamsFrame(frameContext!));
        storeTeamsMetadata(context.hostClientType!, frameContext!);
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();
      });
    });
  }
}
