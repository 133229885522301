import { decodeToken } from "../../utils/credential.helpers";
import { log } from "@hoylu/client-common";

// Test: empty, non-existing, incorrectly formatted -> should all be undefined
function readHubspotUserTokenFromCookie(cookies: string) {
  return cookies
    .split("; ")
    .find((s) => s.startsWith("hubspotutk="))
    ?.split("=")[1];
}

export async function hubspotTryTrackUserRegistration(token: string) {
  try {
    // do only execute this on a sub-domain of .hoylu.com, because this is where we have a hubspot cookie (needs to visit hoylu.com first to get one)
    // and this should also prevent submission of user sign-ups from staging/dev envs
    if (!window.location.hostname.endsWith(".hoylu.com")) return;

    const hoyluUser = decodeToken(token);
    // no need to trigger hubspot if the user data is not there
    if (!hoyluUser.firstName || !hoyluUser.lastName || !hoyluUser.email) return;

    const hsUserToken = readHubspotUserTokenFromCookie(document.cookie);

    const hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: hoyluUser.firstName,
        },
        {
          name: "lastname",
          value: hoyluUser.lastName,
        },
        {
          name: "email",
          value: hoyluUser.email,
        },
      ],
      context: {
        hutk: hsUserToken,
        pageUri: window.location.href,
        pageName: document.title,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true, // Note: There is a button in the sign-up that is required to be checked
          text: "I agree to Hoylu's Terms and Privacy Policy.",
          // Note: We had that on our website, but this is not present in the app sign-up anymore
          //       Kept that here for reference, if we want to add that again
          // communications: [
          //   {
          //     value: jQuery('[name="hoyluNews"]:checked').length > 0,
          //     subscriptionTypeId: 7002224,
          //     text: "I want to receive news and updates from Hoylu.",
          //   },
          // ],
        },
      },
    };

    await fetch(
      // TODO: This URL should eventually be configured somewhere, especially our id & the form id
      "https://api.hsforms.com/submissions/v3/integration/submit/6070055/10055737-8274-4a47-901b-83a7151fef8d",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(hubspotData),
      }
    );
  } catch (e: any) {
    // this should never fail anything, but log the error
    log.info("Failed to send data to hubspot", e);
  }
}
