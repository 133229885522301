import React, { useMemo } from "react";
import { Label } from "../../../state/workspaces/types";
import Styles from "./WorkspaceLabel.module.css";

type WorkspaceLabelProps = {
  label: Label;
  onClick: (e: React.MouseEvent, isWarningLabel: boolean) => void;
  isLimited: boolean;
  isReadOnly: boolean;
};

const WorkspaceLabel = ({
  label,
  onClick,
  isLimited,
  isReadOnly,
}: WorkspaceLabelProps): JSX.Element => {
  const [labelIcon, labelBg] = useMemo(() => {
    let icon = null;
    let bg = Styles.accent;

    if (isLimited) {
      icon = <div className="hoylu-ui-icons-warning" />;
      bg = Styles.limited;
    }

    if (isReadOnly) {
      icon = <div className="hoylu-ui-icons-edit-not-allowed" />;
      bg = Styles.readonly;
    }

    return [icon, bg];
  }, [isLimited, isReadOnly]);

  return (
    <div
      onClick={(e) => onClick(e, isLimited)}
      title={label.name}
      className={`${labelBg} ${Styles.workspaceLabel}`}
    >
      {labelIcon}
      <div className={Styles.labelName}>{label.name}</div>
    </div>
  );
};

export default WorkspaceLabel;
