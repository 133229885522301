import React from "react";
import { workspaceDefaultImage } from "../../../utils/defaultImages";
import WorkspaceImagePreview from "./WorkspaceImagePreview";
import { CustomWorkspacePreviewProps } from "./CustomWorkspacePreview";

const DefaultWorkspacePreview: React.FC<Omit<
  CustomWorkspacePreviewProps,
  "token"
>> = ({
  workspaceDetails,
  canEditLabels,
  showEditLabelPrompt,
  setWorkspaceFavorite,
  showWorkspaceWeight,
}) => (
  <WorkspaceImagePreview
    imgSrc={workspaceDefaultImage(
      workspaceDetails.workspaceType,
      workspaceDetails.module?.name
    )}
    setWorkspaceFavorite={setWorkspaceFavorite}
    canEditLabels={!!canEditLabels}
    showEditLabelPrompt={showEditLabelPrompt}
    showWorkspaceWeight={showWorkspaceWeight}
    workspaceDetails={workspaceDetails}
  />
);

export default DefaultWorkspacePreview;
