import { createSelector } from "reselect";
import { managementPortal } from "../../state/config/config.selector";
import { getSelectedProject } from "../../state/workspaces/projects.selector";
import { RootState } from "typesafe-actions";

export const managementPortalUserProfileUrl: (
  state: RootState
) => string = createSelector(
  managementPortal,
  (managementPortalUrl): string => {
    return `${managementPortalUrl}/u`;
  }
);

export const managementPortalBillingUrl: (
  state: RootState
) => string = createSelector(
  managementPortalUserProfileUrl,
  (profileLink): string => {
    return `${profileLink}/billing`;
  }
);

export const managementPortalProjectUrl: (
  state: RootState
) => string | undefined = createSelector(
  managementPortalUserProfileUrl,
  getSelectedProject,
  (managementPortalUrl, project): string | undefined => {
    if (!project?.id) return undefined;
    return `${managementPortalUrl}/projects/${project.id}`;
  }
);
