export type WorkspaceTypeName = 'HOYLU' | 'SKETCH' | 'FLOW' | 'EXPERIMENTAL';

export enum WorkspaceType {
  HOYLU = "HOYLU",
  SKETCH = "SKETCH",
  FLOW = "FLOW",
  EXPERIMENTAL = "EXPERIMENTAL",
  DELETED = "DELETED",
}

// TODO: this list should come from services
export const demoWorkspaces = [
  '608940606',
  '322708322',
  '868814547',
  '373507703',
  '605678673',
  '401362486',
  '379815878',
  '406085457',
  '868814547',
  '427830538',
  '612389921',
  '440311689',
  '379815878',
  '178727351',
  '816503511',
  '246705329',
  '846923189',
  '820421138',
  '802304667',
  '166915695',
  '683925859',
  '192421997',
  '270763905',
  '517866133',
  '205244372'
]