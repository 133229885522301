import { from, Observable, defer, of } from "rxjs";
import { Login } from "@hoylu/login";
import { log } from "@hoylu/client-common";
import { HoyluToken } from "../../state/user/user.actions";
import packageJson from "../../../package.json";
import { UserState } from "../../state/user/user.reducer";
import { getLoginRedirectUrl } from "../../utils/url.helpers";
import { AppState } from "../../state/root.reducer";

let loginSingleton: Login | undefined = undefined;

export function getLogin(params: {
  loginHost: string;
}) {
  if (!loginSingleton) {
    loginSingleton = new Login({
      loginHost: params.loginHost,
      appName: packageJson.name,
      appVersion: packageJson.version,
      logger: log,
      timeout: 120000, // == 2 minute timeout
    });
  }

  return loginSingleton;
}

export function handleLogin(state: AppState): Observable<HoyluToken> {
  const config = state.context.config;
  const login = getLogin({
    loginHost: config.serviceConfig.login,
  });
  if (config.teamsClient && config.teamsClient === "desktop") {
    return from(
      login
        .teamsLogin(config.teamsClient)
        .then((r) => r.token)
        .catch((err: string) => {
          throw new Error(err);
        })
    );
  } else if (
    config.webexMode ||
    (config.teamsClient && config.teamsClient !== "web") /* also use login redirect for ms-teams on mobile */
  ) {
    return defer(() => {
      login.loginRedirect(getLoginRedirectUrl(state));
      return of("");
    });
  }
  return from(
    login
      .login()
      .then((r) => r.token)
      .catch((err: string) => {
        throw new Error(err);
      })
  );
}
export function handleLogout(
  loginHost: string,
): Observable<HoyluToken> {
  const login = getLogin({ loginHost });
  return from(
    login
      .logout()
      .then((r) => r.token)
      .catch((err: string) => {
        throw new Error(err);
      })
  );
}

export function getTokenSilent(
  loginHost: string,
  userState: UserState
): Observable<HoyluToken> {
  const login = getLogin({
    loginHost,
  });
  let currentLoginState = getCurrentLoginState(userState);
  return from(
    login
      .getTokenSilent(currentLoginState)
      .then((r) => r.token)
      .catch((err: string) => {
        console.log(err);
        throw new Error(err);
      })
  );
}

function getCurrentLoginState(
  userState: UserState
): "UNKNOWN" | "LOGGED_IN" | "NOT_LOGGED_IN" {
  switch (userState.isLoggedIn) {
    case true:
      return "LOGGED_IN";
    case false:
      return "NOT_LOGGED_IN";
    default:
      return "UNKNOWN";
  }
}

export function registerEmail(
  state: AppState,
  email: string
): Observable<HoyluToken> {
  const config = state.context.config;
  const login = getLogin({
    loginHost: config.serviceConfig.login,
  });
  if (config.teamsClient && config.teamsClient === "desktop") {
    return from(
      login
        .teamsRegisterEmail(config.teamsClient, email)
        .then((r) => r.token)
        .catch((err: string) => {
          throw new Error(err);
        })
    );
  } else if (
    config.webexMode ||
    config.teamsClient /* use redirect mode for ms-teams on mobile */
  ) {
    return defer(() => {
      login.registerEmailRedirect(email, getLoginRedirectUrl(state));
      return of("");
    });
  }
  return from(
    login
      .registerEmail(email)
      .then((r) => r.token)
      .catch((err: string) => {
        throw new Error(err);
      })
  );
}
