import { createReducer } from "typesafe-actions";
import { resetState } from "../root.action";
import * as actions from "./templates.actions";
import {LoadingState, TemplateCategoryState, TemplateDetails} from "./types";
import { ORGANIZATION_CATEGORY_ID, QUICK_ACCESS_CATEGORY_ID } from "../../services/templates/types";

export type TemplatesState = {
  templates: TemplateDetails[];
  categories: TemplateCategoryState[];
} & LoadingState;

// Mocking UI specific category
export const organizationCategory = {
  categoryId: ORGANIZATION_CATEGORY_ID,
  name: "Organization",
  icon: "hoylu-ui-icons-construction2"
}

export const defaultState = (): TemplatesState => {
  return {
    templates: [],
    categories: [
      {
        categoryId: QUICK_ACCESS_CATEGORY_ID,
        name: "Quick Access",
        templates: []
      }
    ],
    isLoading: false,
    isFetchingTemplateDetails: false,
    isUpdated: false,
    fetchFailure: false,
  };
};

function setAt(
  categories: TemplateCategoryState[],
  id: string,
  change: Partial<TemplateCategoryState>
) {
  const index = categories.findIndex((c) => c.categoryId === id);
  if (index === -1) {
    return categories;
  }
  return [
    ...categories.slice(0, index),
    {
      ...categories[index],
      ...change,
    },
    ...categories.slice(index + 1),
  ];
}

export default createReducer<TemplatesState>(defaultState())
  .handleAction(resetState, () => defaultState())
  .handleAction(actions.fetchTemplateCategories.request, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(actions.fetchTemplateCategories.success, (state, action) => ({
    ...state,
    categories: action.payload.categories.map((c) => {
      const category = state.categories.find(({ categoryId }) => categoryId === c.categoryId);

      return {
        ...c,
        templates: category?.templates ?? []
      }
    }),
    isLoading: false,
  }))
  .handleAction(actions.fetchTemplateCategories.failure, (state) => ({
    ...state,
    isLoading: false,
    fetchFailure: true,
  }))
  .handleAction(actions.fetchTemplates.request, (state, action) => ({
    ...state,
    categories: setAt(state.categories, action.payload.categoryId, {
      isLoading: true,
      fetchFailure: false,
    }),
  }))
  .handleAction(actions.fetchTemplates.success, (state, action) => ({
    ...state,
    categories: setAt(state.categories, action.payload.categoryId, {
      templates: action.payload.templates,
      isLoading: false,
    }),
  }))
  .handleAction(actions.fetchTemplates.failure, (state) => ({
    ...state,
    isLoading: false,
    fetchFailure: true,
  }))
  .handleAction(actions.fetchOrganizationTemplates.request, (state) => ({
    ...state,
    categories: setAt(state.categories, ORGANIZATION_CATEGORY_ID, {
      isLoading: true,
      fetchFailure: false,
    }),
  }))
  .handleAction(actions.fetchOrganizationTemplates.success, (state, action) => ({
    ...state,
    categories: setAt(state.categories, ORGANIZATION_CATEGORY_ID, {
      templates: action.payload.templates,
      isLoading: false,
    })
  }))
  .handleAction(actions.fetchOrganizationTemplates.failure, (state) => ({
    ...state,
    isLoading: false,
    fetchFailure: true,
  }))
  .handleAction(actions.fetchTemplateDetails.request, (state) => ({
    ...state,
    isFetchingTemplateDetails: true
  }))
  .handleAction(actions.fetchTemplateDetails.success, (state, action) => {
    const existingTemplateIndex = state.templates.findIndex(
      (template) => template.templateId === action.payload.templateId
    );

    const updatedTemplates = existingTemplateIndex !== -1
        ? state.templates.map((template, index) => index === existingTemplateIndex ? action.payload : template)
        : [...state.templates, action.payload];

    return {
      ...state,
      templates: updatedTemplates,
      isFetchingTemplateDetails: false,
      fetchFailure: false,
    }
  })
  .handleAction(actions.fetchTemplateDetails.failure, (state) => ({
    ...state,
    isFetchingTemplateDetails: false,
    fetchFailure: true,
  }))
  .handleAction(actions.updateTemplateDetails.request, (state) => ({
      ...state,
      isLoading: true,
      isUpdated: false
  }))
  .handleAction(actions.updateTemplateDetails.success, (state, action) => {
    const { name, description } = action.payload;

    const updatedTemplates = state.templates.map((template) =>
      template.templateId === action.payload.templateId
        ? { ...template, description, name }
        : template
    );

    return {
      ...state,
      templates: updatedTemplates,
      isLoading: false,
      isUpdated: true,
      fetchFailure: false,
    }
  })
  .handleAction(actions.updateTemplateDetails.failure, (state) => ({
    ...state,
    isLoading: false,
    isUpdated: false,
    fetchFailure: true,
  }))
  .handleAction(actions.publishTemplate.request, (state) => ({
    ...state,
    isLoading: true,
    isUpdated: false
  }))
  .handleAction(actions.publishTemplate.success, (state, action) => {
    const { categoryIds, templateId } = action.payload;

    const updatedTemplates = state.templates.map((template) =>
      template.templateId === templateId
        ? { ...template, categoryIds: [...template.categoryIds, ...categoryIds] }
        : template
    );

    return {
      ...state,
      templates: updatedTemplates,
      isLoading: false,
      isUpdated: true,
      fetchFailure: false,
    }
  })
  .handleAction(actions.publishTemplate.failure, (state) => ({
    ...state,
    isLoading: false,
    isUpdated: false,
    fetchFailure: true,
  }))
  .handleAction(actions.removeTemplateCategories.request, (state) => ({
    ...state,
    isLoading: true,
    isUpdated: false
  }))
  .handleAction(actions.removeTemplateCategories.success, (state, action) => {
    const { categoryIds, templateId } = action.payload;

    const updatedTemplates = state.templates.map((template) =>
      template.templateId === templateId
        ? { ...template, categoryIds: template.categoryIds.filter((c) => !categoryIds.includes(c) ) }
        : template
    );

    return {
      ...state,
      templates: updatedTemplates,
      isLoading: false,
      isUpdated: true,
      fetchFailure: false,
    }
  })
  .handleAction(actions.removeTemplateCategories.failure, (state) => ({
    ...state,
    isLoading: false,
    isUpdated: false,
    fetchFailure: true,
  }))
  .handleAction(actions.createTemplate.request, (state) => ({
    ...state,
    isLoading: true,
    isUpdated: false
  }))
  .handleAction(actions.createTemplate.success, (state, action) => {
    const templates = [ ...state.templates, {...action.payload, categoryIds: []}];

    return ({
      ...state,
      templates,
      isLoading: false,
      isUpdated: true
    })
  })
  .handleAction(actions.createTemplate.failure, (state) => ({
    ...state,
    isLoading: false,
    isUpdated: false,
    fetchFailure: true
  }))
  .handleAction(actions.deleteTemplate.request, (state) => ({
    ...state,
    isLoading: true,
    isUpdated: false
  }))
  .handleAction(actions.deleteTemplate.success, (state, action) => {
    const templates = state.templates.filter((template) => template.templateId !== action.payload);
    const categories = state.categories.map(c => {
      const filteredTemplates = c.templates.filter(t => t.templateId != action.payload);
      return { ...c, templates: filteredTemplates };
    });

    return ({
      ...state,
      categories,
      templates,
      isLoading: false,
      isUpdated: false
    })
  })
  .handleAction(actions.deleteTemplate.failure, (state) => ({
    ...state,
    isLoading: false,
    isUpdated: false,
    fetchFailure: true
  })
  );
//TODO: Add handlers for create/delete success/failure, they might be in different reducer
