import React from "react";
import { Modal } from "./Modal";
import { connect } from "react-redux";
import packageJson from "../../../package.json";
import { updateVersion } from "../../state/config/config.actions";
import { Localized } from "../../strings";
import ICON_FEATURE_1 from "../../assets/images/icons/pull-plan.svg";
import ICON_FEATURE_2 from "../../assets/images/icons/text-align.svg";
import ICON_FEATURE_3 from "../../assets/images/icons/updates.svg";
import Styles from "./WhatsNewDialog.module.css";
import { Button } from "@hoylu/client-common";

type FeatureType = {
  icon: string;
  title: string;
  description: string;
};

// MATCH ORDER OF STRING.FEATURES OBJECT
const iconPaths = [ICON_FEATURE_1, ICON_FEATURE_2, ICON_FEATURE_3];

// When updating Whats New, update dashboard and electron version number
// Run on repo root: `npx lerna version minor --no-git-tag-version --no-push`
//
// They should be the same number. e.g: bump from 1.1.0 to 1.2.0
// Note: the 3rd number is automatically set by the build process and gets overwritten
//    dashboard/package.json
//    electron/package.json

const mapDispatchToProps = {
  updateVersion: updateVersion,
};

type WhatsNewDialogProps = typeof mapDispatchToProps;

const newItem: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }, index) => {
  return (
    <div className={Styles.item} key={index}>
      <div className={Styles.itemImage}>
        <img src={iconPaths[index]} alt={title + " alt"} />
      </div>
      <div>
        <p className={Styles.itemTitle}>{title}</p>
        <br />
        <pre className={Styles.itemDescriptionContainer}>
          <p className={Styles.itemDescription}>{description}</p>
        </pre>
      </div>
    </div>
  );
};

export const WhatsNewDialog: React.FC<WhatsNewDialogProps> = ({
  updateVersion,
}) => {
  const strings = Localized.object("WHATS_NEW_DIALOG");
  return (
    <Modal classNames={[Styles.front]}>
      <div className={Styles.whatsNewDialog}>
        <div className={Styles.header}>
          <div className={Styles.iconContainer}>
            <img
              src={require("../../assets/images/icons/new-icon.svg")}
              alt={strings.WHATS_NEW_ICON}
            />
          </div>
          <h1 className={Styles.title}>{strings.WHATS_NEW}</h1>
        </div>

        <div className={Styles.items}>
          {Object.values(strings.FEATURES).map((val, i) =>
            newItem(val as FeatureType, i)
          )}
        </div>

        <Button
          type="primary"
          onClick={() => {
            window.localStorage.setItem("version", packageJson.version);
            updateVersion(false);
          }}
          toolTip={strings.CONTINUE}
        >
          {strings.CONTINUE}
        </Button>
        <div className={Styles.browserSuggestion}>
          {strings.BROWSER_SUGGESTION}
        </div>
      </div>
    </Modal>
  );
};

export default connect(null, mapDispatchToProps)(WhatsNewDialog);
