import { AppEpic } from "../types";
import { catchError, filter, mergeMap } from "rxjs/operators";
import {isActionOf, PayloadAction} from "typesafe-actions";
import {
  createTemplate,
  deleteTemplate,
  fetchTemplateCategories,
  fetchTemplates,
  publishTemplate,
  fetchTemplateDetails,
  updateTemplateDetails,
  removeTemplateCategories,
  fetchOrganizationTemplates
} from "../../state/templates/templates.actions";
import { waitForToken } from "../helpers/waitForState";
import { of, from } from "rxjs";
import { PublishTemplatePayload } from "../../state/templates/types";
import { getWorkspaceDetails } from "../../state/workspaces/workspaces.actions";
import { QUICK_ACCESS_CATEGORY_ID } from "./types";
import { organizationCategory } from "../../state/templates/templates.reducer";

const updateCategories = (originalCategories: string[], newCategories: string[]) => {
  const addedCategories = newCategories.filter(id => !originalCategories.includes(id));
  const removedCategories = originalCategories.filter(id => !newCategories.includes(id));

  return { addedCategories, removedCategories };
}

export const fetchTemplateCategoriesEpic: AppEpic = (
  action$,
  state$,
  { templateRequests }
) =>
  action$.pipe(
    filter(isActionOf(fetchTemplateCategories.request)),
    waitForToken(state$),
    mergeMap(() =>
      templateRequests
        .getTemplateCategories(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token
        )
        .pipe(
          mergeMap((res) => {
            // 'Organization' category is not stored in the database. It is exclusively displayed on the client side.
            // Its inclusion here is for the purpose of keeping this category in the same place in state in order to
            // simplify Redux operations like update / remove etc.
            const clientCategories = { ...res, categories: [ ...res.categories, organizationCategory] };
            return of(fetchTemplateCategories.success(clientCategories))
          }),
          catchError((e) => of(fetchTemplateCategories.failure(e)))
        )
    )
  );

export const fetchTemplatesEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(fetchTemplates.request)),
    waitForToken(state$),
    mergeMap((action) =>
      templateRequests
        .getTemplates(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          action.payload.categoryId
        )
        .pipe(
          mergeMap((templates) => of(fetchTemplates.success({...action.payload, ...templates}))),
          catchError((e) => of(fetchTemplates.failure(e)))
        )
    )
  );

export const fetchOrganizationTemplatesEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(fetchOrganizationTemplates.request)),
    waitForToken(state$),
    mergeMap(() =>
      templateRequests
        .getOrganizationTemplates(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token
        )
        .pipe(
          mergeMap((templates) => of(fetchOrganizationTemplates.success(templates))),
          catchError((e) => of(fetchOrganizationTemplates.failure(e)))
        )
    )
  );

export const fetchTemplateDetailsEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(fetchTemplateDetails.request)),
    waitForToken(state$),
    mergeMap((action) => {
        return templateRequests
          .getTemplateById(
            state$.value.context.config.serviceConfig.documentMetadata,
            state$.value.context.user.token,
            action.payload
          )
          .pipe(
            mergeMap((template) => of(fetchTemplateDetails.success(template))),
            catchError((e) => of(fetchTemplateDetails.failure(e)))
          );
      }
    )
  );

export const deleteTemplateEpic: AppEpic = (
  action$,
  state$,
  { templateRequests }
) =>
  action$.pipe(
    filter(isActionOf(deleteTemplate.request)),
    waitForToken(state$),
    mergeMap((action) =>
      templateRequests
        .deleteTemplate(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          action.payload
        )
        .pipe(
          mergeMap(() => of(deleteTemplate.success(action.payload))),
          catchError((e) => of(deleteTemplate.failure(e)))
        )
    )
  );

export const createTemplateEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(createTemplate.request)),
    waitForToken(state$),
    mergeMap((action) =>
      templateRequests
        .createTemplate(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          action.payload
        )
        .pipe(
          mergeMap((template) => {
            const {categoryIds, templateId} = action.payload;

            if (categoryIds && categoryIds.length > 0) {
              const publishPayload: PublishTemplatePayload = {
                categoryIds: categoryIds,
                templateToCategory: {templateId, orderRank: null}
              };

              return of(
                createTemplate.success(template),
                publishTemplate.request(publishPayload)
              )
            } else {
              return of(
                createTemplate.success(template),
                getWorkspaceDetails.request(template.workspaceId)
              )
            }
          }),
          catchError((e) => of(createTemplate.failure(e)))
        )
    )
  );

export const publishTemplateEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(publishTemplate.request)),
    waitForToken(state$),
    mergeMap((action) =>
      templateRequests
        .publishTemplate(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          action.payload.categoryIds,
          action.payload.templateToCategory
        )
        .pipe(
          mergeMap(() => {
            const isPublishingToQuickAccess = action.payload.categoryIds.some((c) => c === QUICK_ACCESS_CATEGORY_ID);
            const workspaceId = state$.value.context.workspaces.selectedWorkspaceID;

            let requests: PayloadAction<any, any>[] = [
              publishTemplate.success({ templateId: action.payload.templateToCategory.templateId, categoryIds: action.payload.categoryIds }),
            ];

            if (workspaceId) {
              requests.push(getWorkspaceDetails.request(workspaceId))
            }

            if (isPublishingToQuickAccess) {
              requests.push(fetchTemplates.request({ categoryId: QUICK_ACCESS_CATEGORY_ID }))
            }

            return from(requests).pipe(mergeMap((r) => of(r)))
          }),
          catchError((e) => of(publishTemplate.failure(e)))
        )
    )
  );

export const removeTemplateCategoriesEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(removeTemplateCategories.request)),
    waitForToken(state$),
    mergeMap((action) =>
      templateRequests
        .removeTemplateFromCategories(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          action.payload.categoryIds,
          action.payload.templateId
        )
        .pipe(
          mergeMap(() => {
            const isRemovingQuickAccessTemplates = action.payload.categoryIds.some((c) => c === QUICK_ACCESS_CATEGORY_ID);

            if (isRemovingQuickAccessTemplates) {
              return of(
                removeTemplateCategories.success({
                  templateId: action.payload.templateId,
                  categoryIds: action.payload.categoryIds
                }),
                fetchTemplates.request({ categoryId: QUICK_ACCESS_CATEGORY_ID })
                )
            }

            return of(removeTemplateCategories.success({
              templateId: action.payload.templateId,
              categoryIds: action.payload.categoryIds
            }))
          }),
          catchError((e) => of(removeTemplateCategories.failure(e)))
        )
    )
  );

export const updateTemplateEpic: AppEpic = (action$, state$, { templateRequests }) =>
  action$.pipe(
    filter(isActionOf(updateTemplateDetails.request)),
    waitForToken(state$),
    mergeMap((action) => {
      const { templateId, details, originalTemplateIds, updatedTemplatesIds } = action.payload;

      return templateRequests
        .updateTemplate(
          state$.value.context.config.serviceConfig.documentMetadata,
          state$.value.context.user.token,
          templateId,
          details
        )
        .pipe(
          mergeMap((res) => {
            const { addedCategories, removedCategories} = updateCategories(originalTemplateIds, updatedTemplatesIds);
            const templateToCategory = { templateId, orderRank: null };

            return of(
              publishTemplate.request({ categoryIds: addedCategories, templateToCategory }),
              removeTemplateCategories.request({ categoryIds: removedCategories, templateId }),
              updateTemplateDetails.success(res)
            )
          }),
          catchError((e) => of(updateTemplateDetails.failure(e)))
        )}
    )
  );
