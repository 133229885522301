import React from "react";
import Styles from "./ShareSection.module.css";

export type ShareSectionProps = React.PropsWithChildren<{
  testId: string;
}>;

export const ShareSection: React.FC<ShareSectionProps> = ({
  testId,
  children,
}) => {
  return (
    <div className={Styles.shareSection} data-test-id={testId}>
      {children}
    </div>
  );
};
