import { createSelector } from "reselect";
import { RootState } from "typesafe-actions";
import { getWorkspaceURL } from "../../utils/url.helpers";
import type {
  Label,
  WorkspaceDetails,
  WorkspaceLabels,
  WorkspacePermissionsAndUsers,
} from "./types";
import { ConfigState } from "../config/config.reducer";
import { config, showCreditLimitUi } from "../config/config.selector";
import { user } from "../user/user.selector";
import { LicenseType } from "../user/user.actions";
import { StoredSearchParams } from "./workspaces.reducer";
import { defaultRoles } from "./defaultRoles";

const getWorkspaces = (state: RootState) =>
  state.context.workspaces?.existing ?? [];
const getStoredSearchParams = (state: RootState) =>
  state.context.workspaces?.storedSearchParams;
const getWaitingToEditID = (state: RootState) =>
  state.context.workspaces?.waitingToEditID;
const getOpeningWorkspaceID = (state: RootState) =>
  state.context.workspaces?.existing?.find((w) => w.isFullscreen)?.workspaceId;
const getUserLicenseInfo = (state: RootState) => state.context.user.licenseInfo;
const getRequestedUpdatingWorkspaces = (state: RootState) =>
  state.context.workspaces.requestedUpdates;
const selectedProjectWorkspaces = (state: RootState) =>
  state.context.workspaces.selectedProjectWorkspaces ?? [];
export const selectHasAssignableProjects = (state: RootState) =>
  !!state.context.workspaces.assignableProjects?.length;
const getProjects = (state: RootState) => state.context.workspaces.projects;
const getHasPopulatedAllWorkspaces = (state: RootState) =>
  state.context.workspaces.hasPopulatedAllWorkspaces;
const tonnageUsage = (state: RootState) =>
  state.context.workspaces.tonnageUsage;
export const getSelectedWorkspaceId = (state: RootState) =>
  state.context.workspaces.selectedWorkspaceID;
export const getUserLicenseType = createSelector(
  getUserLicenseInfo,
  (license) => {
    if (!license) return LicenseType.Free;
    return license.type;
  }
);
export const getWaitingToLoadProjectID = (state: RootState) =>
  state.context.workspaces.waitingToLoadProjectID;

export const isUserLicensed = createSelector(getUserLicenseInfo, (license) => {
  if (!license) return false;
  return (
    [LicenseType.Plus, LicenseType.Enterprise].includes(license.type) &&
    !license.isExpired
  );
});

export function augmentWorkspaceDetails(
  w: WorkspaceDetails,
  config: ConfigState,
  email: string,
  storedSearchParameters?: StoredSearchParams
): WorkspaceDetails {
  const isAdmin =
    w.myPermissions?.canAdmin ??
    /* to be removed when only v3 endpoints are used */
    w.roles?.administrators
      .map((admin) => admin.toLowerCase())
      .includes(email.toLowerCase()) ??
    false;
  return {
    ...w,
    thumbnailUrl: getWorkspaceThumbnailUrl(w.workspaceId, config),
    workspaceUrl: getWorkspaceURL(
      w.workspaceType,
      w.workspaceId,
      config,
      w.module?.name ?? "",
      storedSearchParameters
    ),
    canLeave: w.roles
      ? !(isAdmin && w.roles?.administrators.length === 1)
      : w.myPermissions?.canLeave,
    isAdmin,
  };
}

export function getWorkspaceThumbnailUrl(
  workspaceId: string,
  config: ConfigState
): string {
  return (
    config.serviceConfig.documentImages +
    "/api/v2/blob/" +
    workspaceId +
    "/thumbnail"
  );
}

export const getWorkspaceById = (state: RootState, workspaceId: string) =>
  state.context.workspaces.existing.find((w) => workspaceId === w.workspaceId);

export const getSelectedProjectWorkspaces = createSelector(
  [selectedProjectWorkspaces, config, user, getStoredSearchParams],
  (workspaces, config, user, storedSearchParams) =>
    workspaces.map((w) =>
      augmentWorkspaceDetails(w, config, user.profile.email, storedSearchParams)
    )
);

export const getAllWorkspaces = createSelector(
  [
    getWorkspaces,
    getRequestedUpdatingWorkspaces,
    config,
    user,
    getStoredSearchParams,
  ],
  (existing, updating, config, user, storedSearchParams) => {
    const workspaces = [...existing];
    updating.forEach((update) => {
      const index = workspaces.findIndex(
        (w) => w.workspaceId === update.workspaceId
      );
      workspaces[index] = update;
    });

    return workspaces.map((w) =>
      augmentWorkspaceDetails(w, config, user.profile.email, storedSearchParams)
    );
  }
);

export const isWorkspaceFullscreen = createSelector(
  [getWorkspaces],
  (workspaces) => {
    return workspaces.some((w) => w.isFullscreen);
  }
);

export const getWorkspaceFullscreen = createSelector(
  [getWorkspaces],
  (workspaces) => {
    return workspaces.find((w) => w.isFullscreen);
  }
);

export const getWorkspaceIsLive = createSelector(
  [getWorkspaces],
  (workspaces) => {
    return workspaces.filter((w) => w.isLive);
  }
);

export const getSelectedWorkspaceDetails = createSelector(
  [getWorkspaces, getSelectedWorkspaceId, config, user, getStoredSearchParams],
  (workspaces, id, config, user, storedSearchParams) => {
    let workspace = workspaces.find((w) => w.workspaceId === id);
    if (workspace) {
      return augmentWorkspaceDetails(
        workspace,
        config,
        user.profile.email,
        storedSearchParams
      );
    }
    return undefined;
  }
);

export const isWorkspaceLimited = (state: RootState, workspaceId: string) => {
  const workspace = getWorkspaceById(state, workspaceId);

  if (!workspace || !workspace.myPermissions) {
    return false;
  }

  return !workspace.myPermissions.canGrow && workspace.myPermissions.canWrite;
};

export const isWorkspaceReadOnly = createSelector(
  [
    user,
    getWorkspaces,
    getSelectedProjectWorkspaces,
    (_: RootState, props: string) => props,
  ],
  (user, existing, projects, workspaceId) => {
    const workspace = [...existing, ...projects].find(
      (ws) => ws.workspaceId === workspaceId
    );

    // v3
    if (workspace?.myPermissions) {
      return !workspace.myPermissions.canWrite;
    }

    // v2 - triggered via "more option" button
    if (workspace?.roles) {
      return !workspace.roles.writers
        .map((email) => email.toLowerCase())
        .includes(user.profile.email.toLowerCase());
    }

    return false;
  }
);

export const permissionLevelSets = createSelector(
  [getSelectedWorkspaceDetails],
  (workspace): WorkspacePermissionsAndUsers => {
    if (workspace?.roles) {
      return {
        ...workspace.roles,
        administrators: workspace.roles.administrators,
        writers: workspace.roles.writers.filter((writer) => {
          return !workspace.roles!.administrators.includes(writer);
        }),
        readers: workspace.roles.readers.filter((reader) => {
          return !(
            workspace.roles!.writers.includes(reader) ||
            workspace.roles!.administrators.includes(reader)
          );
        }),
      };
    }
    return defaultRoles();
  }
);

export const getAllowedPremiumModules = createSelector([user], (user) => {
  return user.licenseInfo?.allowedPremiumModules || [];
});

export const getAllLabels = createSelector(
  [getWorkspaces],
  (existing): WorkspaceLabels => {
    if (!existing) return [];

    const allLabels: Label[] = existing
      .flatMap((w: WorkspaceDetails) => w.labels)
      .reverse() // reverse -> first occurance gets selected for distinct values
      .sort((a, b) => a.name.localeCompare(b.name)); // alphabetically sorted
    // make distinct
    const map = new Map(allLabels.map((l: Label) => [l.name.toUpperCase(), l]));
    return [...map.values()];
  }
);

export const hasModifiedThumbnail = (state: RootState, workspaceId: string) => {
  return state.context.workspaces.thumbnailsModified.has(workspaceId);
};

export const uploadedSnapshot = (state: RootState, workspaceId: string) => {
  return state.context.workspaces.thumbnailsModified.get(workspaceId);
};

export const effectiveTonnageUsage = createSelector(
  [tonnageUsage, isUserLicensed, showCreditLimitUi],
  (tonnage, isLicenseActive, showCreditLimitUi) => {
    if (isLicenseActive || !showCreditLimitUi) return 0;
    return tonnage;
  }
);

export const isCreditLimitReached = createSelector(
  [effectiveTonnageUsage],
  (tonnage) => tonnage >= 100
);

export const isHiddenSectionVisible = (state: RootState) => {
  return (
    state.context.workspaces.showHiddenWorkspaces &&
    !state.context.workspaces.selectedProject
  );
};

export const getDialogPosition = (state: RootState) => {
  return state.context.workspaces.dialogPosition;
};

export const isNotificationCenterOpen = (state: RootState) => {
  return state.context.workspaces.activeOption === "NOTIFICATION_CENTER";
};

export const fullScreenWorkspace = createSelector(
  getWorkspaces,
  (workspaces) => {
    return workspaces.find((workspace) => workspace.isFullscreen);
  }
);

export const isEditingAWorkspace = createSelector(
  fullScreenWorkspace,
  (workspace) => !!workspace
);
