import { Icon } from "@hoylu/client-common";
import React from "react";

type CloseIconProps = {
  onClose: () => void;
  title?: string;
  style?: string;
};

export const CloseIcon: React.FC<CloseIconProps> = ({ onClose, title }) => {
  return <Icon icon={"hoylu-ui-icons-x"} title={title} onClick={onClose} />;
};
