//TODO: This is for our convenience until cisco delivers their own type definitions - it is also partly guessing the types here

export function isWebexApplication(obj: any): obj is WebexApplication {
  return "onReady" in obj && "setShareUrl" in obj && "clearShareUrl" in obj  && "context" in obj;
}

export interface WebexApplication {
  // According to docs those functions should be there, but they are not
  onReady: () => Promise<void>;
  setShareUrl: (
    internalUrl: string,
    externalUrl: string,
    title: string
  ) => Promise<number>;
  clearShareUrl: () => Promise<void>;
  isShared: boolean;
  listen: () => Promise<void>;
  on: any; // TODO: type
  context: Context;
}

interface Context {
  getUser: () => Promise<User>;
}

interface User {
  id?: string;
  orgId?: string;
  email?: string;
  displayName?: string;
  token?: string;
}
