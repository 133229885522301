import type { Theme } from "@hoylu/client-common";
import React, { MouseEvent } from "react";
import Styles from "./Modal.module.css";
import AppStyles from "../App.module.css";
import { useDashboardTheme } from "../../utils/hooks/useDashboardTheme";

type ModalProps = React.PropsWithChildren<{
  handleOutsideClick?: (e: MouseEvent) => void;
  allowContentToOverflow?: boolean; // true: allow content to overflow and also prevent showing scrollbars (e.g: when content has a dropdown)
  showOverlay?: boolean;
  forcedTheme?: Theme;
  classNames?: string[];
}>;

export const Modal: React.FC<ModalProps> = ({
  handleOutsideClick,
  children,
  allowContentToOverflow = false,
  classNames = [],
  showOverlay = true,
  forcedTheme,
}) => {
  const theme = useDashboardTheme();
  const modalStyle = `${Styles.modal} ${
    allowContentToOverflow ? "" : Styles.overflowAuto
  }`;

  return (
    // hideScrollbar class is required here to hide scrollbar on root element
    <div className={`${AppStyles.hideScrollbar} ${classNames.join(" ")}`}>
      {showOverlay && (
        <div
          onClick={handleOutsideClick}
          className={Styles.overlay}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
        />
      )}
      <div className={`${Styles.modalContainer} ${forcedTheme ?? theme}`}>
        <div className={modalStyle}>{children}</div>
      </div>
    </div>
  );
};
