import React from "react";
import { Localized } from "../strings";
import { EditLabelContent } from "./modals/EditLabelContent";
import { WorkspaceLabels } from "../state/workspaces/types";
import useAutoVisibility from "./hooks/useAutoVisibility";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  activateDashboardOption,
  populateAllWorkspaces,
} from "../state/workspaces/workspaces.actions";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { DraggableWindowHeader, Icon } from "@hoylu/client-common";
import { getSelectedProject } from "../state/workspaces/projects.selector";
import Styles from "./FilterByWorkspaceTag.module.css";

export type FilterByWorkspaceTagProps = {
  allLabels: WorkspaceLabels;
  selectedLabels: WorkspaceLabels;
  handleLabelsChange: (selected: WorkspaceLabels) => void;
};

export const FilterByWorkspaceTag: React.FC<FilterByWorkspaceTagProps> = ({
  allLabels,
  handleLabelsChange,
  selectedLabels,
}) => {
  const dispatch = useDispatch();
  const { ref, componentVisible, setComponentVisible } = useAutoVisibility(
    false
  );
  const strings = Localized.object("FILTER_BY_WORKSPACE_LABEL");
  const onUpdateSelected = (selected: WorkspaceLabels) => {
    handleLabelsChange(
      selected.filter((l) => allLabels.find((lb) => lb.name === l.name))
    );
  };

  const filterIconStyle = { color: "var(--theme-text)" };

  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const isProjectView = useSelector(getSelectedProject);

  const openFilters = () => {
    if (hasPopulatedAllWorkspaces || componentVisible) {
      dispatch(
        activateDashboardOption({
          optionType: DashboardOption.FILTER,
        })
      );
      setComponentVisible(!componentVisible);
    }
  };
  const populateAllWs = () => {
    if (!hasPopulatedAllWorkspaces) {
      dispatch(populateAllWorkspaces.request());
    }
  };

  return (
    <div
      ref={ref}
      className={Styles.filterByWorkspaceTag}
      aria-label={strings.FILTER_BY_LABELS}
      tabIndex={0}
    >
      <div onMouseOver={populateAllWs}>
        <Icon
          style={filterIconStyle}
          hoverStyle={filterIconStyle}
          icon={"hoylu-ui-icons-filter"}
          onClick={openFilters}
          testId={"filter-by-label"}
          title={strings.FILTER_BY_LABELS}
        />
      </div>
      {componentVisible && hasPopulatedAllWorkspaces && (
        <div className={Styles.filterByWorkspaceTagDialog}>
          <DraggableWindowHeader
            title={strings.FILTER_BY_LABELS}
            onClose={() => setComponentVisible(false)}
          />
          <EditLabelContent
            allLabels={allLabels}
            selected={selectedLabels}
            updateSelected={onUpdateSelected}
            canAddNewLabels={false}
            subscribeLabelsChanges
            enableHiddenWorkspaceSearch={!isProjectView}
          />
        </div>
      )}
    </div>
  );
};
