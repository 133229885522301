import React from "react";
import { Localized } from "../strings";
import Styles from "./OrgBanner.module.css";

/* 
Instructions on how to update the customer org banner:
1. Go to https://portal.azure.com/ and click on containers
2. Open hoyluprodwesteurope -> containers -> images
3. Search by org id (P&G: 1758900b-6aa9-4e1c-a757-bab498da0232)
4. Delete the old banner.png and click upload
5. Find the new banner.png and enter org id under Advanced -> "Upload to folder"

If creating a new folder for an org:
1. After step 2 above, click "Upload"
2. Select the banner.png to upload
3. Click "Advanced"
4. Type in the org ID for "Upload to folder"
5. Click Upload
6. A new folder named the org ID will be created with the banner.png in it

Note: To change the staging banner, similarly open stagehoyluappst (not everyone has permission to view this Storage Account!)
  and click containers -> images then search by org id
  (hoylu org id: ebfe1970-bfba-4913-b09f-f993ba741678)

To add a banner link:
Upload a text file named banner.json next to the banner.png with following content:
```
{
  "bannerLink": "https://my.link"
}
```
Link: Only https links are allowed!
*/

type OrgBannerProps = {
  bannerLink: string | null | undefined;
  orgBanner: string;
};

export const OrgBanner: React.FC<OrgBannerProps> = ({
  bannerLink,
  orgBanner,
}) => {
  const strings = Localized.object("ORG_BANNER");

  return (
    <div
      className={Styles.orgBanner}
      title={Localized.string("ORG_BANNER.ORGANIZATION_BANNER")}
      tabIndex={0}
    >
      <img
        className={`${bannerLink ? Styles.cursorPointer : ""}`}
        src={orgBanner}
        alt={strings.ORGANIZATION_BANNER}
        title={bannerLink ?? ""}
        onClick={() => {
          if (bannerLink) {
            window.open(bannerLink, "_blank");
          }
        }}
      />
    </div>
  );
};

export default OrgBanner;
