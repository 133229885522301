import React, { useEffect, useState } from "react";
import { ShareSectionHeader } from "./ShareSectionHeader";
import { LockIcon } from "./icons/LockIcon";
import { UsersIcon } from "./icons/UsersIcon";
import { ShareSection } from "./ShareSection";
import { WorkspacePermissions } from "./WorkspacePermissions";
import { WorkspacePassword } from "./WorkspacePassword";
import {
  WorkspacePermissionsAndUsers,
  WorkspaceDetails,
} from "../../../state/workspaces/types";
import { ShareOptionValue } from "./ShareType";
import { Contact, makeContact } from "../../RecipientInput";
import { emailRegex } from "../../../utils/emailRegex";
import type { importProjectUsers } from "../../../state/workspaces/workspaces.actions";
import Styles from "./PermissionsSection.module.css";

export type PermissionsSectionProperties = {
  workspaceDetails: WorkspaceDetails;
  permissionLevelSets: WorkspacePermissionsAndUsers;
  email: string;
  publicPermissionLevel: ShareOptionValue;
  importProjectUsers: typeof importProjectUsers | undefined;
  isUpdatingWorkspace: boolean;
  handleWorkspacePassword: (
    password: string,
    currentPassword: string,
    mode: string
  ) => void;
  requiresPassword: boolean;
  isAdmin: boolean;
  hasPassword: boolean;
  sectionTitle: string;
  shareLink: string;
  showOrgAdminPolicy: boolean;
  showProjectAdmin: boolean;
  emailPreset: { emails: string };
  activePermission: boolean;
};

export const PermissionsSection: React.FC<PermissionsSectionProperties> = ({
  workspaceDetails,
  permissionLevelSets,
  email,
  publicPermissionLevel,
  importProjectUsers,
  isUpdatingWorkspace,
  handleWorkspacePassword,
  requiresPassword,
  isAdmin,
  hasPassword,
  sectionTitle,
  shareLink,
  showOrgAdminPolicy,
  showProjectAdmin,
  emailPreset,
  activePermission,
}) => {
  const [isSectionVisible, setIsSectionVisible] = useState(
    !!emailPreset || activePermission
  );
  const [isPasswordSectionActive, setIsPasswordSectionActive] = useState(false);
  const [isUsersSectionActive, setIsUsersSectionActive] = useState(
    !!emailPreset || activePermission
  );
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  useEffect(() => {
    const newContactsFromProps = splitContacts(emailPreset.emails).reduce(
      (acc: Contact[], contact: Contact) => {
        const alreadyAdded = selectedContacts.find(
          (selected) => selected.email === contact.email
        );

        if (!alreadyAdded) {
          acc.push(contact);
        }

        return acc;
      },
      []
    );

    setSelectedContacts((selectedContacts) => [
      ...selectedContacts,
      ...newContactsFromProps,
    ]);
  }, [emailPreset]);

  const openPasswordSection = (isActive: boolean) => {
    setIsSectionVisible(isActive);
    setIsPasswordSectionActive(isActive);
    setIsUsersSectionActive(false);
  };
  const openUsersSection = (isActive: boolean) => {
    setIsSectionVisible(isActive);
    setIsUsersSectionActive(isActive);
    setIsPasswordSectionActive(false);
  };
  const toggleOnHeaderClick = () => {
    setIsSectionVisible(!isSectionVisible);
    if (isSectionVisible) {
      setIsPasswordSectionActive(false);
      setIsUsersSectionActive(false);
    } else {
      setIsUsersSectionActive(true);
    }
  };

  const permissionIcons: JSX.Element[] = [
    <LockIcon
      key={"permissions-lock"}
      isAdmin={isAdmin}
      hasPassword={hasPassword}
      isActive={isPasswordSectionActive}
      toggleActive={openPasswordSection}
    />,
    <UsersIcon
      key={"permissions-users"}
      isActive={isUsersSectionActive}
      toggleActive={openUsersSection}
    />,
  ];
  return (
    <ShareSection testId="set-permissions">
      <ShareSectionHeader
        sectionIcon={"hoylu-ui-icons-key"}
        sectionTitle={sectionTitle}
        isSectionVisible={isSectionVisible}
        onHeaderClick={toggleOnHeaderClick}
        icons={permissionIcons}
        alwaysShowIcons={true}
      />
      {isSectionVisible && (
        <div className={Styles.permissionsSectionModule}>
          {isUsersSectionActive && (
            <WorkspacePermissions
              workspaceDetails={workspaceDetails}
              permissionLevelSets={permissionLevelSets}
              publicPermissionLevel={publicPermissionLevel}
              email={email}
              importProjectUsers={importProjectUsers}
              isUpdatingWorkspace={isUpdatingWorkspace}
              permissionsIsOpen={isUsersSectionActive}
              shareLink={shareLink}
              showOrgAdminPolicy={showOrgAdminPolicy}
              showProjectAdmin={showProjectAdmin}
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
              isAddingUsersFromExternalSource={!!emailPreset.emails}
            />
          )}
          {isPasswordSectionActive && (
            <WorkspacePassword
              hasPassword={!!workspaceDetails.hasPassword}
              confirmPasswordAction={(password, currentPassword, mode) =>
                handleWorkspacePassword(password, currentPassword, mode)
              }
              requiresPassword={requiresPassword}
            />
          )}
        </div>
      )}
    </ShareSection>
  );
};

function splitContacts(text: string) {
  return text
    .split(/[,; ]/)
    .filter((e) => e.match(emailRegex))
    .map(makeContact);
}
