import type { Project, ProjectStatus } from "../state/workspaces/types";
import { Localized } from "../strings";
import { ProjectStatuses } from "../state/workspaces/workspaces.reducer";

export const addPrefixWhenProjectIsInactive = (project: Project) => {
  if (isInactive(project.status)) {
    return `${Localized.string("FILTER_BY_NAME.INACTIVE")} - ${project.name}`;
  }
  return project.name;
};

const isInactive = (status: ProjectStatus | null) => {
  return status === ProjectStatuses.Inactive;
};
