import React from "react";
import { Localized } from "../strings";
import Styles from "./CirclePlus.module.css";

type CirclePlusProps = {
  onClick: () => void;
};

export const CirclePlus: React.FC<CirclePlusProps> = ({ onClick }) => {
  const strings = Localized.object("CREATE_WORKSPACES_SECTION");
  const tooltip = `${strings.CREATE} ${strings.FROM_TEMPLATE}`;

  return (
    <>
      <div
        className={Styles.sliderCirclePlus}
        title={tooltip}
        tabIndex={0}
        aria-label={strings.CREATE_WORKSPACE}
      >
        <div className={Styles.sliderIconContainer} onClick={onClick}>
          <div className={`${Styles.sliderIcon} hoylu-ui-icons-plus`} />
        </div>
      </div>
      <div className={Styles.floatingCirclePlus} title={tooltip}>
        <div className={Styles.floatingIconContainer} onClick={onClick}>
          <div className={`${Styles.floatingIcon} hoylu-ui-icons-plus`} />
        </div>
      </div>
    </>
  );
};

export default CirclePlus;
