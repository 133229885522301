import { Icon } from "@hoylu/client-common";
import React from "react";
import Styles from "./ShareSectionHeader.module.css";

export type ShareSectionHeaderProperties = {
  sectionIcon: string;
  sectionTitle: string;
  onHeaderClick: () => void;
  isSectionVisible: boolean;
  icons?: JSX.Element[];
  headerContent?: JSX.Element;
  alwaysShowIcons?: boolean;
};

export const ShareSectionHeader: React.FC<ShareSectionHeaderProperties> = ({
  sectionIcon,
  sectionTitle,
  onHeaderClick,
  isSectionVisible,
  icons,
  headerContent,
  alwaysShowIcons,
}) => {
  const arrowUpIcon: JSX.Element = <Icon icon={"hoylu-ui-icons-up"} />;
  const arrowDownIcon: JSX.Element = <Icon icon={"hoylu-ui-icons-down"} />;
  return (
    <div className={Styles.shareSectionHeader} onClick={() => onHeaderClick()}>
      <div className={Styles.headerTitle}>
        <div className={`${Styles.sectionIcon} ${sectionIcon}`} />
        {sectionTitle}
      </div>
      {!isSectionVisible && headerContent}
      <div className={Styles.icons}>
        {(!isSectionVisible || alwaysShowIcons) &&
          icons &&
          icons.map((icon) => icon)}
        {isSectionVisible ? arrowUpIcon : arrowDownIcon}
      </div>
    </div>
  );
};
