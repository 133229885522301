import { createStore as createReduxStore, applyMiddleware } from 'redux';
import rootReducer from './root.reducer';

import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'typesafe-actions';

import rootEpic from './root.epic';
import services from '../services';
import { createActionTelemetryMiddleware } from './telemetryMiddleware';

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
  // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
});

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  Services
>({
  dependencies: services
});

const middlewares = [epicMiddleware, createActionTelemetryMiddleware()];
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export function createStore () {
  const store = createReduxStore(rootReducer, enhancer);
  epicMiddleware.run(rootEpic);
  return store;
}
