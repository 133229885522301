import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  cancelDashboardOption,
  setSearchTerm,
} from "../../state/workspaces/workspaces.actions";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";
import Styles from "./SearchWorkspaceDialog.module.css";
import { SearchInput } from "../inputs/SearchInput";

const mapStateToProps = (state: RootState) => ({
  searchTerm: state.context.workspaces.searchTerm,
});

const mapDispatchToProps = {
  cancelDashboardOption,
  setSearchingPhrase: (value: string) => setSearchTerm(value),
};

type SearchWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const SearchWorkspaceDialog: React.FC<SearchWorkspaceDialogProps> = ({
  cancelDashboardOption,
  setSearchingPhrase,
  searchTerm,
}) => {
  const strings = Localized.object("SEARCH_WORKSPACE_DIALOG");

  const [term, setTerm] = useState(searchTerm);

  const handleSearch = () => {
    setSearchingPhrase(term);
    cancelDashboardOption();
  };

  return (
    <Modal handleOutsideClick={cancelDashboardOption}>
      <div className={Styles.searchWorkspaceDialog}>
        <DraggableWindowHeader
          title={strings.TITLE}
          onClose={cancelDashboardOption}
        />
        <SearchInput searchTerm={term} handleChange={setTerm} />
        <div className={Styles.buttons}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={handleSearch}
            dataTestId={"set-searching-workspace-term"}
          >
            {strings.SEARCH}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchWorkspaceDialog);
