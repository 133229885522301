import { WorkspaceType } from '../state/workspaces/WorkspaceType';
import sketchPreviewUrl from '../assets/images/sketch.png';
import flowPreviewUrl from '../assets/images/flow.png';
import defaultPreviewUrl from '../assets/images/modules/blank.png';
import { defaultModule } from "./defaultModule";

//We no longer need that feature, leave function for backward compatibility
export function moduleDefaultImage(_: string): string {
  return defaultPreviewUrl;
}

export function workspaceDefaultImage(
  type?: WorkspaceType,
  moduleName?: string
): string {
  switch (type) {
    case WorkspaceType.SKETCH:
      return sketchPreviewUrl;
    case WorkspaceType.FLOW:
      return flowPreviewUrl;
    default:
      if (moduleName === defaultModule) {
        return defaultPreviewUrl;
      }
      if (moduleName) {
        return moduleDefaultImage(moduleName);
      }
      return defaultPreviewUrl;
  }
}
