import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PermissionsBar } from "./PermissionsBar";
import type {
  WorkspacePermissionsAndUsers,
  WorkspaceDetails,
  WorkspaceDetailsWithUserRoles,
} from "../../../state/workspaces/types";
import { sendEmail, sortUsers } from "../../../utils/sharing.helpers";
import { ShareOptionValue, ShareType, User } from "./ShareType";
import { Localized } from "../../../strings";
import { emailRegex } from "../../../utils/emailRegex";
import {
  Contact,
  FetchProps,
  makeContact,
  RecipientInput,
} from "../../RecipientInput";
import {
  importProjectUsers,
  inviteToWorkspace,
  removeWorkspaceUsers,
  upsertWorkspaceUsers,
} from "../../../state/workspaces/workspaces.actions";
import { useDispatch } from "react-redux";
import { UpsertWorkspaceUsersPermissions } from "../../../services/workspaces/api/workspaces.v3.types";
import { Button, Checkbox, useLocalStorage } from "@hoylu/client-common";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import Styles from "./WorkspacePermissions.module.css";

const RECENT_EMAILS = "__recent_emails";

const userAccessOptions: ShareOptionValue[] = [
  ShareType.ADMIN,
  ShareType.EDIT,
  ShareType.READ,
  ShareType.NO_ACCESS,
];

type WorkspacePermissionsProps = {
  workspaceDetails: WorkspaceDetails;
  permissionLevelSets: WorkspacePermissionsAndUsers;
  email: string;
  publicPermissionLevel: ShareOptionValue;
  importProjectUsers: typeof importProjectUsers | undefined;
  isUpdatingWorkspace: boolean;
  permissionsIsOpen: boolean;
  shareLink: string;
  showOrgAdminPolicy: boolean;
  showProjectAdmin: boolean;
  selectedContacts: Contact[];
  setSelectedContacts: (contacts: Contact[]) => void;
  isAddingUsersFromExternalSource: boolean;
};

function shareTypeToUpsertPermission(shareType: ShareOptionValue) {
  switch (shareType) {
    case ShareType.ADMIN:
      return UpsertWorkspaceUsersPermissions.Administer;
    case ShareType.EDIT:
      return UpsertWorkspaceUsersPermissions.Write;
    case ShareType.READ:
      return UpsertWorkspaceUsersPermissions.Read;
    case ShareType.NO_ACCESS:
      return UpsertWorkspaceUsersPermissions.None;
    default:
      throw new Error(`Unknown share type ${shareType}`);
  }
}

export const WorkspacePermissions: React.FC<WorkspacePermissionsProps> = ({
  workspaceDetails,
  permissionLevelSets,
  email,
  publicPermissionLevel,
  importProjectUsers,
  isUpdatingWorkspace,
  permissionsIsOpen,
  shareLink,
  showOrgAdminPolicy,
  showProjectAdmin,
  selectedContacts,
  setSelectedContacts,
  isAddingUsersFromExternalSource,
}) => {
  const dispatch = useDispatch();

  const strings = Localized.object("WORKSPACE_PERMISSIONS");
  const [isAddingUsers, toggleAddUser] = useState(
    isAddingUsersFromExternalSource
  );
  const [newUsersPermission, setNewUsersPermission] = useState(
    ShareType.EDIT as ShareOptionValue
  );
  const [notifyUsers, setNotifyUsers] = useState(false);
  const [pendingImport, setPendingImport] = useState(false);

  const [recentEmails, setRecentEmails] = useLocalStorage<string[]>(
    RECENT_EMAILS,
    []
  );

  const recentContacts = useMemo(() => {
    const counts = recentEmails.reduce<Record<string, number>>((map, val) => {
      map[val] = (map[val] || 0) + 1;
      return map;
    }, {});
    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1])
      .map(([v]) => makeContact(v));
  }, [recentEmails]);
  const fetchContactsLocal = (value: string, props?: FetchProps) => {
    return fetchContacts(value, { ...props, allContacts: recentContacts });
  };
  const [requestedEmails, setRequestedEmails] = useState<string[]>([]);

  const isAdmin = !!workspaceDetails.isAdmin;
  const roles = workspaceDetails.roles;
  const arePermissionsAndUsersLoaded = !!roles;

  let users = sortUsers(permissionLevelSets);

  if (!isAdmin) {
    let user = users.find((user) => user.email === email);
    users = permissionLevelSets.administrators.map((user) => ({
      email: user,
      permission: ShareType.ADMIN,
    }));
    if (user) {
      users.push(user);
    }
  }

  const emailsWithoutAccount = requestedEmails.filter(
    (re) => !users.find((u) => u.email === re)
  );
  const clearRequestedEmails = () => setRequestedEmails([]);

  useEffect(() => {
    setPendingImport(false);
  }, [workspaceDetails]);

  const upsertUsers = useCallback(
    (userEmails: string[], permission: UpsertWorkspaceUsersPermissions) => {
      clearRequestedEmails();
      dispatch(
        upsertWorkspaceUsers.request({
          workspaceId: workspaceDetails.workspaceId,
          userEmails,
          permission,
        })
      );
    },
    [workspaceDetails.workspaceId, dispatch, clearRequestedEmails]
  );

  const removeUser = useCallback(
    (email: string) => {
      clearRequestedEmails();
      dispatch(
        removeWorkspaceUsers.request({
          workspaceId: workspaceDetails.workspaceId,
          userEmails: [email],
        })
      );
    },
    [workspaceDetails.workspaceId, dispatch, clearRequestedEmails]
  );

  const evaluateEmailList = useCallback(() => {
    if (!arePermissionsAndUsersLoaded) return;

    const userIsNotInWorkspace = (userEmail: string) =>
      !users.find((u) => u.email === userEmail);

    const emailList = selectedContacts
      .map((e) => e.email.toLowerCase())
      .filter((e) => e.match(emailRegex)) // filter out invalid emails
      .filter(userIsNotInWorkspace); // filter out existing emails, because adding new users should not overwrite the permission of a user already in the workspace

    const index = emailList.indexOf(email.trim());
    if (index > -1) {
      emailList.splice(index, 1);
    }
    if (emailList.length !== 0) {
      setRecentEmails((re) => [...emailList, ...re].slice(0, 100));
      upsertUsers(emailList, shareTypeToUpsertPermission(newUsersPermission));
      setRequestedEmails(emailList);
      if (notifyUsers) {
        sendEmail(workspaceDetails.workspaceName ?? "", shareLink, emailList);
        setNotifyUsers(false);
      }
    }
    toggleAddUser(false);
    setSelectedContacts([]);
  }, [
    arePermissionsAndUsersLoaded,
    newUsersPermission,
    notifyUsers,
    selectedContacts,
    shareLink,
    upsertUsers,
    workspaceDetails.workspaceName,
    users,
  ]);

  const onCommitUpdate = useCallback(
    (user: User, shareOption: ShareOptionValue) => {
      if (shareOption === ShareType.REMOVE) {
        removeUser(user.email);
      } else {
        upsertUsers([user.email], shareTypeToUpsertPermission(shareOption));
      }
    },
    [upsertUsers, removeUser]
  );

  return (
    <div
      className={`${Styles.workspacePermissions} ${
        !permissionsIsOpen ? Styles.hidden : ""
      }`}
    >
      {!isAddingUsers ? (
        <Fragment>
          <div className={`${Styles.usersPermissions} ${Scrollbar.default}`}>
            {arePermissionsAndUsersLoaded &&
              users.map((user: User) => (
                <PermissionsBar
                  key={user.email}
                  isAdmin={isAdmin}
                  currentUser={email.trim()}
                  text={user.email.trim()}
                  enabledOption={user.permission}
                  publicPermissionLevel={publicPermissionLevel}
                  commitUpdate={(shareOption: ShareOptionValue) =>
                    onCommitUpdate(user, shareOption)
                  }
                  options={userAccessOptions}
                  isUpdating={isUpdatingWorkspace}
                />
              ))}
            {showOrgAdminPolicy && (
              <PermissionsBar
                isAdmin={false}
                commitUpdate={() => {}}
                enabledOption={ShareType.ADMIN}
                options={userAccessOptions}
                text={strings.BY_POLICY_ANY_ORG_ADMIN}
                currentUser="org admin"
              />
            )}
            {showProjectAdmin && (
              <PermissionsBar
                isAdmin={false}
                commitUpdate={() => {}}
                enabledOption={ShareType.ADMIN}
                options={userAccessOptions}
                text={strings.PROJECT_ADMINS}
                currentUser="project admin"
              />
            )}
          </div>
          {!isUpdatingWorkspace && emailsWithoutAccount.length > 0 && (
            <EmailsWithoutAccount
              workspaceId={workspaceDetails.workspaceId}
              {...{
                emailsWithoutAccount,
                newUsersPermission,
                clearRequestedEmails,
                strings,
              }}
            />
          )}
          <div className={Styles.importUsers}>
            {isAdmin && importProjectUsers && arePermissionsAndUsersLoaded && (
              <Button
                type={"primary"}
                toolTip={strings.IMPORT_PROJECT_USERS}
                onClick={async () => {
                  setPendingImport(true);
                  importProjectUsers(
                    workspaceDetails as WorkspaceDetailsWithUserRoles
                  );
                }}
                disabled={pendingImport}
              >
                {strings.IMPORT_PROJECT_USERS}
              </Button>
            )}
            {isAdmin && (
              <Button
                type={"primary"}
                toolTip={strings.ADD_USERS}
                onClick={() => {
                  clearRequestedEmails();
                  toggleAddUser(true);
                }}
              >
                {strings.ADD_USERS}
              </Button>
            )}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={Styles.addUsers}>
            <PermissionsBar
              text={strings.USER_PERMISSION}
              enabledOption={newUsersPermission}
              commitUpdate={(shareOption: ShareOptionValue) =>
                setNewUsersPermission(shareOption)
              }
              options={userAccessOptions}
              isAdmin={isAdmin}
            />
            <RecipientInput
              onChange={(selection) => setSelectedContacts(selection)}
              selectedContacts={selectedContacts}
              fetchContacts={fetchContactsLocal}
              strings={strings}
            />
            <div className={Styles.addUsersFooter}>
              <div className={Styles.notifyUsers}>
                <Checkbox
                  checked={notifyUsers}
                  onChange={() => {
                    setNotifyUsers(!notifyUsers);
                  }}
                >
                  <span>{strings.NOTIFY_USERS}</span>
                </Checkbox>
              </div>
              <div className={Styles.buttons}>
                <Button
                  type="negative"
                  toolTip={strings.CANCEL}
                  onClick={() => {
                    toggleAddUser(false);
                    setSelectedContacts([]);
                  }}
                >
                  {strings.CANCEL}
                </Button>
                <Button
                  toolTip={`${strings.ADD_WITH} ${Localized.string(
                    newUsersPermission.title
                  )} ${strings.ACCESS}`}
                  onClick={evaluateEmailList}
                  type={"primary"}
                  classNames={
                    newUsersPermission.color
                      ? [newUsersPermission.color]
                      : undefined
                  }
                >
                  {`${strings.ADD_WITH} ${Localized.string(
                    newUsersPermission.title
                  )} ${strings.ACCESS}`}
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

type EmailsWithoutAccountProps = {
  emailsWithoutAccount: string[];
  workspaceId: string;
  newUsersPermission: ShareOptionValue;
  clearRequestedEmails: () => void;
  strings: any;
};

const EmailsWithoutAccount: React.FC<EmailsWithoutAccountProps> = ({
  emailsWithoutAccount,
  workspaceId,
  newUsersPermission,
  clearRequestedEmails,
  strings,
}) => {
  const dispatch = useDispatch();

  const sendInvite = () => {
    dispatch(
      inviteToWorkspace.request({
        invite: {
          emails: emailsWithoutAccount,
          reason: "Invite to join Hoylu",
        },
        workspaceId,
        newUsersPermission: shareTypeToUpsertPermission(newUsersPermission),
      })
    );
    clearRequestedEmails();
  };

  return (
    <>
      <div>{strings.NO_ACCOUNTS_ERROR}</div>
      <ul>
        {emailsWithoutAccount.map((email) => (
          <li key={email}>{email}</li>
        ))}
      </ul>
      <div>
        <Button toolTip={strings.INVITE_AND_ADD} onClick={sendInvite}>
          {strings.INVITE_AND_ADD}
        </Button>
        <Button toolTip={strings.CANCEL} onClick={clearRequestedEmails}>
          {strings.CANCEL}
        </Button>
      </div>
    </>
  );
};

function getContacts(
  searchValue: string,
  { omitContacts = [], limit, allContacts = [] }: FetchProps = {}
) {
  const remainingContacts = allContacts.filter(
    (c) => !omitContacts.some((sc) => sc && sc.id === c.id)
  );
  const sortedContacts = searchValue
    ? remainingContacts.filter((c) => c.name.includes(searchValue))
    : remainingContacts;
  const limitedContacts = limit
    ? sortedContacts.slice(0, limit)
    : sortedContacts;
  return limitedContacts;
}

function fetchContacts(
  searchValue: string,
  { omitContacts, limit, requestId, allContacts }: FetchProps = {}
) {
  return Promise.resolve({
    response: {
      data: getContacts(searchValue, { omitContacts, limit, allContacts }),
      requestId,
    },
  });
}
