import React from "react";
import { Icon } from "@hoylu/client-common";
import Styles from "./IconsShared.module.css";

export type PermissionIconProperties = {
  icon: string;
  isActive: boolean;
  toggleActive: (isActive: boolean) => void;
};

export const PermissionIcon: React.FC<PermissionIconProperties> = ({
  icon,
  isActive,
  toggleActive,
}) => {
  const toggleActiveStyle = () => {
    toggleActive(!isActive);
  };

  return (
    <div className={Styles.rightSpacing}>
      <Icon icon={icon} isSelected={isActive} onClick={toggleActiveStyle} />
    </div>
  );
};
