import React from "react";
import { connect } from "react-redux";
import headerGraphic from "../../../assets/images/error-illustration.svg";
import { ErrorDialogWithImage } from "./ErrorDialogWithImage";
import { clearError } from "../../../state/mode/mode.actions";
import { Localized } from "../../../strings";

const mapDispatchToProps = {
  clearError,
};

type OrgBlockedCreateDialogProps = typeof mapDispatchToProps;

export const OrgBlockedCreateDialog: React.FC<OrgBlockedCreateDialogProps> = ({
  clearError,
}) => {
  const strings = Localized.object("ORG_BLOCKED_CREATE_DIALOG");

  return (
    <ErrorDialogWithImage
      onCancel={clearError}
      imageUrl={headerGraphic}
      title={strings.TITLE}
      text={strings.TEXT}
    />
  );
};

export default connect(undefined, mapDispatchToProps)(OrgBlockedCreateDialog);
