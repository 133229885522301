import React from "react";
import { Button } from "@hoylu/client-common";
import { Localized } from "../../../strings";
import Styles from "./CreditLimiSection.module.css";
import { useSelector } from "react-redux";
import { managementPortalBillingUrl } from "../../../utils/management.portal/management.portal.url.selectors";
import { openManagementPortal } from "../../../utils/management.portal/openManagementPortal";
import { canAssignProjectToWorkspace } from "../../../state/workspaces/projects.selector";
import { WorkspaceDetails } from "../../../state/workspaces/types";

type CreditLimitSectionProps = {
  workspaceDetails: WorkspaceDetails;
  openDropdown?: () => void;
};

export const CreditLimitSection = ({
  workspaceDetails,
  openDropdown,
}: CreditLimitSectionProps) => {
  const learnMore = Localized.string("USER_PROFILE_WIDGET.LEARN_MORE");
  const strings = Localized.object("CREDIT_LIMIT_WARNING_BANNER");
  const billingUrl = useSelector(managementPortalBillingUrl);
  const canAssignProject = useSelector(canAssignProjectToWorkspace)(
    workspaceDetails
  );

  return (
    <div
      className={Styles.creditLimitSection}
      data-test-id="ws-info-credit-limit-banner"
    >
      <div className={Styles.header}>
        <div className={`${Styles.icon} hoylu-ui-icons-warning`} />
        <span>{strings.TITLE}</span>
      </div>
      <div className={Styles.buttons}>
        <Button
          toolTip={learnMore}
          type="secondary"
          onClick={() => window.open("https://www.hoylu.com/plans", "_blank")}
          additionalStyles={{ color: "var(--theme-text)" }}
          dataTestId="ws-info-plan-redirect-button"
        >
          {learnMore}
        </Button>
        {canAssignProject && (
          <Button
            toolTip={strings.ADD_TO_PROJECT}
            type="primary"
            onClick={openDropdown}
            dataTestId="ws-info-add-to-project"
          >
            {strings.ADD_TO_PROJECT}
          </Button>
        )}
        <Button
          toolTip={strings.BUTTON}
          type="primary"
          onClick={() => openManagementPortal(billingUrl)}
          additionalStyles={{ marginRight: 0 }}
          dataTestId="ws-info-billing-redirect-button"
        >
          {`${strings.BUTTON}...`}
        </Button>
      </div>
    </div>
  );
};
