import { filter, mergeMap, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { login, getToken } from "../../state/user/user.actions";
import type { AppEpic } from "../types";
import { updateFlags } from "../../state/config/config.actions";
import { config } from "../../state/config/config.selector";
import { user } from "../../state/user/user.selector";

export const featureFlagUpdateEpic: AppEpic = (
  action$,
  state$,
  { flagsForUser }
) => {
  return action$.pipe(
    filter(isActionOf([login.success, getToken.success])),
    mergeMap((_) => {
      return flagsForUser(
        user(state$.value).profile,
        config(state$.value)
      ).pipe(map((flags) => updateFlags(flags)));
    })
  );
};
