import React from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";
import * as workspaceActions from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import Styles from "./LinkedMasterDialog.module.css";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type LinkedMasterDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const LinkedMasterDialog: React.FC<LinkedMasterDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails,
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("LINKED_MASTER_DIALOG");

  const openChangeMasterDialog = () =>
    dispatch(
      workspaceActions.activateDashboardOption({
        optionType: DashboardOption.CHANGE_MASTER,
        workspaceId: selectedWorkspaceDetails?.workspaceId,
      })
    );

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      allowContentToOverflow={true}
    >
      <div
        data-test-id="linked-master-ws-dialog"
        className={Styles.linkedMasterDialog}
      >
        <DraggableWindowHeader
          title={strings.TITLE}
          onClose={cancelDashboardOption}
        />
        <span className={Styles.subtitle}>{strings.SUBTITLE}</span>
        <div className={Styles.items}>
          <div className={Styles.item}>
            <span>{strings.NAME_FIELD}</span>
            <span>Workspace Name</span>
          </div>
          <div className={Styles.item}>
            <span>{strings.ID_FIELD}</span>
            <span>Workspace ID</span>
          </div>
        </div>
        <div className={Styles.buttons}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={openChangeMasterDialog}
            dataTestId={"change-linked-master"}
          >
            {strings.CHANGE_MASTER}
          </Button>
          <Button
            type={"primary"}
            onClick={() => {}}
            dataTestId={"edit-content"}
          >
            {strings.EDIT_CONTENT}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedMasterDialog);
