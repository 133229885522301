import { Localized } from "../strings";

const MAX_LENGTH = 200;

export const isWithinCharLimitValidation = (
  value?: string,
  maxLength: number = MAX_LENGTH
): string | undefined => {
  const strings = Localized.object("VALIDATION");

  if (value && value.length < maxLength) return;
  return strings.CHARACTER_LIMIT;
};
