import type { Option } from "@hoylu/client-common";
import type { ProjectOption } from "./types";
import { Localized } from "../../strings";
import { ProjectNotSelected } from "./defaults";

export const getDashboardProjectOption = (
  onSelected?: () => void
): Option<ProjectOption> => {
  const strings = Localized.object("FILTER_BY_NAME");

  return {
    label: strings.DASHBOARD,
    value: {
      id: ProjectNotSelected,
      icon: "hoylu",
      name: strings.SELECT_PROJECT,
      status: null,
      endDate: null,
      color: "var(--global--notification--Color)",
      hasAdmin: false,
      onSelected,
    },
  };
};
