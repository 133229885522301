import { Button } from "@hoylu/client-common";
import React from "react";
import { connect } from "react-redux";
import { populateInitialWorkspaces } from "../state/workspaces/workspaces.actions";
import { Localized } from "../strings";
import { defaultDashboardPageSize } from "../utils/defaultDashboardPageSize";
import Styles from "./ErrorFetchingWorkspace.module.css";

const mapDispatchToProps = {
  populateWorkspacesAction: populateInitialWorkspaces.request,
};

type ErrorFetchingWorkspaceProps = typeof mapDispatchToProps;

export const ErrorFetchingWorkspace: React.FC<ErrorFetchingWorkspaceProps> = ({
  populateWorkspacesAction,
}) => {
  const strings = Localized.object("ERROR_FETCHING_WORKSPACE");
  return (
    <div className={Styles.errorFetchingWorkspace}>
      <div className={Styles.errorMessage}>
        {strings.ERROR_FETCHING_WORKSPACES}
      </div>
      <Button
        type={"primary"}
        onClick={() =>
          populateWorkspacesAction({ limit: defaultDashboardPageSize })
        }
      >
        {strings.TRY_AGAIN}
      </Button>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ErrorFetchingWorkspace);
