import React, { FormEvent, useState } from "react";
import { Modal } from "./Modal";
import {
  cancelDashboardOption,
  createWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { connect } from "react-redux";
import { RootState } from "typesafe-actions";
import { defaultModule } from "../../utils/defaultModule";
import { GlobalAccess } from "../../services/workspaces/global.access";
import { Localized } from "../../strings";
import {
  Button,
  Checkbox,
  DraggableWindowHeader,
  TextInput,
} from "@hoylu/client-common";
import Styles from "./NewWorkspaceDialog.module.css";
import { textInputLocalization } from "../../utils/text.input.localization";

function mapStateToProps(state: RootState) {
  let workspaces = state.context.workspaces;
  let useCaseName = defaultModule;
  if (
    workspaces.creationParameters &&
    workspaces.creationParameters.useCaseName
  ) {
    useCaseName = workspaces.creationParameters.useCaseName;
  }
  return {
    useCaseName: useCaseName,
    defaultWorkspaceName: workspaces.defaultWorkspaceName,
    requiresPassword: !!state.context.user.requiresPassword,
    isPrivateChecked: !!state.context.user.policies?.workspacesPrivateByDefault,
  };
}

const mapDispatchToProps = {
  handleClose: cancelDashboardOption,
  createWorkspace: createWorkspace.request,
};

type NewWorkspaceDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const NewWorkspaceDialog: React.FC<NewWorkspaceDialogProps> = ({
  handleClose,
  createWorkspace,
  requiresPassword,
  useCaseName,
  defaultWorkspaceName,
  isPrivateChecked,
}) => {
  const strings = Localized.object("NEW_WORKSPACE_DIALOG");
  let [name, setName] = useState(defaultWorkspaceName);
  let [isPrivate, setIsPrivate] = useState(isPrivateChecked);
  let [disabled, setDisabled] = useState(false);
  let [password, setPassword] = useState("");

  function handleContinue(e: FormEvent) {
    e.preventDefault();
    if (disabled || (requiresPassword && password.length < 8)) {
      return;
    }

    createWorkspace({
      globalAccess: isPrivate ? GlobalAccess.NONE : GlobalAccess.WRITE,
      module: { name: useCaseName },
      ...(password && { password }),
      workspaceName: name ?? defaultWorkspaceName,
    });
    setDisabled(true);
  }

  return (
    <Modal handleOutsideClick={handleClose}>
      <DraggableWindowHeader
        title={strings.CREATE_WORKSPACE}
        onClose={handleClose}
      />
      <form className={Styles.form} onSubmit={handleContinue}>
        <TextInput
          value={name}
          label={strings.NAME}
          type={"text"}
          name={"name"}
          onChange={(value) => setName(value)}
          onFocus={(e) => {
            e.target.select();
            setName(e.target.value);
          }}
          isFocus={true}
        />
        {requiresPassword && (
          <TextInput
            label={`${strings.PASSWORD} - ${strings.CHARACTERS}`}
            value={password}
            onChange={(value) => setPassword(value)}
            useTogglePassword={true}
            type={"password"}
            localizationDict={textInputLocalization()}
          />
        )}
        <div
          title={strings.PRIVATE_WORKSPACE_TOOLTIP}
          className={Styles.setPrivate}
        >
          <Checkbox
            checked={isPrivate}
            onChange={() => setIsPrivate((prevState) => !prevState)}
          >
            {strings.PRIVATE_WORKSPACE}
          </Checkbox>
        </div>
        <footer className={Styles.footer}>
          <Button
            type="negative"
            onClick={handleClose}
            toolTip={strings.CANCEL}
            formType={"button"}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            disabled={disabled || (requiresPassword && password.length < 8)}
            toolTip={disabled ? strings.OPENING : strings.CONTINUE}
            formType={"submit"}
          >
            {disabled ? strings.OPENING : strings.CONTINUE}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkspaceDialog);
