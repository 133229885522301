export function parseNDJSON() {
  let ndjsonBuffer = "";

  return new TransformStream({
    transform: function (ndjsonChunk, controller) {
      ndjsonBuffer += ndjsonChunk;

      const jsonValues = ndjsonBuffer.split("\n");
      jsonValues.slice(0, -1).forEach(function (jsonValue) {
        controller.enqueue(JSON.parse(jsonValue));
      });

      ndjsonBuffer = jsonValues[jsonValues.length - 1];
    },
    flush: function (controller) {
      if (ndjsonBuffer) {
        controller.enqueue(JSON.parse(ndjsonBuffer));
      }
    },
  });
}

export async function* readableStreamToAsyncGenerator<T>(
  readableStream: ReadableStream<T>
): AsyncGenerator<T> {
  const reader = readableStream.getReader();
  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        return;
      }
      yield value!;
    }
  } finally {
    reader.releaseLock();
  }
}
