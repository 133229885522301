import React from "react";
import Styles from "./MenuItem.module.css";

type MenuItemProps = {
  icon: string;
  label: string;
  onClick: () => void;
  title?: string;
};
const MenuItem = ({ icon, label, onClick, title }: MenuItemProps) => (
  <div title={title ?? label}>
    <button
      className={Styles.menuItem}
      onClick={onClick}
      aria-label={title ?? label}
    >
      <div className={`${Styles.icon} ${icon}`} />
      <span className={Styles.label}>{label}</span>
    </button>
  </div>
);

export default MenuItem;
