import React from "react";
import { emailToHSL, Icon, Marble } from "@hoylu/client-common";
import { Localized } from "../strings";
import { useSelector } from "react-redux";
import { CopyIcon } from "./modals/sharing/icons/CopyIcon";
import Styles from "./ProjectSection.module.css";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import { projectDeepLink } from "../utils/url.selectors";
import { openManagementPortal } from "../utils/management.portal/openManagementPortal";
import {
  getAllProjectCollaborators,
  isAdminOfSelectedProject,
} from "../state/workspaces/projects.selector";
import { managementPortalProjectUrl } from "../utils/management.portal/management.portal.url.selectors";

const ProjectSection: React.FC = () => {
  const strings = Localized.object("SECTIONS.PROJECT");
  const deepLink = useSelector(projectDeepLink);
  const manageProjectUrl = useSelector(managementPortalProjectUrl);
  const collaborators = useSelector(getAllProjectCollaborators);
  const isAdmin = useSelector(isAdminOfSelectedProject);

  const editIconStyle = {
    color: "var(--theme-text)",
    fontSize: "var(--global--size-x-large)",
  };

  const showCollaborators = collaborators.length > 0;
  const showProjectSettings = isAdmin && !!manageProjectUrl;

  return (
    <div className={Styles.projectSection}>
      {showCollaborators && (
        <div
          className={`${Styles.collaborators} ${Scrollbar.normal} ${Scrollbar.horizontal}`}
          tabIndex={0}
          aria-label={strings.COLLABORATORS}
        >
          {collaborators.map((email) => (
            <CollaboratorMarble key={email} collaboratorEmail={email} />
          ))}
        </div>
      )}
      <div title={strings.COPY_LINK} tabIndex={0}>
        <CopyIcon
          icon={"hoylu-ui-icons-link"}
          style={editIconStyle}
          text={deepLink}
        />
      </div>
      {showProjectSettings && (
        <div title={strings.PROJECT_SETTINGS} tabIndex={0}>
          <Icon
            icon={"hoylu-ui-icons-setting"}
            style={editIconStyle}
            onClick={() => openManagementPortal(manageProjectUrl)}
          />
        </div>
      )}
    </div>
  );
};

type CollaboratorMarbleProps = {
  collaboratorEmail: string;
};

const CollaboratorMarble = ({ collaboratorEmail }: CollaboratorMarbleProps) => {
  const initial = collaboratorEmail[0];
  const projectMarbleStyles = {
    width: "3rem",
    minWidth: "3rem",
    height: "3rem",
    fontSize: "var(--global--size-h1)",
    backgroundColor: emailToHSL(collaboratorEmail),
    boxShadow: "var(--global--shadow--lg)",
  };

  return (
    <Marble
      key={`marble-${collaboratorEmail}`}
      title={collaboratorEmail}
      style={projectMarbleStyles}
    >
      {initial}
    </Marble>
  );
};

export default ProjectSection;
